<template>
  <div
    class="landing-page overflow-hidden bg-black h-auto max-w-[100%] md:min-h-[auto] flex flex-col p-0 items-center child:max-w-[1600p]">
    <div class="w-full">
      <PHeader />
      <main-section />
      <PickYourSports />
      <WhyJuked />
      <HowItWorks />
      <AllowedState />
      <LatestWinner />
      <FAQ />
      <CTA />
      <PFooter />
    </div>
  </div>
</template>

<script>
import Auth from '@/components/Home/Auth.vue';
import { mapGetters } from 'vuex';
import PHeader from '@/components/Home/Landingpage/PHeader.vue';
import MainSection from '@/components/Home/Landingpage/MainSection.vue';
import PickYourSports from '@/components/Home/Landingpage/PickYourSports.vue';
import WhyJuked from '@/components/Home/Landingpage/WhyJuked.vue';
import HowItWorks from '@/components/Home/Landingpage/HowItWorks.vue';
import AllowedState from '@/components/Home/Landingpage/AllowedState.vue';
import LatestWinner from '@/components/Home/Landingpage/LatestWinner.vue';
import FAQ from '@/components/Home/Landingpage/Faq.vue';
import CTA from '@/components/Home/Landingpage/CallToAction.vue';
import PFooter from '@/components/Home/Landingpage/PFooter.vue';

export default {
  name: 'landing',
  components: {
    PHeader,
    MainSection,
    PickYourSports,
    WhyJuked,
    HowItWorks,
    AllowedState,
    LatestWinner,
    FAQ,
    CTA,
    PFooter,
  },
  computed: {
    ...mapGetters(['activeRoom', 'me', 'isLoggedIn']),
  },
  mounted() {
    if (this.$route.query.aid) {
      this.$router.push(`/c/${this.$route.query.aid}`);
    }
    let section = this.$router.currentRoute.value.hash.replace('#', '');
    if (section)
      this.$nextTick(() => window.document.getElementById(section).scrollIntoView());
  },
  data() {
    return {
      loading: true,
      games: [],
    };
  },
  watch: {
    '$route.hash': {
      handler: function (hash) {
        hash = hash.length === 0 ? 'main' : hash.replace('#', '');
        const el = document.getElementById(hash);
        if (el) {
          el.scrollIntoView();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
@import '@/components/Home/Landingpage/style.css';
</style>
