<template>
  <div class="w-full bg-tertiary px-4 py-12 md:px-8 md:py-12">
    <footer
      class="flex w-full flex-col items-center justify-center space-y-2 text-slate-400">
      <div class="flex flex-col items-center">
        <div class="mt-8 flex flex-col items-center">
          <img src="../assets/logo.png" alt="logo" class="h-8 sm:h-10 opacity-60" />
          <div
            class="mt-5 flex w-full flex-row items-center justify-center gap-2 text-center text-xs">
            <a
              class="flex flex-row items-center justify-center gap-2 hover:opacity-75"
              href="https://facebook.com/JukedApp"
              target="_blank">
              <img src="../assets/svg/facebook.png" cl alt="logo" class="h-6 w-6" />
            </a>
            <a
              class="flex flex-row items-center justify-center gap-2 hover:opacity-75"
              href="https://x.com/jukedco"
              target="_blank">
              <img src="../assets/svg/twitter.png" cl alt="logo" class="h-6 w-6" />
            </a>
       
            <a
              class="flex flex-row items-center justify-center gap-2 hover:opacity-75"
              href="https://instagram.com/Juked.co"
              target="_blank">
              <img src="../assets/svg/insta.png" cl alt="logo" class="h-6 w-6" />
            </a>
          </div>
          <p class="mt-4 text-center text-sm">
            © {{ getcurrentyear() }} Juked Entertainment LLC. | All Rights Reserved.
          </p>
        </div>

        <div
          class="mt-5 flex w-full flex-col md:flex-row items-center justify-center gap-2 text-center text-xs">
          <div>
            Support:
            <a href="mailto:help@juked.co" class="text-slate-100 hover:opacity-70"
              >help@juked.co</a
            >
          </div>
          <span class="font-bold hidden md:block">|</span>
          <div>
            Partners:
            <a href="mailto:partners@juked.co" class="text-slate-100 hover:opacity-70"
              >partners@juked.co</a
            >
          </div>
          <span class="font-bold hidden md:block">|</span>
          <div>
            Payment Related Queries (24/7):
            <a href="tel:+1 (855) 587-2839" class="text-slate-100 hover:opacity-70"
              >+1 (855) 587-2839</a
            >
          </div>
        </div>
        <div
          class="mt-7 sm:mt-5 flex w-full flex-col md:flex-row items-center justify-center gap-5 sm:gap-2 pb-0 text-center text-sm sm:text-xs md:pb-1 max-[767px]:gap-2 max-[767px]:text-xs max-[767px]:grid max-[767px]:grid-cols-2">
          <a
            target="_blank"
            href="/docs/Privacy-Policy.pdf"
            class="flex-1 text-right text-slate-100 hover:opacity-70 max-[767px]:text-center">
            Privacy Policy
          </a>
          <!-- <a
            href="https://juked.co/docs/Privacy-Policy.pdf"
            target="_blank"
            class="flex-1 text-right text-slate-100 hover:opacity-70"
            >Privacy Policy</a
          > -->
          <span class="font-bold hidden md:block">|</span>
          <a
            href="/docs/Terms-And-Conditions.pdf"
            target="_blank"
            class="flex-1 text-left text-slate-100 hover:opacity-70 max-[767px]:text-center"
            >Terms and Conditions</a
          >
        </div>

        <div
          class="mt-2 md:mt-5 flex w-full flex-col md:flex-row items-center justify-center gap-5 sm:gap-2 pb-0 text-center text-sm sm:text-xs md:pb-1 max-[767px]:gap-2 max-[767px]:text-xs max-[767px]:grid max-[767px]:grid-cols-2">
          <router-link
            :to="'/vip'"
            class="flex-1 text-right text-slate-100 hover:opacity-70 max-[767px]:text-center">
            VIP Club
          </router-link>
          <span class="font-bold">|</span>
          <router-link
            :to="'/sweep-rules'"
            class="flex-0 text-right text-slate-100 hover:opacity-70 max-[767px]:text-center">
            Sweep Rules
          </router-link>
          
           
          <span class="font-bold hidden md:block">|</span>
          <a
            href="docs/Juked-Responsible-Social-Gaming.pdf"
            target="_blank"
            class="flex-1 text-left text-slate-100 hover:opacity-70 max-[767px]:text-center"
            >Responsible Social Gameplay</a
          >
        </div>
        <p class="mt-5 p-2 pb-0 text-center text-xs leading-relaxed md:pb-16 max-w-[950px]">
          NO PURCHASE IS NECESSARY to play. 
          PROMOTIONS ARE VOID WHERE PROHIBITED BY LAW. For detailed rules, see <a
            class="text-slate-100 hover:opacity-70"
            target="_blank"
            href="https://juked.co/docs/Terms-And-Conditions.pdf"
            >Terms of Use</a> & 
            <router-link
            :to="'/sweep-rules'"
            class="flex-0 text-right text-slate-100 hover:opacity-70 max-[767px]:text-center">
            Sweepstakes Rules.
            </router-link> juked.co Social Gaming Platform is a play-for-fun website intended for amusement purposes only. Juked.co's Social Gaming Platform does not offer “real-money gambling” or the opportunity to win real money. Juked.co Social Gaming Platform is only open to Eligible Participants, who are at least eighteen (18) years old or the age of majority in their jurisdiction (whichever occurs later) at the time of entry.
         
       
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'MainFooter',
  setup() {
    const showLiveChat = function () {
      window.Intercom('showMessages');
    };
    return { showLiveChat };
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
  methods: {
    getcurrentyear() {
      return new Date().getFullYear();
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
