<template>
  <div v-if="!shouldRender">
    <identity-verification-form />
  </div>
  <div v-else>
    <bio-auth-verify
      v-if="
        (isAptPayAchFormValid &&
          aptBioAuthValidationUrl &&
          formData.transactionType == 'ACH') ||
        (isAptPayCardFormValid &&
          aptBioAuthValidationUrl &&
          formData.transactionType == 'CARD')
      "
      :bioAuthVerifylink="aptBioAuthValidationUrl" />
    <div v-else class="flex min-h-[100%] items-center justify-center text-center">
      <form
        @submit.prevent="submitDisbursment"
        class="w-100 max-w-full rounded-lg bg-black bg-opacity-25 px-8 py-8 shadow-lg ssn-address-form">
        <h1 class="mb-8 text-3xl font-bold text-white">Redeem Juked Cash</h1>
        <div class="md:px-12 mb-4 text-left">
          <label for="amount" class="block font-medium text-gray-300">Amount</label>
          <input
            v-model="formData.amount"
            type="number"
            step="any"
            id="amount"
            name="amount"
            :class="{ 'has-error': v$.formData.amount.$error }"
            class="mt-1 w-full rounded-md border-gray-300 bg-white px-4 py-2 text-gray-900 focus:border-blue-300 focus:ring focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:focus:border-gray-600 dark:focus:ring-blue-600" />

          <span v-if="v$.formData.amount.$error" class="text-red-500 text-xs">{{
            v$.formData.amount.$errors[0].$message
          }}</span>
        </div>

        <div v-if="this.isAddressNeeded" class="my-5 rounded-[10px] pb-[5px]">
          <div
            class="max-w-[480px] mx-auto mb-1 max-[767px]:max-w-[320px] max-[480px]:max-w-[240px">
            <h4 class="font-bold text-lg text-white text-center">
              Please enter your address
            </h4>
          </div>
          <div class="max-w-[480px] mx-[auto] my-[0]">
            <div class="">
              <input
                v-model.trim="formData.address_line1"
                type="text"
                id="address_line1"
                name="address_line1"
                placeholder="Address Line 1"
                :class="{ 'has-error': v$.formData.address_line1.$error }"
                class="mt-1 w-full rounded-md border-gray-300 bg-white px-4 py-2 text-gray-900 focus:border-blue-300 focus:ring focus:ring-blue-300" />
            </div>

            <div class="grid grid-cols-1 md:grid-cols-10 md:gap-3 mt-2">
              <input
                v-model.trim="formData.city"
                type="text"
                id="city"
                name="city"
                placeholder="City"
                :class="{ 'has-error': v$.formData.city.$error }"
                class="md:mt-1 col-span-3 w-full rounded-md border-gray-300 bg-white px-4 py-2 text-gray-900 focus:border-blue-300 focus:ring focus:ring-blue-300" />

              <select
                v-model="formData.state"
                :class="{ 'has-error': v$.formData.state.$error }"
                class="mt-2 md:mt-1 col-span-4 w-full rounded-md border-gray-300 bg-white px-4 py-2 text-gray-900 focus:border-blue-300 focus:ring focus:ring-blue-300"
                id="state"
                name="state">
                <option value="">State</option>
                <option v-for="(item, i) in statelist" v-bind:key="i" v-bind:value="i">
                  {{ item }}
                </option>
              </select>

              <input
                v-model="formData.zip"
                type="text"
                maxlength="5"
                id="zip"
                name="zip"
                placeholder="5 Digit Zip"
                :class="{ 'has-error': v$.formData.zip.$error }"
                class="mt-2 md:mt-1 col-span-3 w-full rounded-md border-gray-300 bg-white px-4 py-2 text-gray-900 focus:border-blue-300 focus:ring focus:ring-blue-300" />
            </div>
          </div>
        </div>

        <div class="saved_details">
          <div class="saved-accounts-holder">
            <div class="saved-accounts max-w-[480px]" v-if="!isLoading">
              <ul v-if="accounts.length > 0" class="card-list-ul">
                <li class="mt-5 text-white" v-if="formData.transactionType == 'ACH'">
                  Select Account Number
                </li>
                <li class="mt-5 text-white" v-if="formData.transactionType == 'CARD'">
                  Select Card
                </li>
                <li v-for="(account, index) in accounts" :key="index" class="mt-5">
                  <button
                    type="button"
                    :class="formData.instrumentId == account.id ? 'active' : ''"
                    class="saved-card-item rounded-md px-6 py-2 text-white disabled:opacity-50"
                    @click="selectAccount(account.id)">
                    <p
                      class="text-sm opacity-70"
                      v-if="formData.transactionType == 'ACH'">
                      Account Number:
                    </p>
                    <p
                      class="text-sm opacity-70"
                      v-if="formData.transactionType == 'CARD'">
                      Debit Card:
                    </p>
                    <p>{{ account.number }}</p>
                  </button>
                  <a href="#" @click="removecard(account.id)" class="remove-card">
                    remove
                  </a>
                </li>
                <li class="mt-5"></li>
              </ul>
              <div v-else-if="!isLoading" class="mb-6">
                <p v-if="formData.transactionType == 'ACH'">
                  Please add a account in order to reedeem. Kindly note that in order to
                  add account you need to verify your account so please procced.
                </p>
                <p v-if="formData.transactionType == 'CARD'">
                  Please add a Debit Card to your account in order to initiate an Instant
                  Transfer. Kindly note that in order to add a Debit Card to your account,
                  you must verify ownership of the Debit Card through micro-redemptions.
                </p>
              </div>

              <button
                type="button"
                v-if="formData.transactionType == 'ACH'"
                :disabled="isLoading"
                class="rounded-md bg-accent px-6 py-2 text-white hover:bg-blue-600 disabled:opacity-50"
                @click="addnewbankaccount">
                Add New Account
              </button>
              <button
                v-if="formData.transactionType == 'CARD'"
                type="button"
                @click="addnewcard"
                :disabled="isLoading"
                class="rounded-md bg-accent px-6 py-2 text-white hover:bg-blue-600 disabled:opacity-50">
                Add New Card
              </button>
            </div>
            <div v-else>Please wait loading...</div>
          </div>
        </div>

        <button
          :disabled="isLoading"
          type="Submit"
          class="rounded-md mt-5 px-6 py-2 text-black bg-[#6aff14] disabled:opacity-50">
          Redeem
        </button>
      </form>
    </div>
    <punt-modal
      size="md:max-w-xl max-w-[92%] aptpay-account-inside "
      v-if="accountVerifylink || verifyDebitcard"
      :showClose="true"
      :onClose="
        () => {
          accountVerifylink = null;
          verifyDebitcard = null;
          microdepositestart = false;
        }
      ">
      <template #title></template>
      <template #body>
        <div
          class="modal is-active flex min-h-[500px] flex-col items-center text-slate-300">
          <div class="m-auto w-[100%] text-center">
            <div class="px-10" v-if="formData.transactionType == 'CARD'">
              <!-- CARD specific fields -->
              <div v-if="formData.transactionType == 'CARD'">
                <div class="" v-if="microdepositestart">
                  <p class="mb-3">
                    <b
                      >To verify your debit card, we just made a small redemption to the
                      debit card provided. This usually takes just a few minutes to
                      receive. Once you see the redemption in your account, please enter
                      the amount below.</b
                    >
                  </p>
                  <div v-if="formData.transactionType == 'CARD'" class="mb-5">
                    <input
                      v-model="microDeposite"
                      type="text"
                      step="any"
                      id="microDeposite"
                      name="microDeposite"
                      class="mt-1 w-full rounded-md border-gray-300 bg-white px-4 py-2 text-gray-900 focus:border-blue-300 focus:ring focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:focus:border-gray-600 dark:focus:ring-blue-600" />
                  </div>
                  <button
                    v-if="!isLoading"
                    @click="submitMicro"
                    class="rounded-md inline-block bg-accent px-6 py-2 text-white hover:bg-blue-600 disabled:opacity-50"
                    href="#">
                    SUBMIT
                  </button>
                  <p v-if="isLoading">Please wait</p>
                </div>
                <div v-else>
                  <div class="mb-4">
                    <label
                      for="disbursement-number"
                      class="block font-medium text-gray-300"
                      >Debit Card Number</label
                    >
                    <span
                      id="cc-number"
                      class="form-field block h-10 mt-1 w-full rounded-md border-gray-300 bg-white text-black px-4 py-2 text-gray-900 focus:border-blue-300 focus:ring focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:focus:border-gray-600 dark:focus:ring-blue-600">
                      <!--VGS Collect iframe for card number field will be here!-->
                    </span>
                  </div>
                  <button
                    v-if="card_number && !isLoading"
                    type="button"
                    @click="submitcard"
                    class="rounded-md bg-accent px-6 py-2 text-white hover:bg-blue-600 disabled:opacity-50">
                    Add New Card
                  </button>
                  <p v-if="isLoading">Please wait</p>
                </div>
              </div>
            </div>
            <div v-else class="px-5">
              <p class="mb-5">
                In order to add a new Bank Account, please agree to the Terms of Service
                that will open up in a new window after clicking Proceed
              </p>
              <a
                :href="accountVerifylink"
                class="rounded-md bg-accent px-6 py-2 text-white hover:bg-blue-600 disabled:opacity-50"
                target="_blank">
                Proceed Now
              </a>
            </div>
          </div>
        </div>
      </template>
    </punt-modal>
    <ssn-verification v-if="!ssnveriff" :onSuccess="onssnSuccess" />
  </div>
</template>

<script>
import PuntModal from '@/components/Shared/PuntModal.vue';
import { getBalanceIcon } from '@/lib/icons';
import { mapActions, mapGetters } from 'vuex';
import IdentityVerificationForm from '@/components/Bank/IdentityVerificationForm.vue';
import BioAuthVerify from '@/components/Bank/BioAuthVerify.vue';
import { computed } from '@vue/reactivity';
import $store from '@/store';
import { aptPay } from '@/store/pinia/aptPay';
import SsnVerification from '@/components/Bank/SsnVerification.vue';
import {
  helpers,
  maxLength,
  minValue,
  required,
  integer,
  requiredIf,
  minLength,
  numeric,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { getUsStatesWithCode } from '@/lib/state';

export default {
  name: 'AptPayWithdraw',
  props: {
    type: {
      default: 'max-w-md',
    },
  },
  components: {
    IdentityVerificationForm,
    BioAuthVerify,
    SsnVerification,
    PuntModal,
  },
  setup() {
    const aptP = aptPay();

    return {
      aptP,
      v$: useVuelidate(),
    };
  },
  mounted() {
    this.formData.transactionType = this.type;
    if (window.Echo) {
      this.bindEcho();
    }
    this.getStateByCountry();
  },
  data() {
    return {
      isAptpayenabled: false,
      shouldRender: false,
      formData: {
        amount: '',
        currency: 'USD',
        transactionType: 'ACH',
        disbursementNumber: '',
        branchTransitNumber: '',
        accountNumber: '',
        instrumentId: null,
        address_line1: '',
        city: '',
        state: '',
        zip: '',
      },
      statelist: [],
      formErrors: {
        amountError: null,
      },
      accountVerifylink: null,
      accountVerifyStart: false,
      isLoading: false,
      accounts: [],
      card_number: null,
      microDeposite: 0,
      verifyDebitcard: null,
      latestac_id: null,
      microdepositestart: false,
      ssnveriff: true,
      ssnAddress: $store.getters.me.address,
      ssnStatus: $store.getters.me.ssn_status,
      isAptPayAchFormValid: false,
      isAptPayCardFormValid: false,
      aptBioAuthValidationUrl: '',
    };
  },
  validations() {
    return {
      formData: {
        amount: {
          required: helpers.withMessage('Amount is required.', required),
          integer: helpers.withMessage('Amount must be a digit', required),
          minValue: helpers.withMessage(
            'Minimum you can redeem is 100 SC.',
            (v) => v >= 100,
          ),
        },
        address_line1: {
          required: requiredIf(this.isAddressNeeded),
          maxLength: maxLength(100),
        },
        city: { required: requiredIf(this.isAddressNeeded), maxLength: maxLength(100) },
        state: { required: requiredIf(this.isAddressNeeded) },
        zip: {
          required: requiredIf(this.isAddressNeeded),
          maxLength: maxLength(5),
          minLength: minLength(5),
          numeric: numeric,
        },
      },
    };
  },
  async created() {
    this.isLoading = true;
    window.toast = this.$toast;
    await this.aptP.identityCheck();
    setTimeout(() => {
      this.subscribe_aptpaynotification();
    }, 200);

    this.shouldRender = this.aptP.isAptPayVerified;

    await this.fetchAccounts();
    this.isLoading = false;
  },
  methods: {
    ...mapActions(['fetchUser']),

    getStateByCountry() {
      this.statelist = getUsStatesWithCode();
    },

    onssnSuccess(data) {
      this.ssnveriff = true;
      this.submitDisbursment();
    },
    async setupcollectjs() {
      this.card_number = VGSCollect.create(
        import.meta.env.VITE_PAY_SAFE_COLLECT_JS,
        import.meta.env.VITE_PAY_SAFE_ENV,
        function (state) {},
      );

      this.card_number.field('#cc-number', {
        type: 'card-number',
        name: 'disbursementNumber',
        //placeholder: 'Type Card number here',
        validations: ['required'],
        showCardIcon: false,
        //successColor: '#fff',
        errorColor: '#D8000C',
        placeholder: '4111 XXXX XXXX XXXX',
        addCardBrands: [
          {
            type: 'paymentcard',
            pattern: /^\d/,
            format: /(\d{1,4})/g,
            length: [15, 16, 17, 18, 19],
            cvcLength: [3, 4],
            luhn: false,
          },
        ],
      });
    },
    async submitaptwithdraw() {
      try {
        if(this.accounts.length == 1 && this.formData.instrumentId == null)
          this.selectAccount(this.accounts[0].id);
        let response = await this.aptP.redeembyid(
          JSON.parse(JSON.stringify(this.formData)),
        );

        if (response.data['error_code'] == 'ssn') {
          this.ssnveriff = false;
          return response;
        }

        if (response.data['status']) {
          if (response.data?.data?.url !== null) {
            this.aptBioAuthValidationUrl = response.data?.data?.url;
            this.resetWithdrawFormsResult();

            if (this.formData.transactionType == 'ACH') {
              this.isAptPayAchFormValid = true;
            } else if (this.formData.transactionType == 'CARD') {
              this.isAptPayCardFormValid = true;
            }
          } else {
            this.$toast.success(response.data['status']);
          }
          /*
                setTimeout(() => {
                    window.location.reload();
                    this.$router.go(-1);
                }, 5000);
                */
        }
        if (response.data['error_code']) {
          this.$toast.error(
            'error code ' + response.data['error_code'] + ' ' + response.data['message'],
          );
        }
        if (response.data['error']) {
          this.$toast.error(response.data['error']);
        }
        return response;
        // this.$toast.success('payment success')
      } catch (error) {
        // console.error("Error in payment:", error);
      }
    },

    async submitDisbursment() {
      this.v$.$validate();

      if (!this.v$.$error && !this.isLoading) {
        this.isLoading = true;
        try {
          let response = await this.submitaptwithdraw();

          if (response.status == 200) {
            if (!this.ssnAddress) {
              this.$store.commit('update-user-address', true);
            }

            let url = response.data['verificationUrl'];
            let refid = response.data['referenceId'];
            let result = null;
            let last_step = '';

            if (response.data['status'] == 'ACCEPTED') {
              this.isLoading = false;
            }
          }
          // else{
          // if(response.data.error_code){
          //   this.$toast.error(response.data.errors['0'])
          // }
          // }
        } catch (error) {
          console.error('Error in payment', error);
        }
        this.isLoading = false;
      }
    },
    subscribe_aptpaynotification() {
      if(this.me && typeof this.me.id !== "undefined" )
      {
        try {
          window.Echo.private('App.User.' + this.me.id).listen(
          'AptpayNotification',
          ({ aptpaystatus }) => {
            if (aptpaystatus == 'PAYEE_VERIFICATION_COMPLETED') {
              this.shouldRender = true;
              if (this.formData.transactionType == 'CARD' && this.shouldRender) {
                setTimeout(() => {
                  this.setupcollectjs();
                }, 500);
              }
            }
          },
          );
        } catch(error) {
          console.log('AptpayNotification subscribe failed')
        }
      }
    },
    async fetchAccounts() {
      try {
        let data = null;
        if (this.formData.transactionType == 'ACH') {
          data = await this.aptP.getUserAccounts();
        } else if (this.formData.transactionType == 'CARD') {
          data = await this.aptP.getUserCards();
        }

        this.accounts = data.data.accounts;
      } catch (e) {
        this.$toast.error('There are some error while teching accounts');
      }
    },

    selectAccount(index) {
      if (this.formData.instrumentId == index) this.formData.instrumentId = null;
      else this.formData.instrumentId = index;
    },
    async addnewbankaccount() {
      if (!this.isLoading) {
        this.isLoading = true;
        try {
          const data = await this.aptP.verifyAccountDetails();
          this.accountVerifylink = data.data.url;
        } catch (error) {
          this.$toast.error('There is some error please try again');
        }
        this.isLoading = false;
      }
    },
    async addnewcard() {
      this.verifyDebitcard = true;
      setTimeout(() => {
        this.setupcollectjs();
      }, 500);
    },
    async submitcard() {
      if (!this.isLoading) {
        this.isLoading = true;
        try {
          if (this.formData.transactionType == 'CARD') {
            if (this.card_number) {
              var obj = this;
              this.card_number.submit(
                '/post',
                {},
                function (status, data) {
                  obj.aptP
                    .verifyCardDetails({ card: data.json.disbursementNumber })
                    .then((response) => {
                      // obj.accountVerifylink = response.data.url;

                      obj.latestac_id = response.data.acv_id;
                      if (response.data.success) {
                        obj.latestac_id = response.data.acv_id;
                        obj.microdepositestart = true;
                        obj.verifyDebitcard = true;
                      } else {
                        obj.$toast.success('Card already registered');
                        obj.verifyDebitcard = false;
                        obj.microdepositestart = false;
                      }
                      obj.isLoading = false;
                    })
                    .catch((response) => {
                      if (typeof response.data.error !=="undefined") {
                        obj.$toast.error(response.data.error);
                        obj.verifyDebitcard = false;
                        obj.microdepositestart = false;
                      }
                      obj.isLoading = false;
                    });
                },
                function (data) {
                  obj.isLoading = false;
                },
              );
            } else {
              throw new Error('Card Number is not valid');
              this.isLoading = false;
            }
          }
        } catch (error) {
          this.$toast.error('There is some error please try again');
          this.isLoading = false;
        }
      }
    },
    async submitMicro() {
      if (!this.isLoading) {
        this.isLoading = true;
        try {
          const data = await this.aptP.verifyMicroDeposite({
            amount: this.microDeposite,
            acv_id: this.latestac_id,
          });

          if (data.data.success == true) {
            await this.fetchAccounts();
            this.$toast.success('Card Registered Successfully');
            this.verifyMicroDeposite = false;
            this.verifyDebitcard = false;
            this.microdepositestart = false;
          } else {
            this.$toast.error('Incorrect Amount Entered');
          }
        } catch (e) {
          if (typeof e.data.error !=="undefined") this.$toast.error(e.data.error);
          else
            this.$toast.error(
              'There is somer error to match microdiposite please try again',
            );
        }
        this.isLoading = false;
      }
    },
    async removecard(id) {
      let title = '';
      let desc = '';
      if (this.formData.transactionType == 'CARD') {
        title = 'Confirm Debit Card Deletion';
        desc = 'Are you sure you want to delete your saved debit card?';
      } else {
        title = 'Confirm Checking Account Deletion';
        desc = 'Are you sure you want to delete your saved checking account?';
      }

      this.$swal({
        title: title,
        html: desc,
        showCancelButton: true,
        animation: 'slide-from-top',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            if (!this.isLoading) {
              this.isLoading = true;
              try {
                const data = await this.aptP.removesaved(id);

                await this.fetchAccounts();
              } catch (e) {
                this.$toast.error('There is somer error. please try again');
              }
              this.isLoading = false;
            }
          }
        })
        .catch((error) => {});
    },
    bindEcho() {
      if(this.me && typeof this.me.id !== "undefined" )
      {
        try {
          window.Echo.private('App.User.' + this.me.id).listen(
            'NewAccountAdded',
            async (data) => {
              if (data.success) {
                await this.fetchAccounts();
                this.accountVerifylink = null;
                this.$toast.success(data.message);
              } else {
                this.$toast.error(data.message);
              }
            },
          );
        } catch(error) {
          console.log('NewAccountAdded failed');
        }
      }
    },
    resetWithdrawFormsResult() {
      this.isAptPayAchFormValid = false;
      this.isAptPayCardFormValid = false;
    },
  },
  computed: {
    ...mapGetters(['me']),
    getType() {
      return this.type;
    },
    isAddressNeeded() {
      return !this.ssnAddress && (this.ssnStatus === 1 || this.ssnStatus === 2);
    },
  },
};
</script>

<style scoped>
#cc-number {
  display: flex;
}
</style>
