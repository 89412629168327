<template>
  <!-- <softswiss-bet-info v-if="tab === 'history'" /> -->
  <FullScreenLoader
    height="30px"
    v-if="isLoading"
    :start="startLoader"
    :onSuccess="payloaddone" />

  <login-or-register v-if="tab === 'login' || tab === 'register'" />
  <payment-confirm-model v-if="isLoggedIn" />
  <div v-if="is2FaVerified">
    <chat-rules v-if="tab === 'chatRules'" />
    <bank v-if="tab === 'bank'" />
    <UserStats v-if="tab === 'stats'" />
    <geo-blocked v-if="tab === 'blocked'" />
    <veriff-bouns v-if="first_time_login == '1'" />
    <promo-opt-in-modal v-if="isPromoOptInModalVisible" @close="isPromoOptInModalClosed = true" />
  </div>

  <div class="flex   w-full flex-row text-white">
   

    <div
      v-if="generalS.showLeftMenu || isLoggedIn || !landingPage"
      v-on:click="generalS.showLeftMenu = false"
      class="fixed top-0 left-0 w-full   z-[99999] bg-[rgb(55_65_81_/_40%)] backdrop-filter backdrop-blur-[3px] min-[1025px]:!hidden max-[767px]:!hidden"
      :class="generalS.showLeftMenu ? 'block' : 'hidden'"></div>

    <div
      class="h-screen sticky top-0 left-0 max-[767px]:sticky max-[1024px]:absolute max-[1024px]:z-[99999]"
      v-if="(isLoggedIn || !landingPage) && false  "
      :class="generalS.showLeftMenu ? '' : ''">
      <div
        :class="
          generalS.showLeftMenu
            ? 'transition-all duration-100 ease-linear md:w-[240px]'
            : 'transition-all duration-100 ease-in-out md:w-[80px]'
        "
        class="sticky top-0 hidden h-screen flex-col bg-secondary md:flex"
        v-if="is2FaVerified">
        <div class="border-b border-line-color px-4 flex justify-between py-4">
          <div
            v-if="generalS.showLeftMenu"
            v-on:click="$router.push('/')"
            class="flex cursor-pointer flex-row items-center space-x-1 hover:opacity-60">
            <img class="h-8 w-8" src="./assets/3d/poker-card.png" />
            <p class="font-medium whitespace-nowrap">Juked</p>
          </div>

          <button
            class="hidden md:block h-11 rounded-xl bg-secondary-light px-4 py-3 font-semibold hover:opacity-70"
            v-on:click="generalS.showLeftMenu = !generalS.showLeftMenu">
            <img
              alt="icon"
              :class="generalS.showLeftMenu ? '' : 'rotate-180'"
              src="./assets/svg/collapse.svg" />
          </button>
        </div>

        <div
          :class="generalS.showLeftMenu ? 'overflow-y-auto' : 'overflow-y-hidden'"
          class="flex-grow overflow-y-auto">
          <left-side-bar v-if="is2FaVerified" />
        </div>
      </div>
    </div>
    <!-- <div v-if="is2FaVerified"> -->
    <div
      class="flex h-auto w-screen flex-col md:w-full"
      :class="isLoggedIn ? 'max-[1024px]:pl-[80px] max-[767px]:pl-0' : ''">
      <header
        class="sticky top-0"
        v-if="is2FaVerified && (isLoggedIn || !isHome)"
        :class="generalS.showLeftMenu ? 'z-[99991]' : 'z-[99999]'">
        <top-header class="" />
      </header>

      <div
        class="no-scrollbar   w-full flex-1  md:min-h-full"
        v-if="is2FaVerified">
        <div
          ref="mainScrollbar"
          class="!mt-0 flex main_games w-full grow touch-pan-y flex-col    min-h-[calc(100vh - 114px)]">
          <router-view />
          <main-footer
            v-if="
              ((!isMobile || isHome || isTransaction) && isLoggedIn) ||
              (!isHome && !isLoggedIn)
            " />
        </div>
      </div>

      <div
        class="z-50 w-full sticky bottom-0 overflow-hidden"
        v-if="isLoggedIn || !landingPage">
        <mobile-navigator class="z-50 block md:hidden" v-if="is2FaVerified" />
      </div>
    </div>

    <div
      v-if="generalS.showChat && (isLoggedIn || !landingPage)"
      :class="
        isLandscape && isMobileOnly ? 'landscape:chat-landscape' : 'landscape:sticky'
      "
      class="sticky top-0 hidden h-screen w-full bg-secondary md:block md:min-w-[340px] md:max-w-[340px]">
      <div v-if="is2FaVerified">
        <chat-bar />
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import LeftSideBar from '@/components/Sidebar/LeftSideBar.vue';
import TopHeader from '@/components/TopHeader.vue';
import ChatBar from '@/components/Chat/ChatBar.vue';
import PaymentConfirmModel from '@/components/Bank/PaymentConfirmModel.vue';
import { generalStore } from '@/store/pinia/generalStore';
import MainFooter from '@/components/MainFooter.vue';
import MobileNavigator from '@/components/MobileNavigator.vue';
import Bank from '@/components/Bank/Bank.vue';
import UserStats from '@/components/Stats/UserStats.vue';
import GeoBlocked from '@/components/GeoBlocked.vue';
import VeriffBouns from '@/components/VeriffBouns.vue';
import LoginOrRegister from '@/components/Auth/LoginOrRegister.vue';
import { mapGetters } from 'vuex';
import LoadingOverlay from './components/Shared/LoadingOverlay.vue';
// import SoftswissBetInfo from "@/components/Softswiss/SoftswissBetInfo.vue";
 
 
 
import { rebuildEcho } from '@/lib/echo';
import { isMobileOnly } from 'mobile-device-detect';
// import Radar from 'radar-sdk-js';
// import 'radar-sdk-js/dist/radar.css';
import axios from '@/lib/myAxios';
import ChatRules from '@/components/Chat/ChatRules.vue';
import FullScreenLoader from '@/components/Shared/FullScreenLoader.vue';
import PromoOptInModal from '@/components/Settings/PromoOptInModal.vue';

export default {
  name: 'App',
  components: {
    ChatRules,
    GeoBlocked,
    VeriffBouns,
    Bank,
    UserStats,
    MainFooter,
    ChatBar,
    TopHeader,
    PerfectScrollbar,
    LeftSideBar,
    MobileNavigator,
    LoginOrRegister,
    LoadingOverlay,
    
    FullScreenLoader,
 
    PaymentConfirmModel,
    PromoOptInModal,
  },
  setup() {
    const generalS = generalStore();
    const isLandscape = window.matchMedia('(orientation: landscape)').matches;

    return { generalS, isLandscape, isMobileOnly };
  },
  computed: {
    ...mapGetters(['me', 'isUserObjectFetched', 'isLoggedIn', 'landingPage']),
    isHome() {
      return this.$route.name === 'Home';
    },
    isTransaction() {
      return this.$route.name === 'MyTransactions';
    },
    isChat() {
      return this.$route.name === 'ChatBar';
    },
    isMobile() {
      return window.innerWidth < 768;
    },
    isLoading() {
      return this.generalS.isLoading;
    },
    isPromoSettingsNull() {
      return this.isUserObjectFetched && this.me.opt_in_promo_sms === null && this.me.opt_in_promo_email === null;
    },
    isPromoOptInModalVisible() {
      if (this.isPromoOptInDisabled) {
        return false;
      }
      if (this.tab || this.isLoading || this.isPromoOptInModalClosed) {
        return false;
      }
      return this.isPromoSettingsNull;
    },
  },
  created() {
    this.generalS.isLoading = true;
    window.swal = this.$swal;
    window.toast = this.$toast;

    rebuildEcho(); 
    // this.onScroll();
    if(import.meta.env.VITE_NODE_ENV == 'production')
    window.gtm= this.$gtm;
  },
  watch: {
    '$route.query.tab': {
      handler: function (newState) {
        this.tab = newState;
      },
      deep: true,
      immediate: true,
    },
    '$route.query.rbc': {
      handler: function (newState) {
        setTimeout(() => this.claimBonus(newState), 3000);
      },
      deep: true,
      immediate: true,
    },
    '$route.query.first_time_login': {
      handler: function (newState) {
        if (newState == 1) {
          try {
            axios.get('canhaveveriffpopup').then((data) => this.openVeriffbonus(data));
          } catch(error) {
            console.log('verification issue');
          }
        }
      },
      deep: true,
      immediate: true,
    },
    $route: {
      handler: function (newState) {
        setTimeout(() => {
          if (this.$refs.mainScrollbar) {
            this.$refs.mainScrollbar.scrollTop = 0;
          }
        }, 50);
      },
      deep: true,
      immediate: true,
    },
    me() {
      this.generalS.user = this.me;
      this.initIntercom();
    },
  },
  data() {
    return {
      windowWidth: window.innerHeight,
      tab: null,
      first_time_login: 0,
      is2FaVerified: true,
      isAjaxLoading: false,
      payloading: true,
      startLoader: false,
      intercom: { booted: false, initialized: false },
      isPromoOptInModalClosed: false,
      isPromoOptInDisabled: true, // hardcoded until ready for release
    };
  },
  methods: {
    async openVeriffbonus(data) {
      this.first_time_login = data.data.can;
    },
    async claimBonus(code) {
      if (!code || code.length === 0) {
        return;
      }
      this.isAjaxLoading = true;
      try {
        axios
        .post('bonus', { code, fp: window.fp })
        .then((res) => {
          this.isAjaxLoading = false;
          this.$toast.success('Processing bonus claim!');
        })
        .catch(({ data }) => {
          this.isAjaxLoading = false;
          if(typeof data.error !=="undefined")
           this.$toast.error(data.error);
        });
      } catch(error) {
        console.log('issue with bonus post');
        this.isAjaxLoading  = false;
      }
    },
    initIntercom() {
      let intercomSettings = {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'djlucdqw',
        alignment: 'left',
        hide_default_launcher: true,
      };
      if (this.isLoggedIn && this.me && this.me.id) {
        if (this.intercom.booted) {
          this.intercomSettings = {};
        }
        intercomSettings = {
          name: this.me.username,
          user_id: this.me.id,
          user_hash: this.me.intercomHash,
          created_at: this.me.created,
          ...intercomSettings,
        };
      }
      const action = this.intercom.booted ? 'update' : 'boot';
      window.Intercom(action, intercomSettings);
      this.intercom.booted = true;
      
    },
    logout() {
      this.$store.dispatch('logout');
    },
    startRadarTracking(delay = 0) {
      if (this.isLoggedIn) {
        this.radarTrackUser(delay);
      } else {
        this.radarTrack();
      }
    },
    checkLocationPermission() {
      this.startRadarTracking(2000);
    },
    payloaddone() {
      this.payloading = false;
    },
  },
  mounted() {
    // try {
    //   let radar_config = {
    //     logLevel: 'info',
    //     cacheLocationMinutes: 2,
    //   };
    //
    //   if (!navigator.geolocation)
    //     this.geoLocationPermissionEvent(
    //       'Geo location is not supported in your browser, please switch browser. If you need additional help, please email: help@juked.co',
    //     );
    //
    //   Radar.initialize(import.meta.env.VITE_RADAR_KEY, radar_config);
    //
    //   if (this.isLoggedIn) {
    //     this.checkLocationPermission();
    //   } else {
    //     localStorage.removeItem('radar-cached-location');
    //   }
    // } catch(error) {
    //   console.log('there is issue with radar init');
    // }

    setTimeout(async () => {
      // if(this.isLoggedIn && !$store.getters.me.phone_number_verified) {
      //   this.isLoading = false;
      //   const { value: phoneNumber,isDismissed: dismissed } = await window.swal.fire({
      //     title: 'For enhanced security, Juked requires you to verify your phone number in order to play.',
      //     input: 'text',
      //     inputValue: '',
      //     inputPlaceholder: '1234567890',
      //     showCancelButton: false,
      //     confirmButtonText: 'Submit',
      //     showLoaderOnConfirm: true,
      //     customClass: {
      //         input : "mySwalInput"
      //     },
      //     preConfirm: (number) => {
      //       return axios
      //       .patch("user/profile/phone-number", { phone_number: number })
      //       .then((res) => {
      //         return response.json();
      //       })
      //       .catch(( {data}) => {
      //         if(data) {
      //           window.swal.showValidationMessage(
      //             `Error: ${data.error}`
      //           )
      //         }
      //       });
      //     },
      //     allowOutsideClick: false,
      //     allowEscapeKey: false
      //   })

      //   if(phoneNumber) {
      //     $store.dispatch("updateUserPhoneNumber", phoneNumber)
      //     axios
      //     .post("user/send-verification-code", {})
      //     .then(async (res) => {
      //       window.swal.fire({
      //         title: 'We texted you a verification code to your cell phone number. Please enter the code to verify!',
      //         input: 'number',
      //         inputValue: '',
      //         inputPlaceholder: 'Enter code',
      //         showCancelButton: false,
      //         confirmButtonText: 'Submit',
      //         showLoaderOnConfirm: true,
      //         customClass: {
      //             input : "mySwalInput"
      //         },

      //         inputValidator: (value) => {
      //           if (value.length !== 6) {
      //             return 'Code needs to 6 characters'
      //           }
      //         },
      //         preConfirm: (verificationCode) => {
      //           return axios
      //           .post("user/verify-code", {phone_number: phoneNumber, verification_code: verificationCode, first_time:true, username: $store.getters.me.username})
      //           .then((res) => {
      //             let newAccessToken = res.data.access_token;
      //             window.localStorage["dd-auth"] = newAccessToken;
      //             $store.dispatch("updateUserAccessToken", newAccessToken);
      //             return res.json();
      //           })
      //           .catch(( {data}) => {
      //             if(data) {
      //               window.swal.showValidationMessage(
      //                 `Error: ${data.error}`
      //               )
      //             }
      //           });
      //         },
      //         allowOutsideClick: false,
      //           allowEscapeKey: false
      //       }).then((result) => {
      //         if (result.isConfirmed) {
      //           window.swal.fire(
      //             'Verification successful! All your other sessions will be locked out.',
      //           );
      //           setTimeout(() => {
      //             window.location.reload();
      //             this.$router.push({ path: "/" }).then(() => {
      //               this.$router.go();
      //             });
      //           }, 3000);
      //         }
      //       });

      //     })
      //     .catch(({ data }) => {
      //       this.$toast.error(data.error);
      //     });
      //   }

      // } else {
      // this.is2FaVerified = true;
      // this.isAjaxLoading = false;

      // }
    }, 2000);

    this.windowWidth = window.innerWidth;
    if (this.windowWidth <= 1200) {
      this.generalS.showLeftMenu = false;
    }
    if (this.windowWidth <= 767) {
      this.generalS.showLeftMenu = true;
    }

    window.onresize = () => {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1200) {
        this.generalS.showLeftMenu = false;
      }
      if (this.windowWidth <= 767) {
        this.generalS.showLeftMenu = true;
      }
    };
    setTimeout(() => this.initIntercom(), 1500);

    let obj = this;
    setTimeout(() => {
      imagesLoaded(document.getElementsByTagName('body')[0], function (instance) {
        obj.generalS.isLoading = false;

        setTimeout(() => {
          document.querySelector('body').classList.add('active');
        }, 600);
      });
    }, 2000);

    this.startLoader = true;
  },
};
</script>

<style scoped>
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color:
    /*#132023*/ #64696a transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #64696a;
  border-radius: 10px;
  border: 1px solid transparent;
}

/* customize scrollbar css */
#runebet-scrollbar {
  -webkit-overflow-scrolling: touch;
}

#runebet-scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: #132023;
}

#runebet-scrollbar::-webkit-scrollbar:horizontal {
  height: 12px;
}

#runebet-scrollbar::-webkit-scrollbar-track {
  border: 1px #64696a solid;
  border-radius: 10px;
  -webkit-box-shaxdow: 0 0 6px #64696a inset;
}

#runebet-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(100, 105, 106, 0.4);
  border: 1px solid #64696a;
  border-radius: 16px;
}

#runebet-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #64696a;
  border: 1px solid #333333;
}

#runebet-scrollbar::-webkit-scrollbar-thumb:active {
  background-color: #64696a;
  border: 1px solid #64696a;
}

</style>



