<template>
  <div
    class="top-header-inner relative flex w-full flex-row items-center justify-center bg-[#343b48] lg:px-4">
    <!-- <button
      v-if="generalS.showLeftMenu"
      :class="generalS.showLeftMenu ? 'max-[1024px]:left-[218px]' : ''"
      class="hidden md:block absolute z-50 -left-5 h-11 rounded-xl bg-secondary-light px-4 py-3 font-semibold hover:opacity-70 max-[1024px]:z-[999992]"
      v-on:click="generalS.showLeftMenu = false">
      <img alt="icon" src="../assets/svg/collapse.svg" />
    </button> -->

    <div
      class="flex h-[79px] w-full max-w-[1200px] items-center px-2 md:px-4 lg:ml-4 max-[480px]:gap-x-1/2 max-[480px]:h-[60px] max-[767px]:border-b border-line-color">
      <router-link to="/" class="flex flex-none items-center md:pl-1 text-2xl">
        <img
          alt="icon"
          class="h-8 max-[1024px]:h-6 max-[480px]:h-[clamp(0.75rem,4.375vw_-_0.125rem,1.188rem)]"
          src="../assets/logo.png" />
       
      </router-link>
      <div class="grow">
        <div
          v-if="isLoggedIn"
          class="flex items-center justify-center max-[575px]:gap-x-0 gap-x-4">
          <balance />
          <button
            class="flex-inline flex min-[481px]:h-[40px] max-[480px]:h-[36px] flex-row items-center justify-center gap-2 min-[576px]:rounded-xl max-[575px]:rounded-r-lg bg-accent font-semibold text-white/90 hover:opacity-70 md:h-12 md:w-auto md:px-5 md:py-3 bg-prim-highlight shadow-btn shadow-accent px-2 max-[767px]:text-sm max-[575px]:w-[clamp(2.125rem, 2.353vw + 1.654rem, 2.5rem)]"
            v-on:click="$router.push({ path: $route.path, query: { tab: 'bank' } })">
            <img
              alt="icon"
              class="h-6 md:h-8 max-[480px]:h-[clamp(1.25rem,3.75vw_+_0.5rem,1.625rem)]"
              src="../assets/3d/wallet.png" />
            <span class="max-[575px]:hidden"> Wallet</span>
          </button>
        </div>
      </div>
      <div class="flex-none">
        <div class="flex-inline flex items-center space-x-1 max-[480px]:space-0">
          <div
            v-if="isLoggedIn"
            class="flex-inline flex items-center md:space-x-1 space-x-[clamp(0.313rem,0.671vw_+_0.178rem,0.5rem)]">
             
            <profile-popup v-if="isLoggedIn" />
            <!-- <button
              class="flex-inline hidden h-8 w-8 items-center rounded-xl font-semibold transition delay-0 duration-150 ease-in-out hover:scale-105 hover:opacity-70 md:flex"
              v-on:click="generalS.showChat = !generalS.showChat"
            >
              <img alt="icon" src="../assets/svg/chat.svg" />
            </button> -->
          </div>
          <div v-else class="flex items-center space-x-4">
            <button
              class="flex-inline flex items-center rounded-xl px-4 py-2 font-semibold transition delay-0 duration-150 ease-in-out hover:scale-105 hover:opacity-70"
              v-on:click="$router.push({ path: $route.path, query: { tab: 'login' } })">
              Login
            </button>
            <button
              class="flex-inline flex items-center shadow-btn rounded-md bg-onprimary px-4 py-2 font-semibold text-white transition delay-0 duration-150 ease-in-out hover:scale-105 hover:opacity-70"
              v-on:click="
                $router.push({ path: $route.path, query: { tab: 'register' } })
              ">
              Register
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Balance from '@/components/Bank/Balance.vue';
import { mapGetters } from 'vuex';
import { generalStore } from '@/store/pinia/generalStore';
import ProfilePopup from './Home/ProfilePopup.vue';

export default {
  name: 'TopHeader',
  components: { Balance, ProfilePopup },
  setup() {
    const generalS = generalStore();
    return { generalS };
  },

  computed: {
    ...mapGetters(['me', 'isLoggedIn']),
  },
  methods: {
    toggleSearch() {
      this.generalS.showSearch = !this.generalS.showSearch;
    },
  },
};
</script>

<style scoped></style>
