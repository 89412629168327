<template>
  <div class="px-4">
    <div
      class="max-w-[450px] mx-auto md:p-6 p-3 md:mt-24 w-full mt-8 bg-[rgb(37_37_70_/_30%)] rounded-xl">
      <h1 class="text-xl font-bold text-white mb-2 max-[767px]:text-lg">
        Reset password
      </h1>
      <div class="flex flex-col">
        <form @submit.stop.prevent="() => updatePassword()">
          <label
            class="mt-4 max-[575px]:mt-2 block mb-2 text-sm font-medium text-text-dim-color/80"
            for="email-address"
            >Email Address</label
          >
          <div class="relative opacity-80">
            <div
              class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg
                class="opacity-60"
                fill="none"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  clip-rule="evenodd"
                  d="M2.32698 5.63803C2 6.27976 2 7.11984 2 8.8V15.2C2 16.8802 2 17.7202 2.32698 18.362C2.6146 18.9265 3.07354 19.3854 3.63803 19.673C4.27976 20 5.11984 20 6.8 20H17.2C18.8802 20 19.7202 20 20.362 19.673C20.9265 19.3854 21.3854 18.9265 21.673 18.362C22 17.7202 22 16.8802 22 15.2V8.8C22 7.11984 22 6.27976 21.673 5.63803C21.3854 5.07354 20.9265 4.6146 20.362 4.32698C19.7202 4 18.8802 4 17.2 4H6.8C5.11984 4 4.27976 4 3.63803 4.32698C3.07354 4.6146 2.6146 5.07354 2.32698 5.63803ZM7.56569 8.17538C7.11026 7.86296 6.4878 7.97889 6.17538 8.43431C5.86296 8.88974 5.97889 9.5122 6.43431 9.82462L10.3029 12.4785C11.3256 13.18 12.6744 13.18 13.6971 12.4785L17.5657 9.82462C18.0211 9.5122 18.137 8.88974 17.8246 8.43431C17.5122 7.97889 16.8897 7.86296 16.4343 8.17538L12.5657 10.8293C12.2248 11.0631 11.7752 11.0631 11.4343 10.8293L7.56569 8.17538Z"
                  fill="white"
                  fill-rule="evenodd" />
              </svg>
            </div>
            <input
              id="email-address"
              v-model="form.email"
              class="input-bet pl-12 p-2.5"
              disabled
              placeholder="Your email or username here"
              readonly
              required
              type="text" />
          </div>

          <label
            class="mt-4 block mb-2 text-sm font-medium text-text-dim-color"
            for="email-address"
            >New Password</label
          >
          <div class="relative">
            <div
              class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg
                fill="none"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  clip-rule="evenodd"
                  d="M8.16146 9H15.8385C16.3657 8.99998 16.8205 8.99997 17.195 9.03057C17.5904 9.06287 17.9836 9.13419 18.362 9.32698C18.9265 9.6146 19.3854 10.0735 19.673 10.638C19.8658 11.0164 19.9371 11.4096 19.9694 11.805C20 12.1795 20 12.6344 20 13.1615V17.8386C20 18.3657 20 18.8205 19.9694 19.195C19.9371 19.5904 19.8658 19.9836 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.9836 21.8658 17.5904 21.9371 17.195 21.9694C16.8205 22 16.3657 22 15.8385 22H8.16145C7.63431 22 7.17954 22 6.80498 21.9694C6.40963 21.9371 6.01641 21.8658 5.63803 21.673C5.07355 21.3854 4.6146 20.9265 4.32698 20.362C4.13419 19.9836 4.06287 19.5904 4.03057 19.195C3.99997 18.8205 3.99998 18.3657 4 17.8386V13.1614C3.99998 12.6343 3.99997 12.1795 4.03057 11.805C4.06287 11.4096 4.13419 11.0164 4.32698 10.638C4.6146 10.0735 5.07355 9.6146 5.63803 9.32698C6.01641 9.13419 6.40963 9.06287 6.80498 9.03057C7.17953 8.99997 7.63434 8.99998 8.16146 9ZM12 15C11.4477 15 11 15.4477 11 16V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V16C13 15.4477 12.5523 15 12 15Z"
                  fill="white"
                  fill-rule="evenodd" />
                <path
                  clip-rule="evenodd"
                  d="M8 6C8 3.79086 9.79086 2 12 2C14.2091 2 16 3.79086 16 6V9H14V6C14 4.89543 13.1046 4 12 4C10.8954 4 10 4.89543 10 6V9H8V6Z"
                  fill="white"
                  fill-rule="evenodd" />
              </svg>
            </div>
            <input
              id="email-address"
              v-model="form.password"
              class="input-bet pl-12 p-2.5"
              placeholder="New Password"
              required
              type="password" />
          </div>

          <label
            class="block mb-2 mt-4 text-sm font-medium text-text-dim-color"
            for="email-address"
            >Confirm Password</label
          >
          <div class="relative">
            <div
              class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg
                fill="none"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  clip-rule="evenodd"
                  d="M8.16146 9H15.8385C16.3657 8.99998 16.8205 8.99997 17.195 9.03057C17.5904 9.06287 17.9836 9.13419 18.362 9.32698C18.9265 9.6146 19.3854 10.0735 19.673 10.638C19.8658 11.0164 19.9371 11.4096 19.9694 11.805C20 12.1795 20 12.6344 20 13.1615V17.8386C20 18.3657 20 18.8205 19.9694 19.195C19.9371 19.5904 19.8658 19.9836 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.9836 21.8658 17.5904 21.9371 17.195 21.9694C16.8205 22 16.3657 22 15.8385 22H8.16145C7.63431 22 7.17954 22 6.80498 21.9694C6.40963 21.9371 6.01641 21.8658 5.63803 21.673C5.07355 21.3854 4.6146 20.9265 4.32698 20.362C4.13419 19.9836 4.06287 19.5904 4.03057 19.195C3.99997 18.8205 3.99998 18.3657 4 17.8386V13.1614C3.99998 12.6343 3.99997 12.1795 4.03057 11.805C4.06287 11.4096 4.13419 11.0164 4.32698 10.638C4.6146 10.0735 5.07355 9.6146 5.63803 9.32698C6.01641 9.13419 6.40963 9.06287 6.80498 9.03057C7.17953 8.99997 7.63434 8.99998 8.16146 9ZM12 15C11.4477 15 11 15.4477 11 16V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V16C13 15.4477 12.5523 15 12 15Z"
                  fill="white"
                  fill-rule="evenodd" />
                <path
                  clip-rule="evenodd"
                  d="M8 6C8 3.79086 9.79086 2 12 2C14.2091 2 16 3.79086 16 6V9H14V6C14 4.89543 13.1046 4 12 4C10.8954 4 10 4.89543 10 6V9H8V6Z"
                  fill="white"
                  fill-rule="evenodd" />
              </svg>
            </div>
            <input
              id="email-password"
              v-model="form.password_confirmation"
              class="input-bet pl-12 p-2.5"
              placeholder="Password confirmation"
              required
              type="password" />
          </div>

          <button
            :disabled="loading"
            class="bg-onprimary disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:opacity-50 hover:opacity-70 w-full mt-8 p-3 text-white font-semibold rounded-xl">
            {{ loading ? 'Please wait...' : 'Update Password' }}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { doRecaptcha } from '@/lib/recaptcha';
import { authStore } from '@/store/pinia/authStore';

export default {
  name: 'PasswordReset',
  setup() {
    return {
      authS: authStore(),
    };
  },
  data() {
    return {
      form: {
        password_confirmation: '',
        password: '',
        token: '',
        email: '',
        recaptcha: '',
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
  watch: {
    '$route.query.email': {
      handler: function (newState) {
        this.form.email = newState;
      },
      deep: true,
      immediate: true,
    },
    '$route.params.token': {
      handler: function (newState) {
        this.form.token = newState;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async updatePassword() {
      if (this.form.password !== this.form.password_confirmation) {
        this.$toast.error('Passwords do not match');
        return;
      }
      try {
        this.loading = true;
        this.form.token = this.$route.params.token;
        this.form.recaptcha = await doRecaptcha('update_password');
        await this.authS.updatePassword(this.form);
        this.$toast.success('Password was updated. Logging in...');
        await this.login();
      } catch (e) {
        const { data } = e;
        if (typeof data.error !=="undefined") {
          this.$toast.error(data.error);
        } else {
          window.swal('Failed to update password', 'Reload page and try again.', 'error');
        }
      } finally {
        this.loading = false;
      }
    },
    async login() {
      try {
        this.form.token = await doRecaptcha('login');
        this.form.username = this.form.email;
        await this.performLogin(this.form);
        setTimeout(() => {
          if (this.isLoggedIn) {
            window.location.href = '/';
          }
        }, 1000);
      } catch (e) {
        window.swal('Failed to login', 'Reload page and try again.', 'error');
      }
    },
    ...mapActions(['performLogin', 'performGoogleLogin']),
  },
};
</script>
