<template>
  <div v-if="isLoggedIn">
    <Menu as="div" class="relative md:ml-3">
      <div>
        <MenuButton
          class="bg-secondary-light w-10 h-10 md:w-12 md:h-12 flex flex-row items-center justify-center rounded-xl hover:opacity-70 font-semibold md:flex flex-inline transition ease-in-out delay-0 hover:scale-105 duration-150 max-[480px]:p-0 max-[480px]:w-[clamp(2.25rem,7.5vw_+_0.75rem,3rem)] max-[480px]:h-[clamp(2.25rem,7.5vw_+_0.75rem,3rem)] max-[480px]:rounded-[2vw]">
          <span class="sr-only">Open user menu</span>
          <img
            class="w-5 h-5 md:h-6 md:w-6- max-[480px]:h-[clamp(0.938rem,6.875vw_-_0.438rem,1.625rem)] max-[480px]:w-auto"
            src="../../assets/svg/person.svg" />
        </MenuButton>
      </div>
      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0">
        <MenuItems
          class="absolute right-0 md:w-48 w-32 md:py-4 py-1 mt-2 origin-top-right rounded-md shadow-lg bg-secondary-light ring-1 ring-black ring-opacity-5 focus:outline-none">
          <MenuItem v-for="(menu, idx) in profileMenus" :key="idx" v-slot="{ active }">
            <router-link
              :class="[
                active ? 'bg-tertiary/60 text-slate-300' : 'text-white',
                'block px-4 py-2 md:text-sm text-[0.9rem]	font-bold text-left',
              ]"
              :to="menu.link"
              href="#">
              {{ menu.name }}
            </router-link>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <button
              :class="[
                active ? 'bg-tertiary/60 text-slate-300' : 'text-white',
                'cursor-pointer block px-4 py-2 md:text-sm text-[0.9rem] font-bold text-left',
              ]"
              class="w-full text-left"
              v-on:click="showIntercome">
              Live Support
            </button>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <button
              :class="[
                active ? 'bg-tertiary/60 text-slate-300' : 'text-white',
                'cursor-pointer block px-4 py-2 md:text-sm text-[0.9rem] font-bold text-left',
              ]"
              class="w-full text-left"
              v-on:click="handleShowWagerProgress">
              VIP
            </button>
          </MenuItem>
          <MenuItem v-slot="{ active }" v-if="me.can_create_challenges">
            <RouterLink
              :class="[
                active ? 'bg-tertiary/60 text-slate-300' : 'text-white',
                'cursor-pointer block px-4 py-2 md:text-sm text-[0.9rem] font-bold text-left',
              ]"
              class="w-full text-left"
              :to="'/challenges/create'">
              Challenge
            </RouterLink>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <button
              :class="[
                active ? 'bg-tertiary/60 text-slate-300' : 'text-white',
                'cursor-pointer block px-4 py-2 md:text-sm text-[0.9rem] font-bold text-left',
              ]"
              class="w-full text-left"
              v-on:click="logout">
              Logout
            </button>
          </MenuItem>
        </MenuItems>
      </transition>
    </Menu>
    <punt-modal
      v-if="isWagerProgressVisible"
      show-close="true"
      :onClose="toggleProgressModal">
      <template #title>
        <div class="w-full">
          <span class="flex items-center p-2">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.9971 1.99902C10.9331 1.99902 9.94508 2.37102 9.09107 2.99902C8.05907 3.16302 7.08707 3.59602 6.34107 4.34302C5.59407 5.09002 5.16107 6.06102 4.99707 7.09302C4.36907 7.94702 3.99707 8.93502 3.99707 9.99902C3.99707 11.063 4.36907 12.051 4.99707 12.905C5.16107 13.937 5.59407 14.908 6.34107 15.655C6.53107 15.845 6.78108 15.943 6.99708 16.093V20.999C6.99708 21.551 7.44508 21.999 7.99708 21.999H8.99708C9.10408 21.999 9.20809 21.971 9.30909 21.937L11.9971 21.03L14.6851 21.937C14.7861 21.97 14.8901 21.999 14.9971 21.999H15.9971C16.5491 21.999 16.9971 21.551 16.9971 20.999V16.093C17.2131 15.943 17.4631 15.845 17.6531 15.655C18.4001 14.908 18.8331 13.937 18.9971 12.905C19.6251 12.051 19.9971 11.063 19.9971 9.99902C19.9971 8.93502 19.6251 7.94702 18.9971 7.09302C18.8331 6.06102 18.4001 5.09002 17.6531 4.34302C16.9061 3.59602 15.9351 3.16302 14.9031 2.99902C14.0491 2.37102 13.0611 1.99902 11.9971 1.99902ZM11.9971 3.99902C15.3111 3.99902 17.9971 6.68502 17.9971 9.99902C17.9971 13.313 15.3111 15.999 11.9971 15.999C8.68308 15.999 5.99707 13.313 5.99707 9.99902C5.99707 6.68502 8.68308 3.99902 11.9971 3.99902ZM11.9971 5.99902C9.78808 5.99902 7.99708 7.79002 7.99708 9.99902C7.99708 12.208 9.78808 13.999 11.9971 13.999C14.2061 13.999 15.9971 12.208 15.9971 9.99902C15.9971 7.79002 14.2061 5.99902 11.9971 5.99902Z"
                fill="white" />
            </svg>

            VIP
          </span>
          <hr class="w-full h-px my-2 border-0 dark:bg-gray-700" />
        </div>
      </template>
      <template #body>
        <vip @onClose="handleShowWagerProgress()" />
      </template>
    </punt-modal>
  </div>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { mapGetters } from 'vuex';
import PuntModal from '@/components/Shared/PuntModal.vue';
import Vip from './Vip.vue';
export default {
  name: 'ProfilePopup',

  components: { Menu, MenuButton, MenuItem, MenuItems, PuntModal, Vip },
  data() {
    return {
      isWagerProgressVisible: false,
    };
  },
  setup() {
    const profileMenus = [
      { name: 'Profile', link: '/settings/general' },
      { name: 'Transactions', link: '/transactions/purchases' },
      { name: 'Affiliate', link: '/affiliates/overview' },
    ];
    return {
      profileMenus,
    };
  },
  computed: {
    ...mapGetters(['me', 'isLoggedIn']),
  },
  created() {
    window.toast = this.$toast;
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
    toggleProgressModal() {
      this.isWagerProgressVisible = !this.isWagerProgressVisible;
    },
    async handleShowWagerProgress() {
      this.toggleProgressModal();
    },
    showIntercome()
    {
      if( window.Intercom)
      {
        window.Intercom('showMessages');
      }
    }
  },

};
</script>

<style>
@media (min-width: 1892px) {
  .profile-popup {
    right: auto;
  }
}
</style>
