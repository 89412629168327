<template>
  <ssn-verification v-if="!ssnveriff" :onSuccess="onssnSuccess" />

  <punt-modal size="max-w-2xl" :onClose="closeModal" :show-close="true" :show="showModal">
    <template #title>
      <h1>Postal Card Code Genterator</h1>
    </template>
    <template #body>
      <div class="p-4 flex flex-col">
        <form @submit.stop.prevent="() => forgotPassword()">
          <label
            for="email-address"
            class="mt-4 block mb-2 text-sm font-medium text-text-dim-color"
            >Email Address</label
          >
          <div class="relative">
            <div
              class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.32698 5.63803C2 6.27976 2 7.11984 2 8.8V15.2C2 16.8802 2 17.7202 2.32698 18.362C2.6146 18.9265 3.07354 19.3854 3.63803 19.673C4.27976 20 5.11984 20 6.8 20H17.2C18.8802 20 19.7202 20 20.362 19.673C20.9265 19.3854 21.3854 18.9265 21.673 18.362C22 17.7202 22 16.8802 22 15.2V8.8C22 7.11984 22 6.27976 21.673 5.63803C21.3854 5.07354 20.9265 4.6146 20.362 4.32698C19.7202 4 18.8802 4 17.2 4H6.8C5.11984 4 4.27976 4 3.63803 4.32698C3.07354 4.6146 2.6146 5.07354 2.32698 5.63803ZM7.56569 8.17538C7.11026 7.86296 6.4878 7.97889 6.17538 8.43431C5.86296 8.88974 5.97889 9.5122 6.43431 9.82462L10.3029 12.4785C11.3256 13.18 12.6744 13.18 13.6971 12.4785L17.5657 9.82462C18.0211 9.5122 18.137 8.88974 17.8246 8.43431C17.5122 7.97889 16.8897 7.86296 16.4343 8.17538L12.5657 10.8293C12.2248 11.0631 11.7752 11.0631 11.4343 10.8293L7.56569 8.17538Z"
                  fill="white" />
              </svg>
            </div>
            <input
              :modal="postalCode"
              type="text"
              class="bg-secondary placeholder:text-text-dim-color border border-secondary text-slate-300 text-sm rounded-xl font-semibold focus:ring-green-600 focus:border-green-600 block w-full pl-12 p-2.5" />
          </div>
        </form>
      </div>
      <div class="text-block"></div>
    </template>
  </punt-modal>

  <punt-modal v-if="veriffpop"  :onClose="closeModal" :show-close="true"  >
        <template #title  >
            <div class="p-4">
                <h1 class="text-lg"><b>Please verify your identity</b></h1>
            </div>  
            
        </template>
        <template #body>
            <div class="p-4  ">
                <div   class="flex flex-col gap-4 text-lg text-slate-300/60 font-sesmibold">

                  <p>One final step before you can continue!</p>
                  <p>
                    Please complete our quick and easy verification process. Your submission will be
                    automatically reviewed for approval.
                  </p>

                  <p>Please double check you meet the following requirements:</p>
                  <ul class="list-disc ml-4">
                    <li>You are at least 21 years old.</li>
                    <li>
                      You need to use the physical documents when trying to verify. Do NOT use
                      screenshots or previously taken photos.
                    </li>
                    <li>
                      You have not already verified an existing account. You cannot create multiple
                      accounts.
                    </li>
                    <li>
                      Unfortunately we cannot currently serve people from the following US states:
                      Washington, Idaho, Kentucky, Nevada and Michigan.
                    </li>
                  </ul>
                </div>

                <div class="w-full flex flex-col items-center justify-center mt-4">
                     <VerifyLink/>
                </div>
                
            </div>
           
        </template>
    </punt-modal>
  <div class="flex w-full flex-col items-center privacy-policy-div parent">
    <div class="logo mb-5" style="text-align: center;">
      <img alt="icon" class="h-[12px] md:h-8" src="../../assets/logo.png" />
    </div>
    <h1 style="text-indent: 0pt; text-align: left" class="logo mb-5" >Juked Sweeps Rules</h1>
    <h3 style="padding-top: 6pt; padding-left: 10pt; text-indent: 0pt; text-align: left" class="logo mb-5">
      DATE OF LAST UPDATE: September 16, 2024
    </h3>
     
    <div class="paragraph mb-5" style="border:5px solid #edd05a;padding: 10px 20px;">
      <p class="mb-1">NO PURCHASE OR PAYMENT NECESSARY TO PARTICIPATE. A PURCHASE OR PAYMENT OF ANY KIND WILL NOT INCREASE A PARTICIPANT'S CHANCES OF WINNING.</p>
      <p class="mt-1" >Juked is an online platform which offers Participants the chance to play games for entertainment and enjoyment. Juked also gives away sweepstakes entries referred to as “Juked Cash” as set out in these Sweeps Rules. Juked Cash can be used to Participate in Promotional Play for a chance to win further Juked Coins which are redeemable for real prizes.</p>
      <p>JUKED CASH ARE SUBJECT TO A MINIMUM PRIZE REDEMPTION THRESHOLD OF $US100. AMOUNTS BELOW THE THRESHOLD ARE NOT ELIGIBLE FOR PRIZE REDEMPTION.</p>
      <p>These Sweeps Rules are to be read in conjunction with the Juked Terms and Conditions (<a target="_blank" href="https://juked.co/docs/Terms-And-Conditions.pdf">T&Cs</a>). Terms which are defined in the T&Cs have the same meaning in these Sweeps Rules.</p>
      <p>PLEASE NOTE THAT THESE SWEEPS RULES INCORPORATE CLAUSE 24 OF THE T&Cs, WHICH INCLUDE A PROVISION WAIVING THE RIGHT TO PURSUE ANY CLASS, GROUP OR REPRESENTATIVE CLAIM AND REQUIRING YOU TO PURSUE PAST, PENDING, AND FUTURE DISPUTES BETWEEN YOU AND US THROUGH INDIVIDUAL ARBITRATION UNLESS YOU OPT OUT WITHIN THE SPECIFIED TIME FRAME. SEE CLAUSE 11 OF THESE SWEEPS RULES FOR MORE INFORMATION.</p>
    </div>
    <ol id="l1">
      <li data-list-text="1.">
        <h2 style="padding-left: 45pt; text-indent: -35pt; text-align: left">
          ELIGIBILITY TO PLAY Juked
        </h2>
        <ol id="l2">
          <li data-list-text="1.1.">
            <p
              style="
                padding-top: 8pt;
                padding-left: 45pt;
                text-indent: -35pt;
                text-align: left;
              ">
              The following definitions apply to these Sweeps Rules:
            </p>
            
            <ol id="l3">
              <li data-list-text="a)">
                <h3 style="padding-left: 66pt; text-indent: -18pt; text-align: left">
                  <strong>Eligible Participants</strong> means legal residents of the Permitted Territories;
                </h3>
              </li>
              <li data-list-text="b)">
                <h3
                  style="
                    padding-top: 1pt;
                    padding-left: 66pt;
                    text-indent: -18pt;
                    line-height: 113%;
                    text-align: left;
                  ">
                  <strong>Permitted Territories</strong> means the United States (other than the states of Nevada, Kentucky, Tennessee, Hawaii, Idaho, Michigan, and Washington);
                </h3>
              </li>
              <li data-list-text="c)">
                <h3 style="padding-left: 66pt; text-indent: -18pt; text-align: left">
                  <strong>Participant</strong> means a person who participates in Promotional Play;
                </h3>
              </li>
              <li data-list-text="d)">
                <h3
                  style="
                    padding-top: 1pt;
                    padding-left: 66pt;
                    text-indent: -18pt;
                    text-align: left;
                  ">
                  <strong>Postal Request Code</strong> has the meaning set out in clause 3.4 of these Sweeps Rules;
                </h3>
              </li>
            </ol>
          </li>
          <li data-list-text="1.2.">
            <p
              style="
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              <strong>Promotional Play</strong> means Juked's sweepstakes promotions. Promotional Play is only open to Eligible Participants, who are at least eighteen (18) years old or the age of majority in their jurisdiction (whichever occurs later) at the time of entry. PARTICIPATION IS VOID WHERE PROHIBITED BY LAW.
            </p>
          </li>
          <li data-list-text="1.3.">
            <p
              style="
                padding-top: 6pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              The Sweepstakes is subject to all applicable federal, state, provincial, territorial and local laws and regulations. It is the sole responsibility of a Participant to determine whether the Sweepstakes is legal and compliant with all regulations in the jurisdiction in which the Participant resides.
            </p>
          </li>
          <li data-list-text="1.4.">
            <p
              style="
                padding-top: 5pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 113%;
                text-align: left;
              ">
              Participation constitutes the Participant's full and unconditional agreement to these Sweeps Rules and Sponsor's/Promoter's decisions, which are final and binding in all matters related to the Sweepstakes.
            </p>
          </li>
          <li data-list-text="1.5.">
            <p
              style="
                padding-top: 6pt;
                padding-left: 45pt;
                text-indent: -35pt;
                text-align: left;
              ">
              Winning a prize is contingent upon fulfilling all requirements set out in these Sweeps Rules.
            </p>
            
          </li>
          <li data-list-text="1.6.">
            <p
              style="
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              Employees of Juked Entertainment LLC, any of their respective affiliates, subsidiaries, holding companies, advertising agencies, or any other company or individual involved with the design, production, execution or distribution of the Sweepstakes and their immediate family (spouse, parents, siblings and children, whether the relationship is by birth, marriage or adoption) and household members (people who share the same residence at least 3 months of the year) are not eligible to participate.
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="2.">
        <h2
          style="
            padding-top: 6pt;
            padding-left: 45pt;
            text-indent: -35pt;
            text-align: left;
          ">
          SPONSOR/PROMOTER
        </h2>
        <p
          style="
            padding-top: 8pt;
            padding-left: 45pt;
            text-indent: 0pt;
            text-align: left;
          ">
          The <b>Sponsor/Promoter </b>of the Sweepstakes is Juked Entertainment LLC.
        </p>
      </li>
      <li data-list-text="3.">
        <h2
          style="
            padding-top: 1pt;
            padding-left: 45pt;
            text-indent: -35pt;
            text-align: left;
          ">
          HOW TO COLLECT JUKED CASH
        </h2>
        <ol id="l4">
          <li data-list-text="3.1.">
            <p
              style="
                padding-top: 8pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: justify;
              ">
              To Participate, you must have an active Customer Account. If you do not have a Customer Account, you can create one free of charge at <a href="https://juked.co/" class="a" target="_blank">https://juked.co/</a>. To enter the Sweepstakes, a Participant must access Juked at <a href="https://juked.co/" class="a" target="_blank">https://juked.co</a> and sign in to their Customer Account.
            </p>
          </li>
          <li data-list-text="3.2.">
            <p
              style="
                padding-top: 5pt;
                padding-left: 45pt;
                text-indent: -35pt;
                text-align: left;
              ">
              Once they have accessed Juked, there are four potential ways for an Eligible Participant to collect Juked Cash:
            </p>
            
            <ol id="l5">
              <li data-list-text="(a)">
                <h3 style="padding-left: 80pt; text-indent: -35pt; text-align: justify">
                  <strong>Receive Juked Cash as a free bonus when purchasing Juked Gold.</strong>
                  <span style="font-weight: 400;"> Each Participant may receive Juked Cash as a bonus upon the purchase of specifically marked packs of Juked Gold. Juked Gold can be used for social game play in Juked. Juked Gold cannot be redeemed for prizes. The number of Juked Cash a Participant will receive as a bonus for each relevant Juked Gold purchase will be stated in the website purchase window. All purchases of Juked Gold are final and no refunds will be given.</span
                  >
                </h3>
                
              </li>
              <li data-list-text="(b)">
                <h3 style="padding-left: 80pt; text-indent: -35pt; text-align: left">
                  <strong>Receive Juked Cash when entering Juked Cash no-cost giveaway contests on the Juked Facebook page.</strong>
                  <span style="font-weight: 400;"
                    > The Sponsor/Promoter regularly holds Juked Cash no-cost giveaway contests which Participants can enter by following the instructions provided in the contest. These giveaways may require sharing the Facebook page posts or answering various game related questions to be eligible for entry. The amount of Juked Cash given away will be stated on the applicable Juked Cash no-cost contest post and will be added to each contest winner's Customer Account.</span
                  >
                </h3>
                
              </li>
              <li data-list-text="(c)">
                <h3 style="padding-left: 80pt; text-indent: -35pt; text-align: left">
                  <strong>Receive Juked Cash by collecting the Hourly Bonus.</strong>
                  <span style="font-weight: 400;"
                    > Eligible Participants can receive free Juked Cash on a hourly basis by taking the following steps (</span
                  ><strong>hourly Bonus</strong> <span>):</span>
                </h3>
                <ol id="l15">
                  <li data-list-text="i."><p
                  style="
                    padding-top: 6pt;
                    padding-left: 117pt;
                    text-indent: 0pt;
                    text-align: left;
                  ">
                  sign into your Customer Account;
                </p></li>
                <li data-list-text="ii."><p style="padding-left: 117pt; text-indent: 0pt; text-align: left">
                  click the “GET COINS” button at the top of the Juked platform page;
                </p></li>
                <li data-list-text="iii."><p style="padding-left: 117pt; text-indent: 0pt; text-align: left">
                  click the “Hourly Bonus” button at the top right of the “Get Coins” pop up;
                </p></li>
                  <li data-list-text="iv."><p style="padding-left: 117pt; text-indent: 0pt; text-align: left">
                    if you are eligible, click the “Claim” button at the bottom of the Hourly Bonus tab.
                  </p></li>
                </ol>
                
                <p style="padding-left: 80pt; text-indent: 0pt; text-align: left">
                  Participants can only claim a Hourly Bonus once per hour (the hourly clock resets every hour).
                </p>
                
              </li>
              <li data-list-text="(d)">
                <h3 style="padding-left: 80pt; text-indent: -35pt; text-align: left">
                  <strong>Receive Juked Cash by sending a request by post.</strong>
                  <span style="font-weight: 400;"
                    > Participants can receive free Juked Cash by obtaining a Postal Request Code and sending a standard post card or piece of white paper
                    (</span
                  ><strong>Request Card</strong>
                  <span style="font-weight: 400;"
                    >) which must be 4” x 6”, unfolded, blank and unlined and placed inside a stamped #10 envelope addressed in handwriting, to the following address and satisfying the requirements set out below:</span
                  >
                </h3>
                
                <p
                  style="
                    padding-left: 117pt;
                    text-indent: 0pt;
                    line-height: 162%;
                    text-align: left;
                  ">
                  United States residents:<br>Juked Entertainment LLC<br>202 Walton Way Ste 192 Unit #716<br>Cedar Park, TX 78613
                </p>
                
                <p style="padding-left: 80pt; text-indent: 0pt; text-align: left">
                  Participants must:
                </p>
                <ol id="l6">
                  <li data-list-text="i.">
                    <p
                      style="
                        padding-top: 5pt;
                        padding-left: 118pt;
                        text-indent: -21pt;
                        text-align: left;
                      ">
                      handwrite their return address on the front of the envelope and the words: “Sweepstakes Entries”; and
                    </p>
                    
                  </li>
                  <li data-list-text="ii.">
                    <p style="padding-left: 118pt; text-indent: -24pt; text-align: left">
                      handwrite all of the following
                      <b>on only one side of the Request Card </b>inserted inside the
                      envelope <b>:</b>
                    </p>
                    <ol id="l7">
                      <li data-list-text="A.">
                        <p
                          style="
                            padding-left: 154pt;
                            text-indent: -18pt;
                            text-align: left;
                          ">
                          in the top-left corner, one (and only one) unique one-time
                          Postal Request Code (see clauses 3.4 and 3.5 of these Sweeps
                          Rules for further information);
                        </p>
                       
                      </li>
                      <li data-list-text="B.">
                        <p
                          style="
                            padding-left: 154pt;
                            text-indent: -18pt;
                            text-align: left;
                          ">
                          under the Postal Request Code, in the following order:
                        </p>
                       
                        <ul id="l8">
                          <li data-list-text="●">
                            <p
                              style="
                                padding-left: 172pt;
                                text-indent: -18pt;
                                text-align: left;
                              ">
                              their full name as shown on their government issued
                              identification;
                            </p>
                            
                          </li>
                          <li data-list-text="●">
                            <p
                              style="
                                padding-left: 172pt;
                                text-indent: -18pt;
                                text-align: left;
                              ">
                              the email address registered to their Juked account;
                            </p>
                            
                          </li>
                          <li data-list-text="●">
                            <p
                              style="
                                padding-left: 172pt;
                                text-indent: -18pt;
                                text-align: left;
                              ">
                              the return/residential address registered to their Juked
                              account; and
                            </p>
                          </li>
                          <li data-list-text="●">
                            <p
                              style="
                                padding-top: 3pt;
                                padding-left: 172pt;
                                text-indent: -18pt;
                                text-align: left;
                              ">
                              the following statement:
                            </p>
                            <p
                            style="
                              padding-top: 7pt;
                              padding-left: 172pt;
                              text-indent: 0pt;
                              text-align: left;
                            ">
                            "I wish to receive Juked Cash to participate in the sweepstakes promotions offered by Juked. By submitting this request, I hereby declare that I have read, understood and agree to be bound by Juked's Terms and Conditions and Sweeps Rules."
                          </p>
                          </li>
                        </ul>
                      </li>
                    </ol>
                  </li>
                  <li data-list-text="iii.">
                    <p style="padding-left: 118pt; text-indent: -24pt; text-align: left">
                      <strong>FOR THE AVOIDANCE OF DOUBT, COMBINED REQUESTS SENT VIA PACKAGE OR PARCEL DO NOT MEET THE REQUIREMENTS SET OUT ABOVE AND ARE INVALID.</strong>
                    </p>
                  </li>
                </ol>
              </li>
            </ol>

            
          </li>
          <li data-list-text="3.3.">
            <p
              style="
                padding-top: 6pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              There is a limit of one Request Card per outer envelope. For each Request Card a Participant submits in accordance with the above requirements, the Participant will receive 5 Juked Cash. The Juked Cash will be added to the Participant's Customer Account.
              <b>NOTE: A Participant must ensure that their handwriting is legible.</b> If the Participant's handwriting is not legible, the entry will be void and the Juked Cash will not be added to the Participant's Customer Account. The legibility of a Participant's handwriting will be determined by Sponsor/Promoter in its sole discretion.
              <b
                >THE REQUEST MUST ONLY BE MADE BY THE PARTICIPANT AND MUST BE POSTED FROM THE SAME STATE OR PROVINCE AS THE PARTICIPANT'S VERIFIED RESIDENTIAL ADDRESS.</b
              >.

              <b>Requests made by any other individual or any entity (including but not limited to commercial sweepstakes subscription notification and/or entering services) or posted from a state or province different to the Participant's verified residential address will be declared invalid and Juked Cash will not be added to the Participant's Customer Account. Tampering with the entry process or the operation of the Sweepstakes, including but not limited to the use of any device or software to automate the Juked Cash request/entry process or to unfairly assist the Participant with meeting the handwriting requirement, is prohibited and any requests/entries deemed by Sponsor/Promoter, in its sole discretion, to have been submitted in this manner will be void. Where there is an issue or fault with an envelope used by a Participant to request free Juked Cash, such that it does, or is reasonably likely to, cause damage to, or otherwise adversely affect, equipment used or procedures implemented to process mailed in requests (for example, handmade envelopes that are not suitable for machine processing due to poor quality or non-standard adhesive or paper), such entry will be void. In the event a dispute regarding the identity of the individual who actually submitted a request or whether a request satisfies the requirements set out above cannot be resolved to Sponsor/Promoter's satisfaction, the affected request/entry will be deemed ineligible.</b>
            </p>
          </li>
          <br />
          <li data-list-text="3.4.">
            <p
              style="
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              A <b>Postal Request Code </b>is a unique combination of 12 digits that Participants can obtain by following the steps outlined in clause 3.5 of these Sweeps Rules. A Postal Request Code is unique, can only be used once and expires 90 days after it is displayed. <b>The purpose of a Postal Request Code is to assist with the efficient and effective processing of a Participant's Request Card. Request Cards containing an expired Postal Request Code at the time of processing will be void. A POSTAL REQUEST CODE CAN ONLY BE USED BY THE PARTICIPANT WHOSE CUSTOMER ACCOUNT WAS USED TO GENERATE THAT POSTAL REQUEST CODE.</b> Any Participant suspected by Sponsor/Promoter to have directly or indirectly provided a Postal Request Code to another person may, in the sole discretion of Sponsor/Promoter, be disqualified and lose their eligibility to Participate in the Sweepstakes.
            </p>
          </li>
          <br />
          <li data-list-text="3.5.">
            <p
              style="
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              A Postal Request Code can be obtained by following each of these steps:
            </p>
          </li>
          <ol id="l9">
            <li data-list-text="a)">
              <h3 style="padding-left: 66pt; text-indent: -18pt; text-align: left">
                sign into your Customer Account; 
              </h3>
            </li>
            <li data-list-text="b)">
              <h3
                style="
                  padding-top: 1pt;
                  padding-left: 66pt;
                  text-indent: -18pt;
                  line-height: 113%;
                  text-align: left;
                ">
                click on the "Sweeps Rules" link at the footer;
              </h3>
            </li>
            <li data-list-text="c)">
              <h3 style="padding-left: 66pt; text-indent: -18pt; text-align: left">
                Clicking the hyperlinked "<a   @click.prevent="getPostalCode">(Generated here)</a>" text in clause 3.5 of these Sweeps Rules <a class="req-postal-code" @click.prevent="getPostalCode">(Generated here)</a>.
              </h3>
            </li>
          </ol>
          <p>The Postal Request Code is then displayed for a period of time to enable a Participant to record it on their Request Card.</p>
          <br />
          <li data-list-text="3.6.">
            <p
              style="
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              The amount of Juked Cash a Participant has will be displayed in their Customer Account on the website. The amount of Juked Cash to be allocated to Participants can be changed at any time by the Sponsor/Promoter in its sole discretion.
            </p>
          </li>
          <li data-list-text="3.7.">
            <p
              style="
                padding-top: 5pt;
                padding-left: 45pt;
                text-indent: -35pt;
                text-align: left;
              ">
              Sponsor/Promoter is not responsible for lost, late, incomplete, invalid, unintelligible or misdirected Juked Cash requests or allocations.
            </p>
            
          </li>
          <li data-list-text="3.8.">
            <p
              style="
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              In the event of a dispute as to any registration of a Customer Account, the authorized account holder of the email address used to register the Customer Account will be deemed to be the Participant. The "authorized account holder" is the natural person assigned the email address by an internet access provider, online service provider or other organization responsible for assigning email addresses for the domain associated with the submitted address.
            </p>
          </li>
          <li data-list-text="3.9.">
            <p
              style="
                padding-top: 5pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 115%;
                text-align: left;
              ">
              Use of any automated or other system(s) to Participate, to acquire Juked Cash or play the games is prohibited and will result in disqualification and loss of eligibility to Participate in the games.
            </p>
          </li>
          <li data-list-text="3.10.">
            <p
              style="
                padding-top: 5pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              Juked Cash are only valid for sixty (60) days from the date a Participant last logged on to their Customer Account and will then automatically expire.
            </p>
          </li>
          <li data-list-text="3.11.">
            <p
              style="
                padding-top: 6pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              Juked Cash may also be forfeited if a Participant's Customer Account is closed for any reason, or at the Sponsor/Promoter's discretion.
            </p>
          </li>
        </ol>
      </li>
       
      <li data-list-text="4.">

        <h2 style="padding-left:45pt;text-indent:-35pt;text-align:left;" data-v-a7f63d94=""> USING JUKED CASH TO PLAY GAMES</h2>
         
        <ol id="l10">
          <li data-list-text="4.1.">
            <p
              style="
                padding-top: 8pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 113%;
                text-align: left;
              ">
              Participants with Juked Cash can use their Juked Cash to play games within Juked for a chance to win additional Juked Cash. Juked Cash won through game play can be redeemed for real prizes.
            </p>
          </li>
          <li data-list-text="4.2.">
            <p
              style="
                padding-top: 6pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              Within Juked there are different games. The amount of Juked Cash required to play each game will be detailed next to the game. A Participant may be able to increase the amount of Juked Cash they play the game with so they can increase their potential winning eligibility.
            </p>
          </li>
          <li data-list-text="4.3.">
            <p
              style="
                padding-top: 5pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              Only games played with Juked Cash provide the opportunity to redeem winnings for real prizes.
            </p>
          </li>
          <li data-list-text="4.4.">
            <p
              style="
                padding-top: 6pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 113%;
                text-align: left;
              ">
              Each Juked Cash that has been won through game play (rather than collected using one of the methods described in clause 3 of these Sweeps Rules) can be redeemed for a prize with value being equivalent to US$1.
            </p>
          </li>
          <li data-list-text="4.5.">
            <p
              style="
                padding-top: 6pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              Sponsor's/Promoter's decisions as to the administration and operation of the Sweepstakes, the game and the amount of winnings are final and binding.
            </p>
          </li>
          <li data-list-text="4.6.">
            <p
              style="
                padding-top: 5pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              Unless Sponsor/Promoter requires otherwise, any Juked Cash allocated to a Participant are required to be played once and in a game where the odds of winning have an implied probability of 75% or less, which equates to displayed odds in the game of -300 or longer [e.g. -200, +100, +200…etc], before the Juked Cash are eligible to be redeemed for prizes. Sponsor/Promoter may, in its sole discretion, require that any Juked Cash allocated to a Participant must be played a greater number of times (not exceeding 20) in any combination of Juked Cash games before they are eligible to be redeemed for prizes.
            </p>
            
          </li>
          <li data-list-text="4.7.">
            <p
              style="
                padding-top: 5pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              Sponsor/Promoter reserves the right to change the prize win rates and odds of any of the Sweepstakes at any time. A Participant can obtain the actual and any amended details of any game at the point of entering an amount to play in the Juked Cash Game. It is a Participant's responsibility to check the prize available on each occasion before they participate.
            </p>
            
          </li>
        </ol>
      </li>
      <li data-list-text="5.">
        <h2 style="padding-left: 45pt; text-indent: -35pt; text-align: left">
          VERIFICATION AND CONFIRMATION OF POTENTIAL WINNERS
        </h2>
        <ol id="l11">
          <li data-list-text="5.1.">
            <h3
              style="
                padding-top: 8pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              <b>POTENTIAL SWEEPSTAKES WINNERS ARE SUBJECT TO VERIFICATION BY SPONSOR/PROMOTER (IN ANY MANNER IT MAY CHOOSE) AND THE DECISIONS OF SPONSOR/PROMOTER ARE FINAL AND BINDING IN ALL MATTERS RELATED TO THE SWEEPSTAKES. A PARTICIPANT IS NOT A WINNER OF ANY PRIZE, EVEN IF THE ONLINE SCREEN INDICATES THEY ARE, UNLESS AND UNTIL THE PARTICIPANT'S ELIGIBILITY AND THE POTENTIAL WINNING PLAY HAS BEEN VERIFIED AND THE PARTICIPANT HAS FULLY COMPLIED WITH THESE SWEEPS RULES AND BEEN NOTIFIED THAT VERIFICATION IS COMPLETE. SPONSOR/PROMOTER WILL NOT ACCEPT SCREEN SHOTS OR OTHER PURPORTED EVIDENCE OF WINNING IN LIEU OF ITS VALIDATION PROCESS.</b>
            </h3>
          </li>
          <li data-list-text="5.2.">
            <p
              style="
                padding-top: 5pt;
                padding-left: 45pt;
                text-indent: -35pt;
                text-align: left;
              ">
              Potential prize winners must comply with these Sweeps Rules and winning is contingent upon fulfilling all requirements.
            </p>
            
          </li>
          <li data-list-text="5.3.">
            <p
              style="
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: justify;
              ">
              A potential prize winner may be required to sign and return to Sponsor/Promoter, an affidavit/declaration of eligibility, and liability/publicity release (except where prohibited) in order to claim his/her prize (if applicable). In addition, in the case of a Canadian eligible winner, he/she will be required to correctly answer a mathematical skill-testing question without mechanical or other aid to be administered at a pre-arranged mutually convenient time.
            </p>
          </li>
          <li data-list-text="5.4.">
            <p
              style="
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              If a potential winner cannot be contacted, fails to properly execute and return the affidavit/declaration of eligibility and liability/publicity release within the required time period (if applicable), fails to comply with these Sweeps Rules (including, but not limited to, the requirement to correctly answer the mathematical skill-testing question if a resident of Canada), or if the prize or prize notification is returned as undeliverable, that potential winner forfeits the prize.
            </p>
          </li>
          <li data-list-text="5.5.">
            <p
              style="
                padding-top: 5pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 113%;
                text-align: left;
              ">
              In the event that a potential winner of a Sweepstakes prize is disqualified for any reason, Sponsor/Promoter may, in its sole discretion, reseed the prize back into the Sweepstakes.
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="6.">
        <h2
          style="
            padding-top: 6pt;
            padding-left: 45pt;
            text-indent: -35pt;
            text-align: left;
          ">
          PRIZES
        </h2>
        <ol id="l12">
          <li data-list-text="6.1.">
            <p
              style="
                padding-top: 8pt;
                padding-left: 45pt;
                text-indent: -35pt;
                text-align: left;
              ">
              A Participant's Juked Cash balance is shown in the Participant's Customer Account.
            </p>
            
          </li>
          <li data-list-text="6.2.">
            <p
              style="
                padding-top: 8pt;
                padding-left: 45pt;
                text-indent: -35pt;
                text-align: left;
              ">
              Prizes are not awarded until redeemed. A Participant has no rights to Juked Cash that have not been redeemed.
            </p>
            
          </li>
          <li data-list-text="6.3.">
            <p
              style="
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              Where a Participant has chosen to redeem prizes for cash, the payment will be made in USD to the financial account or online wallet from which you purchased Juked Gold, or if this is not technically possible, then to an alternative bank account you nominate. Sponsor/Promoter reserves the right to require the use of the same payment method for redemption of prizes as was used to purchase Juked Gold, or a specific payment method at Sponsor/Promoter's discretion.
            </p>
          </li>
          <li data-list-text="6.4.">
            <p
              style="
                padding-top: 6pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 113%;
                text-align: justify;
              ">
              Where a Participant has chosen to redeem prizes for gift cards, the gift cards will be allocated to the email address that the Participant has registered against their Customer Account.
            </p>
          </li>
          <li data-list-text="6.5.">
            <p
              style="
                padding-top: 2pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              Sponsor/Promoter will only process a prize redemption if the redeemed value of the prize is US$100 or more (i.e. a minimum of 100 eligible Juked Cash). Consequently, a Participant's Customer Account must have a balance of at least 100 Juked Cash before requesting a prize redemption.
            </p>
          </li>
          <li data-list-text="6.6.">
            <p
              style="
                padding-top: 5pt;
                padding-left: 45pt;
                text-indent: -35pt;
                text-align: left;
              ">
              TO BE ELIGIBLE FOR A JUKED CASH PRIZE OR A JUKED CASH PRIZE REDEMPTION:
            </p>
            
            <ol id="l13">
              <li data-list-text="(a)">
                <p style="padding-left: 82pt; text-indent: -36pt; text-align: left">
                  A PARTICIPANT MUST BE AN ELIGIBLE PARTICIPANT; AND
                </p>
                
              </li>
              <li data-list-text="(b)">
                <p style="padding-left: 82pt; text-indent: -36pt; text-align: left">
                  THE PARTICIPANT'S DETAILS MUST MATCH THOSE OF THE PARTICIPANT'S CUSTOMER ACCOUNT.
                </p>
              </li>
            </ol>
            
          </li>
          <li data-list-text="6.7.">
            <p
              style="
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              In New York and Florida, the maximum redemption value of a Juked Cash prize won on any one spin or play, via a Participant's participation in the Sweepstakes is US$5,000. Any prize valued in excess of US$5,000 will be reduced to a maximum value of US$5,000.
            </p>
          </li>
          <li data-list-text="6.8.">
            <p
              style="
                padding-top: 6pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 113%;
                text-align: left;
              ">
              Sponsor/Promoter reserves the right, in its sole discretion, to limit a Participant's redemption of Juked Cash to US$10,000 per day or any other amount over any time that Sponsor/Promoter considers necessary to satisfy its regulatory obligations or the requirements of its partners and suppliers. No more than the stated number of prizes will be awarded.
            </p>
          </li>
          <li data-list-text="6.9.">
            <p
              style="
                padding-top: 6pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 113%;
                text-align: left;
              ">
              Sponsor/Promoter is not responsible for any taxes or fees associated with a prize redemption. Participants are responsible for all applicable taxes and fees associated with prize receipt and/or redemption.
            </p>
          </li>
          <li data-list-text="6.10.">
            <p
              style="
                padding-top: 6pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 113%;
                text-align: left;
              ">
              Sponsor/Promoter is not responsible for any foreign exchange transaction fees, charges or related costs that may be incurred as a result of, or in relation to, a prize redemption, including but not limited to any losses or additional costs arising from foreign exchange fluctuations.
            </p>
          </li>
          <li data-list-text="6.11.">
            <p
              style="
                padding-top: 6pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: justify;
              ">
              Juked Cash are not transferable and no substitution will be made except as provided herein at the Sponsor's/Promoter's sole discretion. Sponsor/Promoter reserves the right to substitute the listed prize of equal or greater value for any reason owing to circumstances outside Sponsor's/Promoter's reasonable control.
            </p>
          </li>
          <li data-list-text="6.12.">
            <p
              style="
                padding-top: 5pt;
                padding-left: 45pt;
                text-indent: -35pt;
                text-align: left;
              ">
              Prize winners will be notified by private message or email.
            </p>
            
          </li>
        </ol>
      </li>
      <li data-list-text="7.">
        <h2
          style="
            padding-top: 5pt;
            padding-left: 45pt;
            text-indent: -35pt;
            text-align: left;
          ">
          ENTRY CONDITIONS AND RELEASE
        </h2>
        <p
          style="
            padding-top: 8pt;
            padding-left: 45pt;
            text-indent: 0pt;
            text-align: left;
          ">
          By Participating, each Participant agrees to:
        </p>
        <p style="text-indent: 0pt; text-align: left">
          <br />
        </p>
        <ol id="l14">
          <li data-list-text="(a)">
            <p style="padding-left: 82pt; text-indent: -36pt; text-align: left">
              comply with and be bound by:
            </p>
            
            <ol id="l15">
              <li data-list-text="i.">
                <p style="padding-left: 117pt; text-indent: -21pt; text-align: left">
                  the T&Cs;
                </p>
                
              </li>
              <li data-list-text="ii.">
                <p style="padding-left: 117pt; text-indent: -24pt; text-align: left">
                  these Sweeps Rules; and
                </p>
                
              </li>
              <li data-list-text="iii.">
                <p style="padding-left: 117pt; text-indent: -26pt; text-align: left">
                  the decisions of the Sponsor/Promoter which are binding and final;
                </p>
                
              </li>
            </ol>
          </li>
          <li data-list-text="(b)">
            <p style="padding-left: 82pt; text-indent: -36pt; text-align: left">
              release and hold harmless the Sponsor/Promoter and its parent, subsidiary, and affiliated companies, the prize suppliers and any other organizations responsible for sponsoring, fulfilling, administering, advertising or promoting the Sweepstakes, and all of their respective past and present officers, directors, employees, agents and representatives (collectively, the <b>Released Parties</b>) from and against any and all claims, expenses, and liability, including but not limited to negligence and damages of any kind to persons and property, including but not limited to invasion of privacy (under appropriation, intrusion, public disclosure of private facts, false light in the public eye or other legal theory), defamation, slander, libel, violation of right of publicity, infringement of trademark, copyright or other intellectual property rights, property damage, or death or personal injury arising out of or relating to a Participant's entry, creation of an entry or submission of an entry, participation in the Sweepstakes, acceptance or use or misuse of prizes (including any travel or activity related thereto) and/or the broadcast, exploitation or use of entry; and
            </p>
            
          </li>
          <li data-list-text="(c)">
            <p style="padding-left: 82pt; text-indent: -36pt; text-align: left">
              indemnify, defend and hold harmless the Sponsor/Promoter from and against any and all claims, expenses, and liabilities (including reasonable attorneys/legal fees) arising out of or relating to a Participant's participation in the Sweepstakes and/or Participant's acceptance, use or misuse of prizes.
            </p>
          </li>
        </ol>
        <p style="text-indent: 0pt; text-align: left">
          <br />
        </p>
      </li>
      <li data-list-text="8.">
        <h2 style="padding-left: 45pt; text-indent: -35pt; text-align: left">
          PUBLICITY
        </h2>
        <p
          style="
            padding-top: 8pt;
            padding-left: 45pt;
            text-indent: 0pt;
            line-height: 114%;
            text-align: left;
          ">
          Except where prohibited, participation in the Sweepstakes constitutes each Participant's consent to Sponsor's/Promoter's and its agents' use of Participant's name, likeness, photograph, voice, opinions and/or hometown and state/province/territory for promotional purposes in any media, worldwide, without further payment, notice or consideration.
        </p>
      </li>
      <li data-list-text="9.">
        <h2
          style="
            padding-top: 6pt;
            padding-left: 45pt;
            text-indent: -35pt;
            text-align: left;
          ">
          GENERAL CONDITIONS
        </h2>
        <ol id="l16">
          <li data-list-text="9.1.">
            <p
              style="
                padding-top: 8pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: justify;
              ">
              Sponsor/Promoter reserves the right to cancel, suspend and/or modify the Sweepstakes or these Sweeps Rules, or any part of the Sweepstakes or these Sweeps Rules, with immediate effect owing to circumstances outside its reasonable control and only where circumstances make it unavoidable if any fraud, technical failures or any other factor beyond Sponsor's/Promoter's reasonable control impairs the integrity or proper functioning of the Sweepstakes, as determined by Sponsor/Promoter in its sole discretion.
            </p>
          </li>
          <li data-list-text="9.2.">
            <p
              style="
                padding-top: 6pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              Sponsor/Promoter reserves the right in its sole discretion to disqualify any individual it finds to be tampering with the entry process or the operation of the Sweepstakes or to be acting in violation of these Sweeps Rules or any other promotion or in an unsportsmanlike or disruptive manner.
            </p>
          </li>
          <li data-list-text="9.3.">
            <p
              style="
                padding-top: 5pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              Any attempt by any person to deliberately undermine the legitimate operation of the Sweepstakes may be a violation of criminal and civil law and, should such an attempt be made, Sponsor/Promoter reserves the right to seek damages from any such person to the fullest extent permitted by law.
              Sponsor's/Promoter's failure to enforce any term of these Sweeps Rules shall not constitute a waiver of that provision.
            </p>
          </li>
          <li data-list-text="9.4.">
            <p
              style="
                padding-top: 5pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              In all other cases, the Sponsor/Promoter reserves the right to cancel, suspend and/or modify the Sweepstakes. Any notice regarding cancellation, suspension and/or modification will be posted on Juked at <a href="https://juked.co/" target="_blank">https://juked.co/</a>
            </p>
          </li>
          <li data-list-text="9.5.">
            <p
              style="
                padding-top: 5pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: justify;
              ">
              Whenever we amend these Sweeps Rules in a way that would limit your current rights or which may be to your detriment, we will notify you upon your next visit to the Platform and you will be required to re- confirm your acceptance prior to participating in any Promotional Play. If you do not agree to the amended Sweeps Rules, you may not participate in any Promotional Play.
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="10.">
        <h2
          style="
            padding-top: 6pt;
            padding-left: 45pt;
            text-indent: -35pt;
            text-align: left;
          ">
          LIMITATIONS OF LIABILITY
        </h2>
        <ol id="l17">
          <li data-list-text="10.1.">
            <p
              style="
                padding-top: 8pt;
                padding-left: 45pt;
                text-indent: -35pt;
                text-align: left;
              ">
              Insofar as permitted by law in the respective territories, the Released Parties (as defined above) are not responsible for:
            </p>
            
            <ol id="l18">
              <li data-list-text="(a)">
                <p style="padding-left: 82pt; text-indent: -36pt; text-align: left">
                  any incorrect or inaccurate information, whether caused by a Participant, printing error or by any of the equipment or programming associated with or utilized in the Sweepstakes;
                </p>
                
              </li>
              <li data-list-text="(b)">
                <p style="padding-left: 82pt; text-indent: -36pt; text-align: left">
                  technical failures of any kind, including, but not limited to malfunctions, interruptions, or disconnections in phone lines or network hardware or software;
                </p>
                
              </li>
              <li data-list-text="(c)">
                <p style="padding-left: 82pt; text-indent: -36pt; text-align: left">
                  unauthorized human intervention in any part of the entry process or the Sweepstakes;
                </p>
                
              </li>
              <li data-list-text="(d)">
                <p style="padding-left: 82pt; text-indent: -36pt; text-align: left">
                  technical or human error which may occur in the administration or operation of the Sweepstakes; or
                </p>
                
              </li>
              <li data-list-text="(e)">
                <p
                  style="
                    padding-left: 82pt;
                    text-indent: -36pt;
                    
                    text-align: left;
                  ">
                  any injury or damage to persons or property which may be caused, directly or indirectly, in whole or in part, from Participant's participation in the Sweepstakes or receipt or use or misuse of any prize.
                </p>
              </li>
            </ol>
            
          </li>
          <li data-list-text="10.2.">
            <p
              style="
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              If for any reason a Participant's Juked Cash or winning play is confirmed to have been erroneously deleted, lost, or otherwise destroyed or corrupted, Participant's sole remedy is replacement by Sponsor/Promotor of an equivalent amount of Juked Cash.
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="11.">
        <h2
          style="
            padding-top: 6pt;
            padding-left: 45pt;
            text-indent: -35pt;
            text-align: left;
          ">
          DISPUTES
        </h2>
        <h3
          style="
            padding-top: 8pt;
            padding-left: 45pt;
            text-indent: 0pt;
            line-height: 114%;
            text-align: left;
          ">
          <b>Clause 24 of the <a target="_blank" href="https://juked.co/docs/Terms-And-Conditions.pdf">T&Cs</a> (Dispute Resolution and Agreement to Arbitrate on an Individual Basis) is incorporated herein by reference with the same force and effect as though fully set forth herein. By agreeing to these Sweeps Rules, you agree to be bound by the dispute resolution and arbitration provisions contained in clause 24 of the T&Cs.</b>
        </h3>
      </li>
      <li data-list-text="12.">
        <h2
          style="
            padding-top: 6pt;
            padding-left: 45pt;
            text-indent: -35pt;
            text-align: left;
          ">
          OTHER
        </h2>
        <ol id="l19">
          <li data-list-text="12.1.">
            <p
              style="
                padding-top: 8pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              Under no circumstances will any Participant be permitted to obtain awards for, and Participant hereby waives all rights to claim, punitive, incidental or consequential damages, or any other damages, including attorneys’ fees, other than Participant's actual out-of-pocket expenses (i.e., costs associated with participating in this Sweepstakes), and Participant further waives all rights to have damages multiplied or increased. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATIONS OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE MAY NOT APPLY TO YOU.
            </p>
          </li>
          <li data-list-text="12.2.">
            <p
              style="
                padding-top: 5pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              All issues and questions concerning the construction, validity, interpretation and enforceability of these Sweeps Rules, or the rights and obligations of the Participant and Sponsor/Promoter in connection with the Sweepstakes, are governed by, and construed in accordance with, the laws of the State of Wyoming, without giving effect to any choice of law or conflict of law rules (whether of the State of Wyoming or any other jurisdiction), which would cause the application of the laws of any jurisdiction other than the State of Wyoming.
            </p>
          </li>
          <li data-list-text="12.3.">
            <p
              style="
                padding-top: 6pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 113%;
                text-align: left;
              ">
              To ensure fairness and the integrity of the promotion to all Participants, the Sponsor/Promoter will respond to questions to Customer Support via Live Support or via <a href="mailto:support@juked.co">support@juked.co</a> and may post updates/communications on its Facebook page.
            </p>
          </li>
          <li data-list-text="12.4.">
            <p
              style="
                padding-top: 6pt;
                padding-left: 45pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              Any Participant posting or seen to be posting comments on Sponsor's/Promoter's Facebook page or elsewhere during the promotion that are considered bullying, spiteful or upsetting to other Participants, players and fans of Juked or directly aimed at the Sponsor/Promoter, will have their comments removed and will be disqualified from the Sweepstakes. The Sponsor/Promoter reserves the right to alert Facebook to any such behavior and Participant may have his/her Facebook account frozen pending investigation.
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="13.">
        <h2
          style="
            padding-top: 6pt;
            padding-left: 45pt;
            text-indent: -35pt;
            text-align: left;
          ">
          PARTICIPANT'S PERSONAL INFORMATION
        </h2>
        <ol id="l20">
          <li data-list-text="13.1.">
            <p
              style="
                padding-top: 8pt;
                padding-left: 45pt;
                text-indent: -35pt;
                text-align: left;
              ">
              Information collected from Participants is subject to the Sponsor's/Promoter's Privacy Policy which is available at <a href="https://www.juked.co" class="s3" target="_blank">https://juked.co</a>
            </p>
          </li>
        </ol>
      </li>
    </ol>
  </div>
</template>

<script>
import axios from '@/lib/axiosConfig';
import { doRecaptcha } from '@/lib/recaptcha';
import { mapGetters } from 'vuex';
import PuntModal from '@/components/Shared/PuntModal.vue';
import VerifyLink from "@/components/Veriff/VerifyLink.vue";
import SsnVerification from  "@/components/Bank/SsnVerification.vue";

export default {
  data() {
    return {
      showModal: false,
      postalCode: '',
      veriffpop:false,
      ssnveriff:true,

    };
  },
  components: { PuntModal,VerifyLink,SsnVerification},
  computed: {
    ...mapGetters(['me', 'isLoggedIn']),
  },
  methods: {
    async onssnSuccess()
    {
      this.ssnveriff=true;
      this.getPostalCode();

    },
    async getPostalCode() {
      if (this.isLoggedIn) {
        try {
          doRecaptcha('user_postal_request_code')
            .then(async (token) => {
              const response = await axios.post('/user-postal-request-code', { token });
              window.swal.fire({
                title: 'Postal Request Code',
                input: 'text',
                inputLabel: 'Your Postal Request Code',
                inputValue: response.data.request_code,
                showCancelButton: false,
                customClass: {
                  input: 'mySwalInput',
                },
                inputAttributes: {
                  readonly: true,
                  onPaste: 'return false',
                  onCopy: 'return false',
                },
              });
            })
            .catch((e) => {
              console.log(e);
              if((typeof e.data.error_type != 'undefined')  && e.data.error_type =='veriff')
              {
                this.veriffpop=true;
                this.$toast.error("Please verify your identity to generate postal code");
              }
              else if((typeof e.data.error_type != 'undefined') && e.data.error_type =='ssn')
              {
                this.ssnveriff=false;
                this.$toast.error("Please verify your SSN to generate postal code");
              }
              else if((typeof e.data.error != 'undefined') )
              {
                window.swal('Failed to generate code', ((typeof e.data.error != 'undefined')?e.data.error:''), 'error')
              }
              else
                window.swal('Failed to generate code', 'Please try again in a few mins', 'error')

            });
        } catch(error) {
          console.log("Issue while generating postal code");
        }
      } else {
        window.swal(
          'Cannot Genterate Postal Request Code',
          'You need to login for that.',
          'error',
        );
      }
    },
  },
  closeModal() {
    this.showModal = false;
  },
};
</script>

<style scoped>
.parent * {
  margin: 0;
  padding: 0;
  text-indent: 0;
}
.parent  h1 {
  color: white;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 18pt;
}
.parent h3 {
  color: white;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 12pt;
}
.parent h2 {
  color: white;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 12pt;
}
.parent .p,
.parent p {
  color: white;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
  margin: 15px;
}
.parent .a,
.parent a {
  color: white;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
.parent  .s1 {
  color: white;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
.parent .s3 {
  color: #1154cc;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: underline;
  font-size: 12pt;
}
.parent li {
  display: block;
}
.parent #l1 {
  padding-left: 0pt;
  counter-reset: c1 1;
}
.parent #l1 > li > *:first-child:before {
  counter-increment: c1;
  content: counter(c1, decimal) '. ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
.parent #l1 > li:first-child > *:first-child:before {
  counter-increment: c1 0;
}
.parent #l2 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
.parent #l2 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) '.' counter(c2, decimal) '. ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
.parent #l2 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
.parent #l3 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
.parent #l3 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) ') ';
  color: white;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 12pt;
}
.parent #l3 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
.parent #l4 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
.parent #l4 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) '.' counter(c2, decimal) '. ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
.parent #l4 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
.parent #l5 {
  padding-left: 0pt;
  counter-reset: d1 1;
}
.parent #l5 > li > *:first-child:before {
  counter-increment: d1;
  content: '(' counter(d1, lower-latin) ') ';
  color: white;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
.parent #l5 > li:first-child > *:first-child:before {
  counter-increment: d1 0;
}
.parent #l6 {
  padding-left: 0pt;
  counter-reset: d2 1;
}
.parent #l6 > li > *:first-child:before {
  counter-increment: d2;
  content: counter(d2, lower-roman) '. ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
.parent #l6 > li:first-child > *:first-child:before {
  counter-increment: d2 0;
}
.parent #l7 {
  padding-left: 0pt;
  counter-reset: d3 1;
}
.parent #l7 > li > *:first-child:before {
  counter-increment: d3;
  content: counter(d3, upper-latin) '. ';
  color: white;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
.parent #l7 > li:first-child > *:first-child:before {
  counter-increment: d3 0;
}
.parent #l8 {
  padding-left: 0pt;
}
.parent #l8 > li > *:first-child:before {
  content: '● ';
  color: white;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
.parent #l9 {
  padding-left: 0pt;
  counter-reset: e1 1;
}
.parent #l9 > li > *:first-child:before {
  counter-increment: e1;
  content: '(' counter(e1, lower-latin) ') ';
  color: white;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
.parent #l9 > li:first-child > *:first-child:before {
  counter-increment: e1 0;
}
.parent #l10 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
.parent #l10 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) '.' counter(c2, decimal) '. ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
.parent #l10 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
.parent #l11 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
.parent #l11 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) '.' counter(c2, decimal) '. ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
.parent #l11 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
.parent #l12 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
.parent #l12 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) '.' counter(c2, decimal) '. ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
.parent #l12 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
.parent #l13 {
  padding-left: 0pt;
  counter-reset: f1 1;
}
.parent #l13 > li > *:first-child:before {
  counter-increment: f1;
  content: '(' counter(f1, lower-latin) ') ';
  color: white;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
.parent #l13 > li:first-child > *:first-child:before {
  counter-increment: f1 0;
}
.parent #l14 {
  padding-left: 0pt;
  counter-reset: g1 1;
}
.parent #l14 > li > *:first-child:before {
  counter-increment: g1;
  content: '(' counter(g1, lower-latin) ') ';
  color: white;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
.parent #l14 > li:first-child > *:first-child:before {
  counter-increment: g1 0;
}
.parent #l15 {
  padding-left: 0pt;
  counter-reset: g2 1;
}
.parent #l15 > li > *:first-child:before {
  counter-increment: g2;
  content: counter(g2, lower-roman) '. ';
  color: white;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
.parent #l15 > li:first-child > *:first-child:before {
  counter-increment: g2 0;
}
.parent #l16 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
.parent #l16 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) '.' counter(c2, decimal) '. ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
.parent #l16 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
.parent #l17 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
.parent #l17 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) '.' counter(c2, decimal) '. ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
.parent #l17 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
.parent #l18 {
  padding-left: 0pt;
  counter-reset: h1 1;
}
.parent #l18 > li > *:first-child:before {
  counter-increment: h1;
  content: '(' counter(h1, lower-latin) ') ';
  color: white;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
.parent #l18 > li:first-child > *:first-child:before {
  counter-increment: h1 0;
}
.parent #l19 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
.parent #l19 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) '.' counter(c2, decimal) '. ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
.parent #l19 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
.parent #l20 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
.parent #l20 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) '.' counter(c2, decimal) '. ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
.parent #l20 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
.privacy-policy-div {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
}
.parent .req-postal-code {
  font-style: italic;
  cursor: pointer;
  text-decoration: underline;
}
.swal2-input {
  background: black !important;
}
</style>

<style>
.mySwalInput {
  background: black !important;
}
</style>
