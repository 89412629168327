<template>
  <div class="flex flex-col items-center">
    <div class="flex flex-col md:flex-row p-4 md:p-8 gap-4 w-full max-w-[1200px]">
      <!-- <div class="md:w-[200px] flex flex-col">
        <div class="space-y-2 py-2 bg-secondary h-auto rounded-lg">
          <span class="font-medium w-full mx-2"> Responsible Gaming </span>
        </div>
      </div> -->
      <div class="bg-secondary flex-1 rounded-lg">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 sm:px-6">
            <!-- Break in Play -->
            <div class="p-6 mb-2 rounded-lg shadow border-2 border-slate-300">
              <a href="#">
                <h5
                  class="mb-2 text-2xl font-bold tracking-tight text-white-900 dark:text-white">
                  Break In Play
                </h5>
              </a>
              <p class="mb-3 font-normal text-white-700 dark:text-white">
                If you want to take a short break from gaming with us, you can do so by
                taking a Time-Out for a period of 24 hours, 48 hours, 7 days, 30 days, 2
                months, 3 months. Once you begin your Time-Out, you will not be able to
                use your account for gaming, although you will still be able to log in to
                redeem any remaining balance. It will not be possible to reactivate your
                account until your chosen period has ended.
              </p>
              <p class="mb-3 font-normal text-red-700 dark:text-red-400" v-if="isOnBreak">
                {{ onBreakMessage }}
              </p>
              <a
                v-else
                href="#"
                @click="requestBreakInPlay"
                class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-gray-700 rounded-lg hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                Request Break in Play
              </a>
            </div>

            <!-- Self Exclusion -->
            <div class="mt-3 p-6 rounded-lg shadow border-2 border-slate-300">
              <a href="#">
                <h5
                  class="mb-2 text-2xl font-bold tracking-tight text-white-900 dark:text-white">
                  Self Exclusion
                </h5>
              </a>
              <p class="mb-3 font-normal text-white-700 dark:text-white">
                If you feel you are at risk of developing a social gaming problem or
                believe you currently have a social gaming problem, please consider using
                Self-Exclusion which prevents you playing with Juked for a specified
                period of 6 months, 1 year, 2 years or 5 years. If you want to stop
                playing for other reasons, please consider a Time-Out or using Account
                Closure.
              </p>
              <a
                href="#"
                @click="requestExclusionInPlay"
                class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-gray-700 rounded-lg hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                Request Self Exclusion
              </a>
            </div>

            <!-- Purchase Limit -->
            <div class="mt-3 p-6 rounded-lg shadow border-2 border-slate-300">
              <a href="#">
                <div class="grid gap-4 mb-6 grid-cols-2">
                  <div class="">
                    <h5
                      class="mb-2 text-2xl font-bold tracking-tight text-white-900 dark:text-white">
                      Purchase Limits
                    </h5>
                  </div>
                </div>
              </a>
              <template v-if="purchaseLimitUpdated">
                <div class="grid gap-4 mb-6 md:grid-cols-2">
                  <div>
                    <h2
                      for="countries"
                      class="block mb-2 text-md font-medium text-gray-900 dark:text-white">
                      Duration: {{ purchaseLimitRangeUpdated ?? 'N/A' }}
                    </h2>
                  </div>
                  <div>
                    <h2 for="welcome-bonus-code" class="mb-2 block text-md font-medium">
                      Purchase Limit: $ {{ purchaseLimitUpdated ?? 'N/A' }}
                    </h2>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="text-center">
                  <h2 for="welcome-bonus-code" class="mb-2 block text-md font-medium">
                    No Current Purchase Limits Set!
                  </h2>
                </div>
              </template>
              <div class="text-center">
                <button
                  v-on:click="openPurchaseLimitModal"
                  type="button"
                  class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-gray-700 rounded-lg hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                  Set Purchase Limit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Break In Play Modal -->
    <punt-modal size="max-w-xl" :onClose="closeModal" v-if="confirmBreakInPlay">
      <template #body>
        <div class="modal bonus-modal is-active flex flex-col">
          <!-- Modal header -->
          <div
            class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 class="text-lg font-semibold text-white dark:text-white">
              Break in Play
            </h3>
          </div>

          <div class="col-span-2 m-3" v-if="!doubleConfirmBreakInPlay">
            <label
              for="name"
              class="block mb-3 text-left text-sm font-medium text-white dark:text-white"
              >Duration</label
            >
            <select
              id="category"
              class="bg-gray-50 border border-gray-300 text-white-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              v-model="breakDuration"
              required>
              <option value="1">1 Day</option>
              <option value="2">2 Day</option>
              <option value="7">1 Week</option>
              <option value="30">1 Month</option>
              <option value="60">2 Month</option>
              <option value="90">3 Month</option>
            </select>
          </div>

          <div class="col-span-2 m-3">
            <p v-if="doubleConfirmBreakInPlay" class="text-white text-left text-sm mb-3">
              Are you sure you want to initiate a break in play from juked ? Any
              decision made here is final and irreversible. By confirming your break in
              play, you will not be able to play on juked for the time you set.
            </p>

            <button
              v-if="doubleConfirmBreakInPlay"
              v-on:click="takeBreakInPlay"
              :disabled="isTakingBreakInPlay"
              class="mr-12 inline-block gap-6 rounded-xl bg-accent font-semibold text-white/90 transition delay-0 duration-150 ease-in-out hover:scale-105 hover:opacity-70 md:h-12 md:w-auto px-5 py-3 bg-prim-highlight shadow-btn shadow-accent disabled:opacity-5">
              Confirm
            </button>
            <button
              v-else
              v-on:click="doubleConfirm"
              :disabled="!breakDuration"
              class="mr-8 inline-block gap-6 rounded-xl bg-accent font-semibold text-white/90 transition delay-0 duration-150 ease-in-out hover:scale-105 hover:opacity-70 md:h-12 md:w-auto px-5 py-3 bg-prim-highlight shadow-btn shadow-accent disabled:opacity-20">
              Next
            </button>
            <button
              :disabled="isTakingBreakInPlay"
              v-on:click="closeModal"
              class="gap-6 inline-block rounded-xl bg-secondary font-semibold text-white/90 transition delay-0 duration-150 ease-in-out hover:scale-105 hover:opacity-70 md:h-12 md:w-auto px-5 py-3 bg-prim-highlight shadow-btn shadow-secondary disabled:opacity-5">
              Cancel
            </button>
          </div>

          <div class="col-span-2 m-3" v-if="!doubleConfirmBreakInPlay">
            <p class="text-white text-left text-sm">
              Disclaimer: Your account will immediately be put into 'redeem only' mode.
            </p>
          </div>
        </div>
      </template>
    </punt-modal>

    <!-- Self Exclusion Modal -->
    <punt-modal
      size="max-w-xl"
      :onClose="closeExclusionModal"
      v-if="confirmExclusionInPlay">
      <template #body>
        <div class="modal bonus-modal is-active flex flex-col">
          <!-- Modal header -->
          <div
            class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 class="text-lg font-semibold text-white dark:text-white">
              Self Exclusion
            </h3>
          </div>

          <div class="col-span-2 m-3" v-if="!doubleConfirmExclusionInPlay">
            <label
              for="name"
              class="block mb-3 text-left text-sm font-medium text-white dark:text-white"
              >Duration</label
            >
            <select
              id="category"
              class="bg-gray-50 border border-gray-300 text-white-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              v-model="exclusionDuration"
              required>
              <option value="6">6 Months</option>
              <option value="12">1 Year</option>
              <option value="24">2 Year</option>
              <option value="36">3 Year</option>
              <option value="60">5 Year</option>
              <option value="1200">Permanent</option>
            </select>
          </div>

          <div class="col-span-2 m-3">
            <p
              v-if="doubleConfirmExclusionInPlay"
              class="text-white text-left text-sm mb-3">
              Are you sure you want to self exclude yourself from juked ? Any decision
              made here is final and irreversible. By confirming your exclusion, you will
              not be able to play on juked.
            </p>

            <button
              v-if="doubleConfirmExclusionInPlay"
              v-on:click="takeExclusionInPlay"
              :disabled="isTakingExclusionInPlay"
              class="mr-12 inline-block gap-6 rounded-xl bg-accent font-semibold text-white/90 transition delay-0 duration-150 ease-in-out hover:scale-105 hover:opacity-70 md:h-12 md:w-auto px-5 py-3 bg-prim-highlight shadow-btn shadow-accent disabled:opacity-5">
              Confirm
            </button>
            <button
              v-else
              v-on:click="doubleConfirmExclusion"
              :disabled="!exclusionDuration"
              class="mr-8 inline-block gap-6 rounded-xl bg-accent font-semibold text-white/90 transition delay-0 duration-150 ease-in-out hover:scale-105 hover:opacity-70 md:h-12 md:w-auto px-5 py-3 bg-prim-highlight shadow-btn shadow-accent disabled:opacity-20">
              Next
            </button>
            <button
              :disabled="isTakingExclusionInPlay"
              v-on:click="closeExclusionModal"
              class="gap-6 inline-block rounded-xl bg-secondary font-semibold text-white/90 transition delay-0 duration-150 ease-in-out hover:scale-105 hover:opacity-70 md:h-12 md:w-auto px-5 py-3 bg-prim-highlight shadow-btn shadow-secondary disabled:opacity-5">
              Cancel
            </button>
          </div>

          <div class="col-span-2 m-3" v-if="!doubleConfirmExclusionInPlay">
            <p class="text-white text-center text-sm">
              Disclaimer: Any decision made here is final and not reversible. Please
              redeem any remaining balance from your account before submitting your self
              exclusion request.
            </p>
          </div>
        </div>
      </template>
    </punt-modal>

    <!-- Purchase Limit Modal  -->
    <punt-modal
      size="max-w-xl"
      :onClose="closePurchaseLimitModal"
      v-if="editPurchaseLimit">
      <template #body>
        <div class="modal bonus-modal is-active flex flex-col">
          <!-- Modal header -->
          <div
            class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 class="text-lg font-semibold text-white dark:text-white">
              Purchase Limit
            </h3>
          </div>

          <div class="col-span-2 m-3" v-if="isPurchaseLimitEditable">
            <template v-if="confirmPurchaseLimit">
              <div class="text-center">
                <h2 class="text-white text-center mb-3">
                  Are you sure you want to set a purchase limit?
                </h2>
              </div>
            </template>
            <template v-else>
              <label
                for="name"
                class="block mb-3 text-left text-sm font-medium text-white dark:text-white"
                >Duration <span class="text-red-800">*</span></label
              >
              <select
                id="category"
                class="bg-gray-50 border border-gray-300 text-white-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                v-model="purchaseLimitRange"
                required>
                <option value="null" disabled selected>Select your option</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
              </select>
            </template>
          </div>
          <div class="col-span-2 m-3" v-if="isPurchaseLimitEditable">
            <template v-if="confirmPurchaseLimit">
              <div class="text-center">
                <p
                  class="text-white text-sm mb-3">
                  You are about to set a <b>{{ purchaseLimitRange }}</b> purchase limit of <b>$ {{ purchaseLimit }}.</b>
                </p>
                <p
                  class="text-white text-sm mb-3">
                  Your limit will be effective <b>Immediately.</b>
                </p>
              </div>
            </template>
            <template v-else>
              <label
                for="name"
                class="block mb-3 text-left text-sm font-medium text-white dark:text-white">
                Purchase Limit ($) <span class="text-red-800">*</span>
              </label>
              <div class="relative mb-6">
                <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                  <p class="text-white">$</p>
                </div>
                <input
                id="input-group-1"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                v-model="purchaseLimit"
                required
                type="number"
                placeholder="Purchase Limit" />
              </div>
            </template>
          </div>

          <div class="col-span-2 m-3">
            <h2 v-if="!isPurchaseLimitEditable" class="text-white text-center mb-3">
              Please contact Live Support on the left hand side of Juked.
            </h2>

            <template v-if="confirmPurchaseLimit">
              <button
                v-on:click="updateMyPurchaseLimit"
                :disabled="isUpdating"
                class="mr-12 inline-block gap-6 rounded-xl bg-accent font-semibold text-white/90 transition delay-0 duration-150 ease-in-out hover:scale-105 hover:opacity-70 md:h-12 md:w-auto px-5 py-3 bg-prim-highlight shadow-btn shadow-accent disabled:opacity-25">
                YES, I AM SURE
              </button>
              <button
                :disabled="isUpdating"
                v-on:click="denyPurchaseLimitUpdate"
                class="gap-6 inline-block rounded-xl bg-secondary font-semibold text-white/90 transition delay-0 duration-150 ease-in-out hover:scale-105 hover:opacity-70 md:h-12 md:w-auto px-5 py-3 bg-prim-highlight shadow-btn shadow-secondary disabled:opacity-5">
                NOT NOW
              </button>
            </template>
            <template v-else>
              <button
                v-if="isPurchaseLimitEditable"
                v-on:click="confirmPurchaseLimitUpdate"
                :disabled="!purchaseLimitRange || !purchaseLimit"
                class="mr-12 inline-block gap-6 rounded-xl bg-accent font-semibold text-white/90 transition delay-0 duration-150 ease-in-out hover:scale-105 hover:opacity-70 md:h-12 md:w-auto px-5 py-3 bg-prim-highlight shadow-btn shadow-accent disabled:opacity-25">
                Continue
              </button>
              <button
                :disabled="isUpdating"
                v-on:click="closePurchaseLimitModal"
                class="gap-6 inline-block rounded-xl bg-secondary font-semibold text-white/90 transition delay-0 duration-150 ease-in-out hover:scale-105 hover:opacity-70 md:h-12 md:w-auto px-5 py-3 bg-prim-highlight shadow-btn shadow-secondary disabled:opacity-5">
                Close
              </button>
            </template>
          </div>
        </div>
      </template>
    </punt-modal>
  </div>
</template>
  
<script>
import axios from '../../lib/axiosConfig';
import { mapActions, mapGetters } from 'vuex';
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';
import PuntModal from '@/components/Shared/PuntModal.vue';
import $store from '../../store';

export default {
  name: 'ResponsibleGaming',
  components: {
    Switch,
    SwitchGroup,
    SwitchLabel,
    PuntModal,
  },
  data() {
    return {
      darkMode: false,
      initialized: false,
      is_private: false,
      isPrivateMode: false,
      error: null,
      locked: false,
      confirmBreakInPlay: false,
      doubleConfirmBreakInPlay: false,
      isTakingBreakInPlay: false,
      breakDuration: 1,
      isOnBreak: false,
      onBreakMessage: '',
      confirmExclusionInPlay: false,
      doubleConfirmExclusionInPlay: false,
      isTakingExclusionInPlay: false,
      exclusionDuration: 6,
      isOnExclusion: false,
      onExclusionMessage: '',
      purchaseLimitRange: '',
      purchaseLimit: '',
      purchaseLimitRangeUpdated: '',
      purchaseLimitUpdated: '',
      isUpdating: false,
      editPurchaseLimit: false,
      isPurchaseLimitEditable: false,
      confirmPurchaseLimit: false
    };
  },
  created() {
    this.isPrivateMode = this.me.is_private;
    setTimeout(() => (this.initialized = true), 1000);
    if (this.me.break_type) {
      this.isOnBreak = true;
      this.onBreakMessage = 'You are on Break in Play till ' + this.me.break_till_date;
    }
  },
  computed: {
    ...mapActions(['fetchUser', 'updateBreakType', 'updateBreakTillDate']),
    ...mapGetters(['isLoggedIn', 'me']),
    hasLoaded() {
      return 'email_verified' in this.me;
    },
  },
  async mounted() {
    this.getUserPurchaseLimit();
    if (document.body.classList) {
      this.darkMode = document.body.classList.contains('dark');
    }
    if (!this.isLoggedIn) {
      this.$router.push({ path: '/' }).then(() => {
        this.$router.go();
      });
    }
  },
  watch: {
    isPrivateMode() {
      if (this.initialized) {
        this.privacyChanged();
      }
    },
  },
  methods: {
    /**
     * Confirm Popup for Request Break
     */
    requestBreakInPlay() {
      this.confirmBreakInPlay = true;
    },
    doubleConfirm() {
      this.doubleConfirmBreakInPlay = true;
    },
    closeModal() {
      this.resetTakeBreak();
    },
    async takeBreakInPlay() {
      if (this.me.id === null || this.me.id === undefined) {
        this.$toast.error('No User Found Please refresh and retry!');
        return;
      }
      this.isTakingBreakInPlay = true;
      try {
        const response = await axios.post('/responsible-gaming/take-a-break', {
          days: this.breakDuration,
        });
        this.resetTakeBreak();
        this.$toast.success(response.data.message);
        this.isOnBreak = true;
        this.onBreakMessage =
          'You are on Break in Play till ' + response.data.user.break_till_date;
        // this.$store.dispatch('logout');
        $store.dispatch('updateBreakType', response.data.user.break_type);
        $store.dispatch('updateBreakTillDate', response.data.user.break_till_date);
      } catch (e) {
        console.log(e);
        this.resetTakeBreak();
        this.$toast.error('Error Break in PLay! ');
      }
    },
    resetTakeBreak() {
      this.confirmBreakInPlay = false;
      this.doubleConfirmBreakInPlay = false;
      this.breakDuration = 1;
    },

    requestExclusionInPlay() {
      this.confirmExclusionInPlay = true;
    },
    doubleConfirmExclusion() {
      this.doubleConfirmExclusionInPlay = true;
    },

    // Exclusion
    closeExclusionModal() {
      this.resetTakeExclusion();
    },
    resetTakeExclusion() {
      this.confirmExclusionInPlay = false;
      this.doubleConfirmExclusionInPlay = false;
      this.exclusionDuration = 6;
    },
    async takeExclusionInPlay() {
      if (this.me.id === null || this.me.id === undefined) {
        this.$toast.error('No User Found Please refresh and retry!');
        return;
      }
      this.isTakingExclusionInPlay = true;
      try {
        const response = await axios.post('/responsible-gaming/take-a-exclusion', {
          months: this.exclusionDuration,
        });
        this.resetTakeExclusion();
        this.$toast.success(response.data.message);
        this.$store.dispatch('logout');
      } catch (e) {
        this.resetTakeExclusion();
        this.$toast.error('Error! ');
      }
    },

    /**
     * Update The Purchase Limit
     */
    confirmPurchaseLimitUpdate(){
      this.confirmPurchaseLimit = true;
    },
    denyPurchaseLimitUpdate(){
      this.confirmPurchaseLimit = false;
    },
    async updateMyPurchaseLimit() {
      if (this.purchaseLimitRange === '' || this.purchaseLimit === '') {
        this.$toast.error('Please enter the duration and amount!');
        return;
      }
      this.isUpdating = true;
      try {
        const response = await axios.post('/user/update-purchase-limit', {
          purchase_limit_range: this.purchaseLimitRange,
          purchase_limit: this.purchaseLimit,
        });

        this.isUpdating = false;
        this.editPurchaseLimit = false;
        this.confirmPurchaseLimit = false;
        this.purchaseLimitUpdated = this.purchaseLimit;
        this.purchaseLimitRangeUpdated = this.purchaseLimitRange;
        this.isPurchaseLimitEditable = false;
        this.$toast.success(response.data.message);
      } catch (e) {
        this.isUpdating = false;
        this.$toast.error('Error! ' + ((typeof e.data.error != 'undefined')?e.data.error:''));
      }
    },
    openPurchaseLimitModal() {
      this.editPurchaseLimit = true;
    },
    closePurchaseLimitModal() {
      this.editPurchaseLimit = false;
      this.confirmPurchaseLimit = false;
    },
    /**
     * Get User Purchase Limit
     */
    async getUserPurchaseLimit() {
      try {
        const response = await axios.get('/user/purchase-limit');
        this.purchaseLimitRange = response.data.purchase_limit_range;
        this.purchaseLimit = response.data.purchase_limit;
        
        
        if (!response.data.enable_purchase_limit) {
          this.isPurchaseLimitEditable = true;
        }else{
          this.purchaseLimitRangeUpdated = response.data.purchase_limit_range;
          this.purchaseLimitUpdated = response.data.purchase_limit;
        }
      } catch (error) {
        this.$toast.error('Error! ' + ((typeof e.data.error != 'undefined')?e.data.error:''));
      }
    },
  },
};
</script>
  
<style>
.bonus-modal {
  padding-bottom: 20px !important;
}
</style>
  