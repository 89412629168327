<template>
  <verify-account
    data-verifid="{me.verification_id}"
    :reverification="me.prev_verification_id"
    context="claim your hourly bonus"
    v-if="me.verification_id === null" />
  <div v-else>
    <div class="rounded-lg bg-secondary p-2">
      <!--<span class="bold text-lg font-bold">Welcome Offer</span>
        <br />
        <span class="text-sm"
          >Welcome offers are only valid for first 24 hours after signing up.</span
        >

        <div class="mt-4">
          <label for="welcome-bonus-code" class="mb-2 block text-sm font-medium">
            Code
            <span class="text-red-800">*</span>
          </label>
          <input
            type="text"
            v-model="welcomeCode"
            id="welcome-bonus-code"
            class="input-bet"
            required
          />
        </div>
        <button
          v-on:click="claimBonus(true)"
          :disabled="loading"
          class="btn-bet disabled:opacity-70"
        >
          Submit
        </button>

        <span class="mt-4 flex h-[1px] bg-line-color-bright opacity-50" />
      -->
      <div class="mt-4">
        <span class="bold text-lg font-bold">Redeem Bonus Drop</span>
        <br />
        <span class="text-sm"
          >Find bonus drop codes on our social media's such as Twitter & Telegram.</span
        >

        <div class="mt-4">
          <label for="bonus-code" class="mb-2 block text-sm font-medium">
            Code
            <span class="text-red-800">*</span>
          </label>
          <input type="text" v-model="code" id="bonus-code" class="input-bet" required />
        </div>
        <button
          v-on:click="claimBonus()"
          :disabled="loading"
          class="btn-bet disabled:opacity-70">
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { doRecaptcha } from '../../lib/recaptcha';
import axios from '../../lib/myAxios';
import VerifyAccount from '@/components/Bank/VerifyAccount.vue';

export default {
  name: 'Offers',
  components: {
    VerifyAccount,
  },
  data() {
    return {
      code: '',
      welcomeCode: '',
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['me']),
  },
  methods: {
    async claimBonus(isWelcome = false) {
      let code = isWelcome ? this.welcomeCode : this.code;
      if (!code || code.length === 0) {
        this.$toast.error('Bonus Code Empty!');
        return;
      }
      this.loading = true;
      try {
        axios
        .post('bonus', { code, fp: window.fp })
        .then((res) => {
          this.loading = false;
          this.$toast.warning('Processing bonus claim!');
        })
        .catch(({ data }) => {
          this.loading = false;
          if(typeof data.error != 'undefined')
          this.$toast.error(data.error);
        });
      } catch(error) {
        this.loading = false;
        console.log("error while claim ");
      }
    },
  },
};
</script>
