import Home from '@/components/Home/Home.vue';
import BetBy from '@/components/Home/BetBy.vue';
import LoginOrRegister from '@/components/Auth/LoginOrRegister.vue';
import Bank from '@/components/Bank/Bank.vue';
import About from '@/views/About.vue';
import NotFound from '@/views/NotFound.vue';
import Settings from '@/components/Settings/Settings.vue';

 
import MyTransactions from './components/Transactions/MyTransactions.vue';
 
import Details from './components/Transactions/Details.vue';

import Licenses from './components/Licenses/Licenses.vue';

import Policies from '@/components/Policies/Policies.vue';

 
import UserStats from '@/components/Stats/UserStats.vue';

import Overview from '@/components/Affiliates/Overview.vue';
import Funds from '@/components/Affiliates/Funds.vue';
import ReferedUsers from '@/components/Affiliates/ReferedUsers.vue';
import MyAffiliates from '@/components/Affiliates/MyAffiliates.vue';
import PasswordReset from '@/components/Auth/PasswordReset.vue';
import ForgotPassword from '@/components/Auth/ForgotPassword.vue';
 
import PrivacyPolicy from '@/components/SitePolicies/PrivacyPolicy.vue';
import SweepRules from '@/components/SitePolicies/SweepRules.vue';
 
import VIP from './components/Vip/VipNew.vue'
 

import { createRouter, createWebHistory } from 'vue-router';
import SearchMobile from './components/Search/SearchMobile.vue';

 
 
 
import Lossback from './components/Lossback.vue';

import ResponsibleGaming from "./components/ResponsibleGaming/ResponsibleGaming.vue";

 

/** @type {import('vue-router').RouterOptions['routes']} */
const routes = [
 
  { path: '/', component: Home, name: 'Home', meta: { title: 'Home' } },
  { path: '/sportsbook/:params(.*)*', component: BetBy, name: 'sportsbook', meta: { title: 'Sports book' } },

   
 
 

   

  {
    path: '/auth',
    component: LoginOrRegister,
    name: 'LoginOrRegister',
    meta: {},
  },
  {
    path: '/c/:code',
    component: LoginOrRegister,
    name: 'LoginOrRegister',
    meta: { tab: 'register' },
  },
  {
    path: '/weekly-bonus/:code',
    component: Lossback,
    name: 'Lossback',
    meta: {
      auth: true,
    },
  },

 
  {
    path: '/password/reset/:token',
    component: PasswordReset,
    name: 'PasswordReset',
    meta: {},
    props: (route) => ({ resetToken: +route.params.token }),
  },
  { path: '/licenses', component: Licenses, name: 'Licenses', meta: {} },
  {
    path: '/password/forgot',
    component: ForgotPassword,
    name: 'ForgotPassword',
    meta: {},
  },
  { path: '/auth/:type', component: LoginOrRegister, name: 'Authy', meta: {} },
  { path: '/bank', component: Bank, name: 'Bank', meta: {} },
   
  // {path: '/settings/verify', component: Verify, name: 'Verify', meta: {}},
  { path: '/settings/:type', component: Settings, name: 'Settings', meta: {} },
  { path: '/privacy-policy', component: PrivacyPolicy, name: 'PrivacyPolicy', meta: {} },
  { path: '/sweep-rules', component: SweepRules, name: 'SweepRules', meta: {} },
  //mobile navigation
  {
    path: '/side-menu',
    component: () => import('@/components/Sidebar/LeftSideBar.vue'),
    name: 'LeftSideBar',
    meta: {},
  },
  {
    path: '/chat',
    component: () => import('@/components/Chat/ChatBar.vue'),
    name: 'ChatBar',
    meta: {},
  },
  { path: '/policies/:name', component: Policies, name: 'Policies', meta: {} },
  { path: '/search', component: SearchMobile, name: 'Search', meta: {} },
  {
    path: '/about',
    meta: { title: 'About' },
    component: About,
    // example of route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import('@/views/About.vue')
  },
  {
    path: '/user/statistics',
    component: UserStats,
    name: 'UserStats',
    meta: {},
  },
  {
    path: '/affiliates/:name',
    component: MyAffiliates,
    name: 'MyAffiliates',
    meta: {},
    children: [
      { name: 'Overview', path: 'overview', component: Overview, meta: {} },
      { name: 'Funds', path: 'funds', component: Funds, meta: {} },
      {
        name: 'ReferedUsers',
        path: 'referred-users',
        component: ReferedUsers,
        meta: {},
      },
    ],
  },
  // Responsible Gaming
  {
    path: '/responsible-gaming',
    meta: { title: 'Responsible Gaming' },
    component: ResponsibleGaming,
  },

  { path: '/:path(.*)', component: NotFound },
  {
    path: '/transactions/:name',
    component: MyTransactions,
    name: 'MyTransactions',
    meta: {},
    children: [{ name: 'Details', path: 'details', component: Details, meta: {} }],
  },
  { path: '/:path(.*)', component: NotFound },
  {
    path: '/vip',
    component: VIP,
    name: 'VIP',
    meta: {},
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    } else {
      console.log('moving to top of the page');
      window.scrollTo(0, 0);
    }
  },
});

export default router;
