<template>
  <div :class="`accordian ${isOpen?'open':''}`" @click="toggleAccordion()">
    <h4 class="heading4 max-w-[528px]" :aria-expanded="isOpen" :aria-controls="`collapse${item.id}`">{{ item.que }}
      <div class="plus-icon icon">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.9513 16.6293C10.9513 14.14 10.9514 14.148 8.48311 14.1559C6.76989 14.2433 5.05236 14.1796 3.35012 13.9656C2.60757 13.8881 1.90452 13.5901 1.32994 13.1094C1.06593 12.8928 0.878769 12.5956 0.796556 12.2625C0.714343 11.9294 0.741529 11.5784 0.874028 11.2623C0.99503 10.9576 1.21979 10.7064 1.50795 10.5538C1.7961 10.4012 2.12876 10.3573 2.44615 10.4299C5.11877 10.6756 7.75207 10.1999 10.409 10.1286C11.03 10.1286 11.1007 9.76392 11.1086 9.2724C11.1579 7.30488 11.3101 5.34136 11.5645 3.39001C11.5645 3.04911 11.6588 2.71614 11.7217 2.38318C11.9025 1.47149 12.3348 0.742136 13.3488 0.750064C14.3629 0.757992 14.8188 1.48734 14.9917 2.3911C15.2 4.27808 15.2631 6.17851 15.1804 8.07531C15.2275 9.75599 15.1804 9.76392 16.8232 9.66086C17.963 9.61329 19.1107 9.51816 20.2505 9.48645C20.7779 9.4807 21.3046 9.5285 21.8226 9.62914C22.1769 9.67581 22.5064 9.83828 22.7605 10.0917C23.0146 10.3451 23.1794 10.6755 23.2296 11.0324C23.2848 11.39 23.2272 11.7561 23.065 12.079C22.9028 12.4019 22.6442 12.6652 22.3257 12.832C21.6905 13.2209 20.975 13.4569 20.2347 13.5217C18.9679 13.6995 17.6928 13.8106 16.4145 13.8546C15.4162 13.8546 15.1882 14.251 15.1882 15.1706C15.1445 17.3766 14.8544 19.5706 14.3236 21.711C14.1506 22.5038 13.8834 23.2173 12.9715 23.249C12.0597 23.2807 11.6667 22.5434 11.4701 21.7427C11.0809 20.0677 10.9066 18.3492 10.9513 16.6293Z"
            fill="black" />
        </svg>
      </div>
      <div class="minus-icon icon">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.921 22.2567C15.548 22.2567 13.1857 22.2567 10.8234 22.2567C10.0984 22.2409 9.38032 22.113 8.69522 21.8777C8.33569 21.7854 8.01995 21.572 7.80241 21.2741C7.58486 20.9763 7.47913 20.6127 7.50342 20.246C7.51414 19.8807 7.65088 19.5301 7.89093 19.2524C8.13097 18.9747 8.45986 18.7867 8.82291 18.7195C9.26179 18.6042 9.71071 18.5302 10.1637 18.4985C15.6261 18.1686 21.0814 17.8493 26.5296 17.5405C28.3497 17.4297 30.1766 17.5465 31.9672 17.8879C32.8717 18.0668 33.6378 18.4669 33.7442 19.488C33.7812 19.946 33.6398 20.4006 33.349 20.7587C33.0582 21.1169 32.64 21.3515 32.18 21.4145C29.5326 22.101 26.8083 22.4547 24.0715 22.4672C22.0284 22.5409 19.9747 22.4672 17.9316 22.4672L17.921 22.2567Z"
            fill="black" />
        </svg>

      </div>
    </h4>
    <div
      :class="`content ${isOpen ? 'open' : ''} w-full lg:max-w-[592px]`"
      v-html="item.ans"
         :id="`collapse${item.id}`">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: ['item'],
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

