<template>
  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="inline-block min-w-full py-2 sm:px-6 max-[480px]:pt-0">
      <div class="p-0 md:p-4 text-sm rounded-lg border-slate-300 text-slate-300">
        <span>
          <p>
            Welcome to Juked's Affiliate Program! Refer our brand and earn Juked Gold +
            Juked Cash through participating in the Affiliate Program! You will receive
            commission for every user that registers and plays on Juked through your
            links. You can use your Juked Gold or Juked Cash to play or redeem!
          </p>
          <Br> </Br>

          <p>
            Being a Juked affiliate means you will earn commission for your gameplay on
            our Social Casino - which unlike traditional affiliate programs, means that
            despite if they win or lose, you will still make the same commission!
          </p>
        </span>
        <br />

        <div class="relative">
          <div class="absolute inset-y-0 flex items-center pl-3 right-2">
            <button :disabled="!me.id" class="mt-4" v-on:click="copyAffiliateCode">
              <c-clipboard class="w-6 h-6" />
            </button>
          </div>

          <span class="pb-2 text-slate-300">Referral Link</span>
          <input
            id="address"
            v-model="code"
            class="block h-12 w-full rounded-lg border border-primary bg-primary p-2.5 pr-12 text-sm font-semibold text-slate-300 placeholder:text-text-dim-color focus:border-accent focus:ring-accent"
            placeholder="Address here"
            readonly
            required
            type="text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { copyToClipboard } from '@/lib/utils';
import CClipboard from '../Shared/CopyToClipboard.vue';

export default {
  name: 'Overview',
  components: {
    CClipboard,
  },
  data() {
    return {
      loading: false,
      code: null,
      referralCodes: [],
    };
  },
  mounted() {
    if (this.me.id) {
      this.getReferralCodes(this.me.id).then((res) => {
        this.referralCodes = res.data;
        if (this.referralCodes.length > 0) {
          this.code = import.meta.env.VITE_FRONTEND_URL + 'c/' + this.referralCodes[0];
        } else {
          this.code = import.meta.env.VITE_FRONTEND_URL + 'c/' + this.me.id;
        }
      });
    }
  },

  methods: {
    copyAffiliateCode() {
      copyToClipboard(this.code);
    },

    ...mapActions(['getReferralCodes']),
  },
  computed: {
    ...mapGetters(['me', 'isLoggedIn', 'userBalance']),
  },
};
</script>
