<template>
    <div class="vip-profile">
        <div class="profile_content">
            <h3 class="name">{{ me.username }}</h3>
            <div class="progress">
                <div class="progress-top flex justify-between items-center">
                    <h4 class="progress-title">Your VIP progress</h4>
                    <h4 class="progress-status">{{ percentage }}</h4>
                </div>
                <div class="w-full rounded-full progress-bar">
                    <div class="srounded-full striped-bar" :style="'width:'+percentage" ></div>
                </div>
                <div class="progress-bottom ">
                    <div class="left-part parts">
                        <img :src="lowerimg" class="ranking-image" alt="Ranking">
                        <h3 class="ranking-title">{{ lowername }}</h3>
                    </div>
                    <div class="right-part parts">
                        <h3 class="ranking-title">{{ uppername }}</h3>
                        <img :src="upperimg" class="ranking-image" alt="Ranking">
                    </div>
                </div>
            </div>
        </div>
        <div class="trophyimg-wrapper">
            <img class="trophyimg" src="/images/ranks/trophy-image.webp" />

        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'VipProfile',
  props: {
    percentage:{
        default:0
    },
    lowerimg:{
        default:'',
    },
    lowername:{
        default:'',
    },
    upperimg:{
        default:''
    },
    uppername:{
        default:''
    }
  },
  computed: {
    ...mapGetters(['me', 'isLoggedIn']),
  }
}
</script>

<style lang="scss" scoped>
.vip-profile {
	background-image: url('/images/ranks/profile-bg.webp');
    background-size: cover;
    background-position: center;
    border-radius: 31px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 76px ;
    margin: 50px 0 40px 0;
    .profile_content{
        padding: 52px 0 53px;
        max-width: 540px;
        flex-grow: 1;
    }
    .trophyimg{
        
    }
	.name {
		font-size: 24px;
		line-height: 36px;
		font-weight: 700;
        margin-bottom: 22px;
	}
    .progress-top{
        margin-bottom: 9px;
        column-gap: 10px;
        .progress-title{
            font-size: 13px;
            line-height: 18px;
            letter-spacing: -0.00615385em;
        }
        .progress-status{
            font-size: 18px;
            line-height: 18px;
            font-weight: 700;
            letter-spacing: -0.00615385em;
            @apply text-orange
        }
    }
    .progress-bottom{
        display: flex;
        justify-content: space-between;
        column-gap: 10px;
        .parts{
            display: flex;
            align-items: center;
            column-gap: 10px;
        }
        .ranking-title{
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
        }
}
    .progress-bar{
        height: 14px;
        @apply bg-dark-blue;
        border-radius: 12px;
        overflow: hidden;
        margin-bottom: 8px;
        .striped-bar{
            @apply bg-orange;
            background-image:linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
            height: 100%;
            background-size: 1rem 100% ;
        }
    }
}
@media screen and (max-width:992px){
    .vip-profile{
        flex-direction: column;
        padding: 18px 20px 0 14px;
        border-radius: 21px;
        margin:27px 0 57px;
        .profile_content{
            max-width: 100%;
            width: 100%;
            padding: 0;
            .name{
                font-size: 16px;
                margin-bottom:5px
            }
            .ranking-title{
                font-size: 14px;
            }
        }
        
    }
}
</style>