//import defer from './lib/defer'
//import GAuth from 'vue-google-oauth2'

import $store from '@/store';

const gauthOption = {
  clientId: '939724038002-o0bq65t5gi97f83di5bn7psn4lr0tnh9.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account',
};

import { createApp } from 'vue';
import './tailwind.css';
import App from './App.vue';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import router from './router.js';
import VueChatScroll from './lib/vcs';
import store from './store';
import axios from './lib/myAxios';
import VueAxios from 'vue-axios';
import { createPinia } from 'pinia';
import VueResizeObserver from 'vue-resize-observer';
import FloatingVue from 'floating-vue';
import VueSweetalert2 from 'vue-sweetalert2';
import { createGtm } from '@gtm-support/vue-gtm';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'floating-vue/dist/style.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import 'swiper/css';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import VueLazyload from 'vue-lazyload';
import VueNumber from 'vue-number-animation';
import { TailwindPagination } from 'laravel-vue-pagination';
import * as Sentry from '@sentry/vue';
import { setGlobalFp } from '@/lib/utils';
import { sentryConfig } from '@/lib/utils';

const app = createApp(App);

Sentry.init({ app, ...sentryConfig });

// bet by script added on load
if (!document.getElementById('betby-script')) {
  let script = document.createElement('script');
  script.src = import.meta.env.VITE_BETBY_LIBRARY_URL;
  script.async = true;
  script.id = 'betby-script';
  script.onload = () => store.dispatch('updateIsBeyByScriptLoaded', true);  // Resolve when loaded
  script.onerror = () => alert('Something went wrong. try page refresh');
  document.head.appendChild(script);
}

setTimeout(() => {
  Sentry.setUser({
    email: store.getters.me.email,
    id: store.getters.me.id,
    username: store.getters.me.username,
  });
}, 2000);

app.use(store);
app.component('Datepicker', Datepicker);
app.component('TailwindPagination', TailwindPagination);
app.use(VueNumber);

app.directive('focus', {
  inserted: function (el) {
    el.focus();
  },
});
app.directive('resize', {
  VueResizeObserver,
});

if (store.getters.isLoggedIn) {
  //store.dispatch('fetchRaces');
  store.dispatch('fetchUser').then((user) => {
    //rebuildEcho()
    //deferUntilAuth.resolve(user)
  }).catch((data) => {
    console.log('issue while fetching user');
  });
} else {
  let unregister = store.watch(store._wrappedGetters.isLoggedIn, (newAuth, oldAuth) => {
    if (newAuth) {
      store.dispatch('fetchUser').then((user) => {
        //deferUntilAuth.resolve(user)
        unregister();
      }).catch((data) => {
        console.log('issue while fetching user');
      });
    }
  });
}

app.use(VueAxios, axios);
app.use(VueChatScroll);
app.use(createPinia());
app.use(router);
app.use(PerfectScrollbar);
app.use(VueLazyload);
if(import.meta.env.VITE_NODE_ENV === 'production')
{
  app.use(
    createGtm({
      id: import.meta.env.VITE_GTM_ID,
      vueRouter: router,
    }),
  );
}
const sweetalert2Options = {
  background: '#0A0A23',
  color: '#ffffff',
  confirmButton:
    '!bg-onprimary !text-black !hover:bg-onprimary/60 !rounded-md  !mt-4 !mr-4',
  cancelButton: '!rounded-md !mt-4 !ml-4',
  customClass: {
    confirmButton: 'bg-accent text-white',
  },
};
app.use(VueSweetalert2, sweetalert2Options);
app.use(FloatingVue);
app.use(VueToast, { position: 'top-left' });
app.mount('#app');

setGlobalFp();
