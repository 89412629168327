<template>
  <landing v-if="!this.isLoggedIn && this.landingPage" />
  <div
    v-else
    class="h-auto max-w-[100%] md:min-h-[auto] flex flex-col p-0 items-center  ">
    <div class="w-full max-[480px]:p-0">
      <BetBy />
    </div>
  </div>
</template>

<script>

import BetBy from '@/components/Home/BetBy.vue';
// const Landing = () => import('@/components/Home/Landing.vue')
import Landing from  '@/components/Home/Landing.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Home',
  components: {
    BetBy,
    Landing,
  },
  computed: {
    ...mapGetters(['activeRoom', 'me', 'isLoggedIn', 'landingPage']),
  },
  setup() {
  },
  async created() {
  },
  data() {
    return {
      loading: true,
    };
  },
  methods: {},
  watch: {
    '$route.query.aid': {
      handler: function(newState) {
        if (this.$route.query.aid) {
          window.localStorage['aid'] = this.$route.query.aid;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
