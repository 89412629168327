<template>
  <!-- site-footer -->
  <footer
    class="site-footer relative footer section pt-[50px] max-lg:px-[16px] max-lg:text-center">
    <img src="/images/landing/bg.png" class="section_bg_images bgl1" />
    <img src="/images/landing/bg.png" class="section_bg_images bgl2" />
    <div class="footer-top pb-10 border-b border-b-white/30 relative z-[50]">
      <div class="container flex m-auto gap-x-[36px] max-[400px]:gap-x-[25px]  max-lg:flex-col">
        <div class="lg:w-5/12 footer-widget-discription max-lg:mb-[40px] reveal_div">
          <a href="">
            <img src="../../../assets/logo.png" alt=""
                 class="logo max-w-[230px] mb-10 footer-logo max-lg:m-auto max-lg:mb-[24px] max-lg:max-w-[143px]"
            />
          </a>
          <div class="discription body3 max-w-[501px] max-lg:mx-auto">
            <p class="mb-6">18+ only</p>
            <p>
              NO PURCHASE IS NECESSARY to play.
              PROMOTIONS ARE VOID WHERE PROHIBITED BY LAW. For detailed rules, see
              <a class="text-accent-green1" target="_blank" href="/docs/Terms-And-Conditions.pdf">Terms of Use</a> &
              <router-link
                :to="'/sweep-rules'"
                class="flex-0 text-right text-accent-green1 max-[767px]:text-center">
                Sweepstakes Rules.
              </router-link>
              juked.co Social Gaming Platform is a play-for-fun website intended for
              amusement purposes only. juked.co's Social Gaming Platform does not offer
              “real-money gambling” or the opportunity to win real money. juked.co Social
              Gaming Platform is only open to Eligible Participants, who are at least
              eighteen (18) years old or the age of majority in their jurisdiction
              (whichever occurs later) at the time of entry.
            </p>
          </div>
        </div>
        <div class="md:w-2/12 flex justify-center items-center max-lg:absolute max-lg:top-[38%] max-lg:left-[-75px]">
          <img src="/images/footer/coin.svg" alt="coin" />
        </div>
        <div class="lg:w-3/12 footer-widget-menu two reveal_div">
          <div class="footer-menu max-w-[200px] footer-widget-menu m-auto">
            <h3 class="footer-menu-title title heading4 mb-6">Hyperlinks</h3>
            <ul class="max-w-[178px] max-lg:m-auto max-lg:max-w-full max-lg:mb-[40px]">
              <li><a target="_blank" href="/docs/Privacy-Policy.pdf" class="menu body3 mb-4 inline-block">Privacy
                Policy</a></li>
              <li><a target="_blank" href="/docs/Terms-And-Conditions.pdf" class="menu body3 mb-4 inline-block">Terms of
                Use</a></li>
              <li><a href="/sweep-rules" class="menu body3 mb-4 inline-block">Sweeps Rules</a></li>
              <li>
                <a target="_blank" href="/docs/Juked-Responsible-Social-Gaming.pdf"
                   class="menu body3 mb-4 inline-block">Sports Prediction Rules</a></li>
              <li>
                <a target="_blank" href="/docs/Juked-Responsible-Social-Gaming.pdf"
                   class="menu body3 mb-4 inline-block">Customer Acceptance
                  Policy</a></li>
              <li>
                <a target="_blank" href="/docs/Juked-Responsible-Social-Gaming.pdf"
                   class="menu body3 mb-4 inline-block">Responsible Social Gameplay</a></li>
            </ul>
          </div>
        </div>
        <div class="lg:w-2/12 footer-widget-menu three reveal_div">
          <div class="footer-menu max-w-[280px] footer-widget-menu ml-auto max-lg:max-w-full">
            <h3 class="footer-menu-title title heading4 mb-6">Contact</h3>
            <h4 class="footer-link-title body3 text-white">Partners:</h4>
            <a href="mailto:partners@juked.co" class="footer-link body3 text-accent-green1 mb-6 inline-block"
            >Partners@Juked.co</a
            >
            <h4 class="footer-link-title body3 text-white">Support:</h4>
            <a href="mailto:help@juked.co" class="footer-link body3 text-accent-green1"
            >help@juked.co</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom  relative z-[50]">
      <div class="block lg:hidden mb-[32px] ">
        <div class="social-media-icon flex flex-row gap-4 items-center justify-center pt-10 pb-8">
          <a
            target="_blank"
            href="https://instagram.com/Juked.co"
            class="social-link">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9Z"
                fill="#C159EF" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.87937 3.28246C10.2827 2.90585 13.7173 2.90585 17.1207 3.28246C18.98 3.48968 20.479 4.95099 20.6973 6.81403C21.1009 10.2593 21.1009 13.7397 20.6973 17.1849C20.479 19.048 18.98 20.5093 17.1217 20.7175C13.718 21.0942 10.283 21.0942 6.87937 20.7175C5.02007 20.5093 3.52108 19.048 3.30274 17.1859C2.89909 13.7403 2.89909 10.2596 3.30274 6.81403C3.52108 4.95099 5.02007 3.48968 6.87937 3.28246ZM16.8955 6.1347C16.6358 6.1347 16.3868 6.23768 16.2032 6.42099C16.0195 6.6043 15.9164 6.85292 15.9164 7.11216C15.9164 7.3714 16.0195 7.62002 16.2032 7.80333C16.3868 7.98664 16.6358 8.08962 16.8955 8.08962C17.1552 8.08962 17.4042 7.98664 17.5878 7.80333C17.7714 7.62002 17.8746 7.3714 17.8746 7.11216C17.8746 6.85292 17.7714 6.6043 17.5878 6.42099C17.4042 6.23768 17.1552 6.1347 16.8955 6.1347ZM7.34933 11.9995C7.34933 10.7681 7.83931 9.58713 8.71149 8.71641C9.58366 7.84569 10.7666 7.35653 12 7.35653C13.2335 7.35653 14.4164 7.84569 15.2886 8.71641C16.1607 9.58713 16.6507 10.7681 16.6507 11.9995C16.6507 13.2309 16.1607 14.4118 15.2886 15.2825C14.4164 16.1533 13.2335 16.6424 12 16.6424C10.7666 16.6424 9.58366 16.1533 8.71149 15.2825C7.83931 14.4118 7.34933 13.2309 7.34933 11.9995Z"
                fill="#C159EF" />
            </svg>
          </a>
          <a
            target="_blank"
            href="https://facebook.com/JukedApp"
            class="social-link">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10C0 14.84 3.44 18.87 8 19.8V13H6V10H8V7.5C8 5.57 9.57 4 11.5 4H14V7H12C11.45 7 11 7.45 11 8V10H14V13H11V19.95C16.05 19.45 20 15.19 20 10Z"
                fill="#5FF487" />
            </svg>

          </a>
          <a target="_blank" href="https://x.com/jukedco" class="social-link">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
            </svg>
          </a>
        </div>
        <img src="/images/footer/m-cash.svg" class="footer_case_left" alt="coin" />
        <img src="/images/footer/m-cash2.svg" class="footer_case_right" alt="coin" />
      </div>
      <div class="container  m-auto max-[360px]:block max-[360px]:text-center ">
        <div
          class="inner-content flex items-end pt-10 pb-8 relative max-lg:items-center max-lg:justify-between max-md:pt-0">
          <div class="md:w-2/12">
            <h3 class="title body3 text-white/50">{{ getcurrentyear() }} Juked.</h3>
          </div>
          <div class="md:w-8/12  hidden lg:block">
            <div class="social-media-icon flex flex-row gap-4 items-center justify-center z-[10] relative">
              <a
                target="_blank"
                href="https://instagram.com/Juked.co"
                class="social-link">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9Z"
                    fill="#C159EF" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.87937 3.28246C10.2827 2.90585 13.7173 2.90585 17.1207 3.28246C18.98 3.48968 20.479 4.95099 20.6973 6.81403C21.1009 10.2593 21.1009 13.7397 20.6973 17.1849C20.479 19.048 18.98 20.5093 17.1217 20.7175C13.718 21.0942 10.283 21.0942 6.87937 20.7175C5.02007 20.5093 3.52108 19.048 3.30274 17.1859C2.89909 13.7403 2.89909 10.2596 3.30274 6.81403C3.52108 4.95099 5.02007 3.48968 6.87937 3.28246ZM16.8955 6.1347C16.6358 6.1347 16.3868 6.23768 16.2032 6.42099C16.0195 6.6043 15.9164 6.85292 15.9164 7.11216C15.9164 7.3714 16.0195 7.62002 16.2032 7.80333C16.3868 7.98664 16.6358 8.08962 16.8955 8.08962C17.1552 8.08962 17.4042 7.98664 17.5878 7.80333C17.7714 7.62002 17.8746 7.3714 17.8746 7.11216C17.8746 6.85292 17.7714 6.6043 17.5878 6.42099C17.4042 6.23768 17.1552 6.1347 16.8955 6.1347ZM7.34933 11.9995C7.34933 10.7681 7.83931 9.58713 8.71149 8.71641C9.58366 7.84569 10.7666 7.35653 12 7.35653C13.2335 7.35653 14.4164 7.84569 15.2886 8.71641C16.1607 9.58713 16.6507 10.7681 16.6507 11.9995C16.6507 13.2309 16.1607 14.4118 15.2886 15.2825C14.4164 16.1533 13.2335 16.6424 12 16.6424C10.7666 16.6424 9.58366 16.1533 8.71149 15.2825C7.83931 14.4118 7.34933 13.2309 7.34933 11.9995Z"
                    fill="#C159EF" />
                </svg>
              </a>
              <a
                target="_blank"
                href="https://facebook.com/JukedApp"
                class="social-link">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10C0 14.84 3.44 18.87 8 19.8V13H6V10H8V7.5C8 5.57 9.57 4 11.5 4H14V7H12C11.45 7 11 7.45 11 8V10H14V13H11V19.95C16.05 19.45 20 15.19 20 10Z"
                    fill="#5FF487" />
                </svg>

              </a>
              <a target="_blank" href="https://x.com/jukedco" class="social-link">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                </svg>

              </a>


            </div>
            <img src="/images/footer/cash.png" class="footer_case_left" alt="coin" />
            <img src="/images/footer/cash2.png" class="footer_case_right" alt="coin" />
          </div>
          <div class="md:w-2/12 max-[360px]:mt-2">
            <h3 class="title min-[501px]:text-right  body3 text-white/50">© All rights reserved.</h3>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- site-footer -->
</template>

<script>
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export default {
  name: 'PFooter',
  methods: {
    getcurrentyear() {
      return new Date().getFullYear();
    },
    scrollToElement(id) {
      const container = document.getElementById(id);
      container.scrollIntoView({ behavior: 'smooth' });
    },
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    const t1 = gsap.timeline({
      scrollTrigger: {
        trigger: '.site-footer',
        start: 'bottom bottom',
        end: 'bottom top',
      },
    });
    t1.from('.reveal_div', {
      y: '40px',
      duration: 0.5,
    });
    t1.to('.reveal_div', {
      y: '0',
      duration: 0.5,
    });
  },
};
</script>
