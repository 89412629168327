import { defineStore } from 'pinia';

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const generalStore = defineStore('general', {
  state: () => {
    return {
      showLeftMenu: false,
      showChat: false,
      showSearch: false,
      isLoading: false,
      user: null,
      isTheatreMode: true,
    };
  },
  getters: {},
  actions: {},
});
