<template>
    <section class="section section-why-juked " id="whyJuked">
        <div class="container big">
            <div class="inner-content bg-yellow relative overflow-hidden rounded-[24px] pt-[100px] max-lg:pt-[71px]">
        <img
          src="/images/landing/why-juked/image1.svg"
          class="absolute top-0 left-0 hidden lg:inline-block"
          alt="cash image" />
        <img
          src="/images/landing/why-juked/image2.svg"
          class="absolute top-0 right-0 hidden lg:inline-block"
          alt="cash image" />
        <img
          src="/images/landing/why-juked/cash.svg"
          class="absolute top-0 center_image inline-block lg:hidden"
          alt="cash image" />
                <h2 class="heading2 text-black text-center">Why juked?</h2>
        <div class="w-full flex justify-center mt-[34px] mb-[20px] lg:hidden">
          <div class="body2 text-black">{{formatNumber(currentSlide)}}/{{ formatNumber(totalSlides) }}</div>
               </div>
                <div class="slider flex px-12 max-lg:px-4 relative">
                    <swiper
            class="juked-slider max-w-full pt-[64px] max-lg:pt-0"
                        :modules="modules"
                        :slides-per-view="1"
                        :loop="true"
                        :loopAdditionalSlides="30"
                        effect="fade"
                        :navigation="{
                            enabled: true,
                            nextEl: '.juked-slider-next',
                            prevEl: '.juked-slider-prev',
                        }"
                        @slideChange="onSlideChange">
                        <swiper-slide>
              <div
                class="content-wrapper flex gap-[94px] max-lg:gap-0 slide1 max-lg:flex-col max-lg:items-center max-lg:h-full justify-between">
                <div class="w-full lg:w-1/3 pt-12 max-lg:pt-0 max-lg:order-1 max-lg:w-[313px] hidden lg:block">
                  <p
                    class="body2 mb-[50px] text-black max-lg:mb-6 max-lg:text-center reveal hidden lg:block">
                    <span> 01 </span>
                  </p>
                  <h3 class="heading3 reveal text-black max-lg:text-center max-lg:mb-4">
                    <span> No downloads required </span>
                  </h3>
                                </div>
                <div class="w-full lg:w-1/3 flex justify-center max-lg:order-3 max-lg:w-[313px]">
                                    <div class="img-wrapper relative w-[423px] max-lg:w-[263px]">
                    <img
                      src="/images/landing/why-juked/juked1.png"
                      alt="image"
                      class="hidden lg:inline-block" />
                    <img
                      src="/images/landing/why-juked/mobilejuked1.png"
                      alt="image"
                      class="inline-block lg:hidden" />
                    <div class="btn-bottom-center reveal">
                      <span>
                                        <a
                                            href="#"
                          class="btn btn-primary min-w-[359px] max-lg:min-w-[221px] text-center"
                          v-on:click="
                            $router.push({
                              path: $route.path,
                              query: { tab: 'register' },
                            })
                          "
                          >REGISTER</a
                        >
                      </span>
                    </div>
                    <button class="cursor-default btn btn-purple btn-top-right">
                      Play in browser
                    </button>
                  </div>
                                    </div>
                <div class="w-full lg:w-1/3 pt-12 max-lg:pt-0 max-lg:order-0 max-lg:w-[313px] block lg:hidden">
                  <h3 class="heading3 reveal text-black max-lg:text-center max-lg:mb-4">
                    <span> No downloads required </span>
                  </h3>
                                </div>
                <div class="w-full lg:w-1/3 max-lg:text-center max-lg:mb-[57px] reveal self-center">
                  <span
                    class="flex items-center justify-center gap-[32px] flex-col body2 text-black max-lg:order-2 max-lg:w-full max-lg:gap-3">
                    <p>
                      Juked can be streamed from any device wherever, whenever you want.
                    </p>
                    <p>
                      Access the platform from your favorite browser and start playing for
                      FREE!
                    </p>
                  </span>
                                </div>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="content-wrapper flex gap-[94px] max-lg:gap-0 slide2 max-lg:flex-col max-lg:items-center max-lg:h-full max-lg:justify-end">
                <div class="w-full lg:w-1/3 pt-12 max-lg:pt-0 max-lg:order-1 max-lg:w-[313px] hidden lg:block">
                  <p
                    class="body2 mb-[50px] text-black max-lg:mb-6 max-lg:text-center reveal">
                    <span> 02 </span>
                  </p>
                  <h3 class="heading3 reveal text-black max-lg:text-center max-lg:mb-4">
                    <span> No purchase necessary </span>
                  </h3>
                                </div>
                <div class="w-full lg:w-1/3 flex justify-center max-lg:order-3 max-lg:w-[313px]">
                                    <div class="img-wrapper relative w-[423px] max-lg:w-[263px]">
                    <img
                      src="/images/landing/why-juked/juked2.png"
                      alt="image"
                      class="hidden lg:inline-block" />
                    <img
                      src="/images/landing/why-juked/mobilejuked2.png"
                      alt="image"
                      class="inline-block lg:hidden" />
                                        <button class="cursor-default btn btn-purple btn-bottom-right">Free-to-play</button>
                  </div>
                                    </div>
                <div class="w-full lg:w-1/3 pt-12 max-lg:pt-0 max-lg:order-0 max-lg:w-[313px] block lg:hidden">
                  <h3 class="heading3 reveal text-black max-lg:text-center max-lg:mb-4">
                    <span> No purchase necessary </span>
                  </h3>
                                </div>
                <div class="w-full lg:w-1/3 max-lg:text-center max-lg:mb-[57px] reveal self-center">
                  <span
                    class="flex items-center justify-center gap-[32px] flex-col body2 text-black max-lg:order-2 max-lg:w-full max-lg:gap-3">
                                    <p >We are dedicated to building a completely FREE social sports experience.</p>
                                    <p >Play with both JG and JC immediately without any payment!</p>
                  </span>
                                </div>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="content-wrapper flex gap-[94px] max-lg:gap-0 slide3 max-lg:flex-col max-lg:items-center max-lg:h-full max-lg:justify-end">
                <div class="w-full lg:w-1/3 pt-12 max-lg:pt-0 max-lg:order-1 max-lg:w-[313px] hidden lg:block">
                  <p
                    class="body2 mb-[50px] text-black max-lg:mb-6 max-lg:text-center reveal hidden lg:block">
                    <span> 03 </span>
                  </p>
                  <h3 class="heading3 reveal text-black max-lg:text-center max-lg:mb-4">
                    <span> GET JG & JC FOR FREE </span>
                  </h3>
                                </div>
                <div class="w-full lg:w-1/3 flex justify-center max-lg:order-3 max-lg:w-[313px]">
                                    <div class="img-wrapper relative w-[423px] max-lg:w-[263px]">
                    <img
                      src="/images/landing/why-juked/juked3.png"
                      alt="image"
                      class="hidden lg:inline-block" />
                    <img
                      src="/images/landing/why-juked/mobilejuked3.png"
                      alt="image"
                      class="inline-block lg:hidden" />
                                        <button class="cursor-default btn btn-purple btn-bottom-right">JC</button>
                                        <button class="cursor-default btn btn-purple btn-top-left">JG</button>
                                    </div>
                                </div>
                <div class="w-full lg:w-1/3 pt-12 max-lg:pt-0 max-lg:order-0 max-lg:w-[313px] block lg:hidden">
                  <h3 class="heading3 reveal text-black max-lg:text-center max-lg:mb-4">
                    <span> GET JG & JC FOR FREE </span>
                  </h3>
                </div>
                <div class="w-full lg:w-1/3 max-lg:text-center max-lg:mb-[57px] reveal self-center">
                  <span
                    class="flex items-center justify-center gap-[32px] flex-col body2 text-black max-lg:order-2 max-lg:w-full max-lg:gap-3">
                                    <p>Claim your free JC & JG via our Hourly Login Bonuses, Races, Rakeback, Mail a Request, and Customer Benefits!</p>
                                    <p>Enjoy our immersive social experience.</p>
                  </span>
                                </div>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="content-wrapper flex gap-[94px] max-lg:gap-0 slide4 max-lg:flex-col max-lg:items-center max-lg:h-full max-lg:justify-end">
                <div class="w-full lg:w-1/3 pt-12 max-lg:pt-0 max-lg:order-1 max-lg:w-[313px] hidden lg:block">
                  <p
                    class="body2 mb-[50px] text-black max-lg:mb-6 max-lg:text-center reveal hidden lg:block">
                    <span> 04 </span>
                  </p>
                  <h3 class="heading3 reveal text-black max-lg:text-center max-lg:mb-4">
                    <span> 24/7 customer support </span>
                  </h3>
                                </div>
                <div class="w-full lg:w-1/3 flex justify-center max-lg:order-3 max-lg:w-[313px]">
                                    <div class="img-wrapper relative w-[423px] max-lg:w-[263px]">
                    <img
                      src="/images/landing/why-juked/juked4.png"
                      alt="image"
                      class="hidden lg:inline-block" />
                    <img
                      src="/images/landing/why-juked/mobilejuked3.png"
                      alt="image"
                      class="inline-block lg:hidden" />
                                        <button class="cursor-default btn btn-purple btn-bottom-right">Fast support</button>
                                    </div>
                                </div>
                <div class="w-full lg:w-1/3 pt-12 max-lg:pt-0 max-lg:order-0 max-lg:w-[313px] block lg:hidden">
                  <h3 class="heading3 reveal text-black max-lg:text-center max-lg:mb-4">
                    <span> 24/7 customer support </span>
                  </h3>
                </div>
                <div class="w-full lg:w-1/3 max-lg:text-center max-lg:mb-[57px] reveal self-center">
                  <span
                    class="flex items-center justify-center gap-[32px] flex-col body2 text-black max-lg:order-2 max-lg:w-full max-lg:gap-3">
                                    <p>Contact our 24/7 support if you have any questions or problems, lightning-fast answers are guaranteed!</p>
                                    <p>Juked team is always ready to help you!</p>
                  </span>
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                    <div class="navigation-button hidden lg:flex items-start gap-7">
                        <div class="no_selection arrow left cursor-pointer juked-slider-prev">
                            <svg width="48" height="31" viewBox="0 0 48 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M47.8381 19.2404C36.8334 18.3409 25.6623 15.8533 13.4749 18.2004C17.9748 21.9389 21.7262 25.0449 25.9303 28.5445C23.0197 31.5802 20.3864 31.5661 17.9932 29.7812C12.6618 25.846 7.35813 21.7702 2.21152 17.315C-0.985482 14.5041 -0.662072 10.864 2.8306 8.57315C4.7667 7.44558 6.74062 6.47451 8.74412 5.66392C11.9966 4.13199 15.2582 2.61408 18.5199 1.15243C19.5732 0.688632 20.6636 0.449739 22.0495 -1.13433e-06C22.0495 4.9612 19.5548 5.28442 17.9563 6.68986C16.3578 8.0953 14.3527 9.31805 12.5325 10.6111L12.708 12.0165C18.714 12.0165 24.7199 11.7073 30.7166 12.1149C35.7431 12.4662 40.7418 13.6327 45.7406 14.5182C47.1174 14.7993 48.4756 15.3193 47.8381 19.2404Z" fill="#0D0D0D"/>
                            </svg>
                        </div>
                        <div class="no_selection arrow right cursor-pointer juked-slider-next">
                            <svg width="48" height="32" viewBox="0 0 48 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M-7.58606e-07 17.3549C0.823696 12.5185 2.57178 12.7102 4.12765 12.666C11.1748 12.5185 18.222 12.4448 25.2692 12.3711C28.0149 12.3711 30.8338 12.3711 33.8906 12.3711C33.314 9.61375 31.6758 9.42206 30.5866 8.22772C29.4975 7.03337 28.0149 5.9275 26.9258 4.453C26.7149 4.03087 26.5734 3.53002 26.5142 2.99663C26.455 2.46324 26.4801 1.91444 26.5871 1.40077C26.7946 0.963673 27.062 0.609797 27.3684 0.366797C27.6748 0.123797 28.012 -0.0017163 28.3535 1.68487e-05C29.2235 0.195324 30.0641 0.645049 30.8337 1.32706C35.7942 4.89535 40.7364 8.52261 45.6878 12.0909C46.9508 13.0051 48.3053 14.0962 47.9392 16.6029C47.61 18.3364 46.9136 19.8288 45.9715 20.82C44.6874 21.9633 43.3313 22.8827 41.9262 23.5625C38.2653 25.848 34.5221 28.0893 30.8337 30.3895C29.2138 31.3921 27.2553 33.5154 26.1387 30.2568C24.8757 26.5853 27.182 25.0223 28.7379 23.3414C29.4609 22.5746 30.2206 21.8669 31.6758 20.3924C20.8213 17.7972 10.5159 19.5371 -7.58606e-07 17.3549Z" fill="#0D0D0D"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
  
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import { Navigation , EffectFade } from 'swiper';
export default {
    name: 'WhyJuked',
    components: {
        Swiper,
        SwiperSlide,
    },
  data: () => ({
        currentSlide: 1,
        totalSlides:5,
    }),
    setup() {
        return {
            modules: [Navigation , EffectFade],
        };
  },
  methods: {
        onSlideChange(swiper) {
            this.currentSlide = swiper.realIndex + 1; // Update the current slide index
        },
        formatNumber(num) {
            return num.toString().padStart(2, '0'); // Change 2 to the desired number of digits
        },
    },
};
</script>
  