<template>
  <div class="p-4">
    <div
      v-if="reverification == null"
      class="flex flex-col gap-4 md:text-lg sm:text-base text-sm text-slate-300/60 font-sesmibold">
      <p>One final step before you can continue!</p>
      <p>
        Please complete our quick and easy verification process. Your submission will be
        automatically reviewed for approval.
      </p>

      <p>Please double check you meet the following requirements:</p>
      <ul class="list-disc ml-4">
        <li>You are at least 21 years old.</li>
        <li>
          You need to use the physical documents when trying to verify. Do NOT use
          screenshots or previously taken photos.
        </li>
        <li>
          You have not already verified an existing account. You cannot create multiple
          accounts.
        </li>
        <li>
          Unfortunately we cannot currently serve people from the following US states:
          Washington, Idaho, Kentucky, Nevada and Michigan.
        </li>
      </ul>
    </div>

    <div
      v-else
      class="flex flex-col gap-4 md:text-lg sm:text-base text-sm text-slate-300/60 font-sesmibold">
      <p>We need you to verify your identity once more before you can continue!</p>
      <p>
        Please complete our quick and easy verification process. Your submission will be
        automatically reviewed for approval.
      </p>

      <p>Please double check you are following these requirements:</p>
      <ul class="list-disc ml-4">
        <li>You are at least 21 years old.</li>
        <li>
          You need to use the physical documents when trying to verify. Do NOT use
          screenshots or previously taken photos.
        </li>
        <li>
          You have not already verified an existing account. You cannot create multiple
          accounts.
        </li>
        <li>
          Unfortunately we cannot currently serve people from the following US states:
          Washington, Idaho, Kentucky, Nevada and Michigan.
        </li>
      </ul>
    </div>

    <div class="w-full">
      <div class="w-full flex flex-col items-center justify-center mt-4">
        <a
          :disabled="canNotVerify"
          target="_blank"
          :href="verifylink"
          class="btn-bet ch-button hover:opacity-75 disabled:opacity-50 min-w-[200px]">
          {{ buttonText }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { authStore } from '@/store/pinia/authStore';

export default {
  name: 'VerifyAccount',
  props: {
    context: { default: 'Redeem your Juked Cash' },
    reverification: {
      default: null,
    },
  },
  setup() {
    return { authS: authStore() };
  },
  data() {
    return { loading: false, session: null, verifylink: null };
  },
  async created() {
    await this.fetchVerification();
    this.getVerificationLink();
  },
  computed: {
    buttonText() {
      if (this.loading) return 'Please wait...';

      if (!this.session || ['expired', 'abandoned'].includes(this.session.status)) {
        return 'Get Verified';
      }

      if (this.session.status === 'resubmission_requested') {
        return 'Resubmit Details';
      }

      if (this.session.status === 'declined') {
        return 'Verification Failed Verify Again';
      }

      if (this.session && ['created', 'started'].includes(this.session.status)) {
        return 'Continue With Verification';
      }

      if (this.session && ['review', 'submitted'].includes(this.session.status)) {
        return 'Verification In Progress...';
      }

      if (this.session.status === 'approved') {
        return 'Verification Approved. Exit';
      }

      return 'Get Verified';
    },
    canNotVerify() {
      return (
        this.loading ||
        (this.session &&
          ['approved', 'review', 'submitted'].includes(this.session.status))
      );
    },
  },
  methods: {
    async fetchVerification() {
      try {
        this.loading = true;
        if (!this.session) {
          const { data } = await this.authS.fetchVerify();
          if (data.session) {
            this.session = data.session;
          }
        }
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
    async getVerificationLink() {
      try {
        this.loading = true;
        const { data } = await this.authS.verify();
        this.session = data;
        if (this.session.status === 'approved') {
          this.$router.replace({
            path: this.$route.path,
            query: { tab: 'bank', action: 'redeem' },
          });
          window.location.reload();
        } else {
          this.verifylink = this.session.url;
        }
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
