<template>
  <div v-if="!loading && balS.selectedCrypto">
    <div class="w-full flex flex-col items-center justify-center">
      <button
        class="text-secondary-text px-4 py-3 flex flex-col items-center justify-center hover:opacity-75"
        v-on:click="back">
        <svg
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          stroke-width="1.5"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
            stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
        <span>Back to Bundles</span>
      </button>
    </div>

    <div
      class="flex flex-row bg-tertiary items-center justify-center mb-4 mt-8 px-4 py-2 text-slate-300 rounded-2xl gap-4">
      <div class="flex flex-row gap-4 items-center relative cursor-pointer">
        <Popover v-slot="{ open }" class="relative max-h-full bg-tertiary">
          <PopoverButton
            :class="open ? '' : 'text-opacity-90'"
            class="min-w-96 px-4 py-3 rounded-xl font-semibold flex flex-inline items-center">
            <div class="flex flex-row space-x-2 text-slate-300 items-center">
              <img
                :src="getBalanceIcon(balS.default)"
                alt="icon"
                class="ml-2 mr-4 h-8 w-8" />
              <span v-if="balS.selectedCrypto">{{ balS.selectedCrypto.name }}</span>
              <span class="border-l-2 border-side-color w-[1px]" />
              <img
                :class="{ 'rotate-180': open }"
                alt="icon"
                class="ml-2"
                src="../../assets/svg/chevron.svg" />
            </div>
          </PopoverButton>

          <transition
            enter-active-class="transition duration-200 ease-out"
            enter-from-class="translate-y-1 opacity-0"
            enter-to-class="translate-y-0 opacity-100"
            leave-active-class="transition duration-150 ease-in"
            leave-from-class="translate-y-0 opacity-100"
            leave-to-class="translate-y-1 opacity-0">
            <PopoverPanel
              class="absolute bg-tertiary z-[500] w-[180px] px-2 mt-3 transform -translate-x-1/2 left-3/4 sm:px-0">
              <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <perfect-scrollbar class="rounded-md">
                  <div class="max-h-[450px] overflow-y-auto relative bg-secondary py-4">
                    <PopoverButton
                      v-for="item in showBuy
                        ? balS.cryptos
                        : balS.getCryptos(this.amount)"
                      :key="item.name"
                      class="flex flex-col w-full px-4 py-2 cursor-pointer transition duration-150 ease-in-out rounded hover:bg-tertiary focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      v-on:click="selectCurrency(item)">
                      <div
                        class="mls-2 flex flex-row space-x-2 justify-start items-center">
                        <img :src="item.icon" class="h-6 w-6" />
                        <p class="text-sm font-bold text-slate-500">
                          {{ item.name }}
                        </p>
                      </div>
                    </PopoverButton>
                  </div>
                </perfect-scrollbar>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>
        <div v-if="balS.networks.length > 0" class="flex flex-row items-center">
          <Popover v-slot="{ open }" class="relative max-h-full">
            <PopoverButton
              :class="open ? '' : 'text-opacity-90'"
              class="bg-seconsdary-light min-w-96 px-4 py-3 rounded-xl font-semibold flex flex-inline items-center">
              <div class="flex flex-row space-x-2 text-slate-300 items-center">
                <span class="mr-2 font-bold text-secondary-text">Network:</span>
                {{ balS.selectedNetwork.label }}
                <span class="border-l-2 border-[#32647E] w-[1px]" />
                <img alt="icon" class="ml-2" src="../../assets/svg/chevron.svg" />
              </div>
            </PopoverButton>

            <transition
              enter-active-class="transition duration-200 ease-out"
              enter-from-class="translate-y-1 opacity-0"
              enter-to-class="translate-y-0 opacity-100"
              leave-active-class="transition duration-150 ease-in"
              leave-from-class="translate-y-0 opacity-100"
              leave-to-class="translate-y-1 opacity-0">
              <PopoverPanel
                class="absolute z-[500] w-[200px] px-2 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0">
                <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <perfect-scrollbar class="rounded-md">
                    <div class="max-h-[450px] overflow-y-auto relative bg-slate-100 py-4">
                      <PopoverButton
                        v-for="item in balS.networks"
                        :key="item.name"
                        class="px-4 py-2 cursor-pointer transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                        v-on:click="selectNetwork(item)">
                        <div class="ml-2 flex flex-row">
                          <p class="text-sm font-bold text-slate-500">
                            {{ item.label }}
                          </p>
                        </div>
                      </PopoverButton>
                    </div>
                  </perfect-scrollbar>
                </div>
              </PopoverPanel>
            </transition>
          </Popover>
        </div>
      </div>
    </div>

    <div v-if="warnings.length > 0 && !showBuy" class="space-y-4 pt-4">
      <div
        v-for="(warning, i) in orderS.getWarnings(balS.default, 'deposit')"
        :key="i"
        class="text-sm text-slate-300 border-slate-300 border-2 border-dashed p-4 rounded-lg">
        <p>{{ warning }}</p>
      </div>
    </div>
    <div
      v-if="minDeposit > 0 && !showBuy"
      class="mt-4 text-sm text-slate-300 border-slate-300 border-2 border-dashed p-4 rounded-lg">
      <span>
        We are currently unable to process {{ balS.default }} deposits that are less than
        {{ formatedMinDeposit }}
        <img
          :src="getBalanceIcon(balS.default)"
          alt="Currency Icon"
          class="inline h-4 w-4" />
      </span>
    </div>
    <div v-if="!form.tag" class="flex flex-row justify-center">
      <img
        v-if="!showBuy && form.address && form.address.length > 0"
        :src="url"
        class="rounded-2xl w-48 h-48 my-8" />
    </div>
    <div class="flex flex-col justify-center">
      <label
        class="mt-4 inline mb-2 text-sm font-medium text-text-dim-color"
        for="address">
        {{ balS.default }}
        <img
          :src="getBalanceIcon(balS.default)"
          alt="Currency Icon"
          class="inline h-4 w-4" />
        Address
      </label>
      <div class="relative">
        <div class="flex absolute inset-y-0 right-2 items-center pl-3">
          <button class="text-white hover:text-slate-400" v-on:click="copyAddress">
            <c-clipboard class="h-6 w-6" />
          </button>
        </div>
        <input
          id="address"
          v-model="form.address"
          class="input-bet"
          placeholder="Address here"
          readonly
          required
          type="text" />
      </div>
    </div>
    <div v-if="form.tag" class="flex flex-col justify-center">
      <label class="mt-4 block mb-2 text-sm font-medium text-text-dim-color" for="tag"
        >Tag</label
      >
      <div class="relative">
        <div class="z-50 flex absolute inset-y-0 right-2 items-center pl-3">
          <button class="z-50 text-white hover:text-slate-400" v-on:click="copyTag">
            <c-clipboard class="h-6 w-6" />
          </button>
        </div>
        <input
          id="tag"
          v-model="form.tag"
          class="input-bet"
          placeholder="Address here"
          readonly
          required
          type="text" />
      </div>
    </div>

    <div v-if="!showBuy" class="flex flex-col justify-center">
      <label
        class="mt-4 inline mb-2 text-sm font-medium text-text-dim-color"
        for="amount">
        Transfer this amount to your {{ balS.default }}
        <img
          :src="getBalanceIcon(balS.default)"
          alt="Currency Icon"
          class="inline h-4 w-4" />
        Address
      </label>
      <div class="relative">
        <div class="flex absolute inset-y-0 right-2 items-center pl-3">
          <button class="text-white hover:text-slate-400" v-on:click="copyAmount">
            <c-clipboard class="h-6 w-6" />
          </button>
        </div>
        <input
          id="address"
          :value="depositAmount"
          class="input-bet"
          placeholder="Address here"
          readonly
          required
          type="text" />
      </div>
    </div>
    <div v-if="!showBuy" class="flex flex-col">
      <div
        class="flex flex-col items-center justify-center text-secondary-text gap-2 py-4">
        <span class="font-bold">You will receive:</span>
        <span>
          <span class="font-bold text-slate-300"
            >{{ balS.formatCrypto(this.amount * 10000, true) }} Juked Gold</span
          >
          plus free
          <span class="font-bold text-slate-300"
            >{{ balS.formatCrypto(this.amount, true) }} Juked Cash.</span
          >
        </span>
      </div>
      <div class="text-secondary-text">
        This is an estimation based on current conversion rates, it may change by the time
        you exchange currencies
      </div>
    </div>
    <iframe
      v-if="showBuy && form.address && form.address.length > 0"
      :key="buyUrl"
      :src="buyUrl"
      class="min-h-[420px] my-8 rounded-md"
      frameborder="none"
      height="100%"
      width="100%"
      >Can't load widget
    </iframe>
    <div v-if="!showBuy" class="inline text-slate-300/70 text-sm mt-8">
      <span class="inline">
        Only send <span class="inline font-bold">{{ this.orderS.selected.name }}</span> to
        this address. <span class="font-bold">{{ confirmations }}</span> confirmations
        required.
      </span>
      <span class="inline"> We do not accept BEP20 from Binance. </span>
    </div>
    <div v-else class="flex flex-col space-y-4">
      <span
        class="inline text-slate-300/70 text-sm mt-8 border-slate-300 border-2 border-dashed p-4 rounded-lg">
        Your <span class="inline font-bold">{{ this.balS.default }}</span> deposit address
        is prefilled in the input field below for your convenience. Please be sure funds
        are sent to this address, and this address only. Removing or changing the address
        below may result in funds being lost.
      </span>
      <span
        class="inline text-slate-300/70 text-sm mt-8 border-slate-300 border-2 border-dashed p-4 rounded-lg">
        <span class="inline font-bold">Disclaimer:</span> Changelly is a 3rd party partner
        that facilitates the purchasing of crypto using fiat currencies. By registering on
        their platform, you are also accepting to their terms of service and will be
        required to pass their KYC process, which runs independently to ours.
      </span>
    </div>
  </div>
  <div
    v-else
    class="flex flex-row flex-grow p-8 justify-center items-center min-h-40 rounded-md h-full">
    <svg
      class="animate-spin -ml-1 mr-3 h-10 w-10 text-white"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"></circle>
      <path
        class="opacity-75"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        fill="currentColor"></path>
    </svg>
  </div>
</template>

<script>
import { ordersStore } from '@/store/pinia/ordersStore';
import { copyToClipboard } from '@/lib/utils';
import { getBalanceIcon } from '@/lib/icons';
import { balanceStore } from '@/store/pinia/balanceStore';
import CClipboard from '../Shared/CopyToClipboard.vue';
import {
  Popover,
  PopoverButton,
  PopoverOverlay,
  PopoverPanel,
  Switch,
  SwitchGroup,
  SwitchLabel,
} from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/solid';
import { isNumber } from 'lodash';

export default {
  name: 'Deposit',
  components: {
    CClipboard,
    Popover,
    PopoverButton,
    PopoverPanel,
    ChevronDownIcon,
    Switch,
    SwitchGroup,
    SwitchLabel,
    PopoverOverlay,
  },
  props: {
    showBuy: {
      default: true,
      type: Object,
      required: false,
    },
  },
  setup() {
    const orderS = ordersStore();
    const balS = balanceStore();
    return { orderS, balS, getBalanceIcon };
  },
  data() {
    return {
      loading: false,
      amount: 5,
      form: {
        address: null,
        tag: null,
      },
    };
  },
  computed: {
    url() {
      return `https://chart.googleapis.com/chart?cht=qr&chs=300x300&chl=${this.form.address}&choe=UTF-8`;
    },
    buyUrl() {
      let amount = this.$route.query.bundle ?? 30;
      amount = isNumber(amount) ? amount : 30;
      const urls = {
        BTC: `https://widget.changelly.com?from=usd%2Ceur%2Cgbp%2Ccad%2Cjpy%2Cmxn%2Cphp%2Cbrl%2Cdkk%2Cinr%2Ckrw%2Cidr%2Ccny&to=btc&amount=${amount}&address=${this.form.address}&fromDefault=usd&toDefault=btc&merchant_id=1g6RpihdvK8ZJDPy&payment_id=&v=3&type=no-rev-share&color=25987f&headerId=1&logo=hide&buyButtonTextId=2`,
        ETH: `https://widget.changelly.com?from=usd%2Ceur%2Cgbp%2Ccad%2Cjpy%2Cmxn%2Cphp%2Cbrl%2Cdkk%2Cinr%2Ckrw%2Cidr%2Ccny&to=eth&amount=${amount}&address=${this.form.address}&fromDefault=usd&toDefault=eth&merchant_id=RqpYtDBky2oBS_P1&payment_id=&v=3&type=no-rev-share&color=25987f&headerId=1&logo=hide&buyButtonTextId=2`,
        LTC: `https://widget.changelly.com?from=usd%2Ceur%2Cgbp%2Ccad%2Cjpy%2Cmxn%2Cphp%2Cbrl%2Cdkk%2Cinr%2Ckrw%2Cidr%2Ccny&to=ltc&amount=${amount}&address=${this.form.address}&fromDefault=usd&toDefault=ltc&merchant_id=GzgRGBAhVXrIBdg-&payment_id=&v=3&type=no-rev-share&color=25987f&headerId=1&logo=hide&buyButtonTextId=2`,
        DOGE: `https://widget.changelly.com?from=usd%2Ceur%2Cgbp%2Ccad%2Cjpy%2Cmxn%2Cphp%2Cbrl%2Cdkk%2Cinr%2Ckrw%2Cidr%2Ccny&to=doge&amount=${amount}&address=${this.form.address}&fromDefault=usd&toDefault=doge&merchant_id=ojVWESAyKnFiPI3U&payment_id=&v=3&type=no-rev-share&color=25987f&headerId=1&logo=hide&buyButtonTextId=2`,
        BCH: `https://widget.changelly.com?from=usd%2Ceur%2Cgbp%2Ccad%2Cjpy%2Cmxn%2Cphp%2Cbrl%2Cdkk%2Cinr%2Ckrw%2Cidr%2Ccny&to=bch&amount=${amount}&address=${this.form.address}&fromDefault=usd&toDefault=bch&merchant_id=KMDt31v_2MUEuvsj&payment_id=&v=3&type=no-rev-share&color=25987f&headerId=1&logo=hide&buyButtonTextId=2`,
        TRX: `https://widget.changelly.com?from=usd%2Ceur%2Cgbp%2Ccad%2Cjpy%2Cmxn%2Cphp%2Cbrl%2Cdkk%2Cinr%2Ckrw%2Cidr%2Ccny&to=trx&amount=${amount}&address=${this.form.address}&fromDefault=usd&toDefault=trx&merchant_id=6gHVcDHZF_kElkcO&payment_id=&v=3&type=no-rev-share&color=25987f&headerId=1&logo=hide&buyButtonTextId=2`,
        'BNB-BSC': `https://widget.changelly.com?from=usd%2Ceur%2Cgbp%2Ccad%2Cjpy%2Cmxn%2Cphp%2Cbrl%2Cdkk%2Cinr%2Ckrw%2Cidr%2Ccny&to=bnbbsc&amount=${amount}&address=${this.form.address}&fromDefault=usd&toDefault=bnbbsc&merchant_id=VTyE2vnS0N8kkCrj&payment_id=&v=3&type=no-rev-share&color=25987f&headerId=1&logo=hide&buyButtonTextId=2`,
        USDTT: `https://widget.changelly.com?from=usd%2Ceur%2Cgbp%2Ccad%2Cjpy%2Cmxn%2Cphp%2Cbrl%2Cdkk%2Cinr%2Ckrw%2Cidr%2Ccny&to=usdtrx&amount=${amount}&address=${this.form.address}&fromDefault=usd&toDefault=usdtrx&merchant_id=5ov11ecrkbC9F0Mj&payment_id=&v=3&type=no-rev-share&color=25987f&headerId=1&logo=hide&buyButtonTextId=2`,
        USDTE: `https://widget.changelly.com?from=usd%2Ceur%2Cgbp%2Ccad%2Cjpy%2Cmxn%2Cphp%2Cbrl%2Cdkk%2Cinr%2Ckrw%2Cidr%2Ccny&to=usdt20&amount=${Math.max(
          50,
          30,
        )}&address=${
          this.form.address
        }&fromDefault=usd&toDefault=usdt20&merchant_id=vdLjIFiFCGCzkGYe&payment_id=&v=3&type=no-rev-share&color=25987f&headerId=1&logo=hide&buyButtonTextId=2`,
        XRP: `https://widget.changelly.com?from=usd%2Ceur%2Cgbp%2Ccad%2Cjpy%2Cmxn%2Cphp%2Cbrl%2Cdkk%2Cinr%2Ckrw%2Cidr%2Ccny&to=xrp&amount=${amount}&address=${this.form.address}&fromDefault=usd&toDefault=xrp&merchant_id=uOjTVzOklcZl7mdz&payment_id=${this.form.tag}&v=3&type=no-rev-share&color=25987f&headerId=1&logo=hide&buyButtonTextId=2`,
      };
      const currency = this.balS.selectedNetwork
        ? this.balS.selectedNetwork.name
        : this.balS.default;
      return urls[currency];
    },
    warnings() {
      return this.orderS.getWarnings(this.balS.default, 'deposit');
    },
    confirmations() {
      if (this.balS.selectedNetwork) {
        return this.balS.selectedNetwork.confirmations;
      }
      if (this.balS.selectedCrypto) {
        return this.balS.selectedCrypto.confirmations;
      }
      return 0;
    },
    minDeposit() {
      if (this.balS.selectedNetwork) {
        return this.balS.selectedNetwork.min_deposit;
      }
      if (this.balS.selectedCrypto) {
        return this.balS.selectedCrypto.min_deposit ?? 0;
      }
      return 0;
    },
    formatedMinDeposit() {
      const amount = this.balS.exchangeCurrency(this.minDeposit, this.balS.default);
      const symbol = this.balS.displayInFiat ? this.balS.selectedFiatSymbol : '';
      return symbol + '' + this.balS.formatCrypto(amount, false);
    },
    depositAmount() {
      const amount = this.balS.fiatToCrypto('USD', this.balS.default, this.amount);
      return this.balS.formatCrypto(amount, false);
    },
  },
  mounted() {
    this.orderS.selected = this.balS.selectedCrypto;
    //roll dice
    this.deposit();
  },
  methods: {
    copyAddress() {
      copyToClipboard(this.form.address);
    },
    copyAmount() {
      copyToClipboard(this.depositAmount);
    },
    back() {
      this.$router.replace({
        path: this.$route.path,
        query: { tab: 'bank', page: 'deposit', action: 'bundles' },
      });
    },
    copyTag() {
      copyToClipboard(this.form.tag);
    },
    selectCurrency(cur) {
      this.orderS.selected = cur;
      this.balS.default = cur.name;
    },
    async selectNetwork(currency) {
      this.balS.network = currency;
    },
    async deposit() {
      if (!this.balS.selectedCrypto) return;

      this.form = {
        address: null,
        tag: null,
      };
      try {
        this.loading = true;
        const { data } = await this.orderS.deposit(this.balS.selectedNetwork);
        this.form = {
          address: data.address,
          tag: data.tag,
        };
      } catch (e) {
        this.form = {
          address: null,
          tag: null,
        };
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    'orderS.selected.name': {
      handler(currency) {
        //roll dice
        this.deposit();
      },
      // force eager callback execution
      immediate: true,
    },
    'balS.network': {
      handler(network) {
        if (this.balS.network) {
          this.deposit();
        }
      },
      // force eager callback execution
      immediate: true,
    },
    '$route.query.bundle': {
      handler(amount) {
        this.amount = amount && !isNaN(amount) ? amount : 5;
      },
      // force eager callback execution
      immediate: true,
    },
  },
};
</script>
