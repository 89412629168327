<template>
  <div id="betby"></div>
</template>

<script>
import axios from '@/lib/myAxios';
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'activeRoom',
      'me',
      'isLoggedIn',
      'landingPage',
      'isBetByScriptLoaded'
    ]),
  },
  data() {
    return {
      bt: null,
      route: this.$router,
    };
  },
  methods: {
    isMobile() {
      return window.innerWidth < 768;
    },
    detectFocusOut() {
      var inView = false;
      window.onfocus =
        window.onblur =
          window.onpageshow =
            window.onpagehide =
              (e) => {
                if ({ focus: 1, pageshow: 1 }[e.type]) {
                  if (inView) return;
                  this.tabFocus = true;
                  inView = true;
                } else if (inView) {
                  this.tabFocus = !this.tabFocus;
                  inView = false;
                  this.bt.kill();
                }
              };
    },
    async init() {
      if (!this.isLoggedIn || this.me.jwt === undefined || this.me.jwt === '')
        await this.fetchUser();
      if (this.bt == null) this.bt = new BTRenderer().initialize(this.getSettings());
    },
    getSettings() {
      let obj = this;

      return {
        brand_id:
          this.me.game_mode === 'SC'
            ? import.meta.env.VITE_BETBY_BRAND_ID
            : import.meta.env.VITE_BETBY_BRAND_ID_GC,
        token:
          this.isLoggedIn && this.me.jwt !== undefined && this.me.jwt !== ''
            ? this.me.jwt
            : null,
        onTokenExpired: function() {
          return obj.getNewJWT();
        },
        themeName: 'juked',
        lang: 'chr',
        target: document.getElementById('betby'),
        betSlipOffsetTop: obj.isMobile() ? 60 : 80,
        betslipZIndex: 999,
        betSlipOffsetRight: obj.isMobile() ? 0 : 10,
        betSlipOffsetLeft: obj.isMobile() ? 0 : 10,
        onRegister: function() {
          console.log('onRegister');
        },
        onSessionRefresh: function() {
          obj.bt.initialize(obj.getSettings());
        },
        onRecharge: function() {
          obj.openWallet();
        },
        stickyTop: obj.getStickTop(),
        onBetSlipStateChange: function() {
          console.log('onBetSlipStateChange');
        },
        basename: '/sportsbook',
      };
    },
    getStickTop() {
      if (window.innerWidth < 480) return 60;
      return 80;
    },
    openWallet() {
      this.route.push({ path: window.location.pathname, query: { tab: 'bank' } });
    },
    async login() {
      if (!this.isLoggedIn)
        this.route.push({ path: window.location.pathname, query: { tab: 'login' } });
      else {
        await this.fetchUser();
        this.init();
      }
    },
    register() {
      this.route.push({ path: window.location.pathname, query: { tab: 'register' } });
    },
    getNewJWT() {
      return new Promise((resolve, reject) => {
        axios
          .get('getjwt')
          .then((data) => {
            if (data.data.token !== undefined && data.data.token) {
              resolve(data.data.token);
            }
          })
          .catch(() => {
            reject('some error while fetching token');
          });
      });
    },
    ...mapActions(['fetchUser']),
  },
  watch: {
    // Watch the global state variable for changes
    isBetByScriptLoaded(newValue) {
      if (newValue) {
        this.init(); // Call your init method based on condition
      }
    }
  },
  name: 'BetBy',
  setup() {
  },
  mounted()
  {
    if(this.isBetByScriptLoaded){
      this.init();
    }
  },
  unmounted() {
    if (this.bt) {
      this.bt.kill();
    }
  },
};
</script>

<style scoped></style>
