<template>
  <section class="section section-cta relative pt-[50px] pb-[15px] lg:py-[50px] z-[50]">
    <img src="/images/landing/bg.png" class="section_bg_images bgl2" />
    <div class="container big relative overflow-hidden">
      <img
        class="block lg:hidden w-full h-full absolute top-[26px] object-bottom left-0 z-[5] object-cover"
        src="/images/landing/cta/cta-mobile-bg.svg"
        alt="img" />
      <div
        class="inner-content relative bg-purple overflow-hidden rounded-[24px] pt-[100px] px-12 pb-[120px] max-lg:pt-[72px] max-lg:pb-[400px] max-lg:px-[8px]">
        <div class="top-wrapper relative">
          <div
            class="text-wrapper overflow-hidden mb-[64px] max-w-[1117px] max-lg:mb-[85px]">
            <h2
              class="adt text-black max-lg:text-center max-lg:z-[15] max-md:max-w-[343px] max-md:mx-auto">
              start your sports picks journey
            </h2>
          </div>
          <button class="cursor-default cta_animated_offer_btn btn btn-yellow py-[19px] px-[33px] max-lg:z-[10]">
            Get 200% extra!
          </button>
        </div>
        <a
          href="#"
          class="btn btn-primary text-center min-w-[370px] max-lg:z-[10] max-lg:relative max-lg:min-w-full"
          v-on:click="$router.push({ path: $route.path, query: { tab: 'register' } })"
          >join now</a
        >
        <img
          src="/images/landing/cta/coin.svg"
          class="coin abs_images hidden lg:block"
          alt="coin" />
        <img
          src="/images/landing/cta/coin2.svg"
          class="coin2 abs_images hidden lg:block"
          alt="coin" />
        <img
          src="/images/landing/cta/coin3.svg"
          class="coin3 abs_images hidden lg:block"
          alt="coin" />
        <img
          src="/images/landing/cta/coin4.svg"
          class="coin4 abs_images hidden lg:block"
          alt="coin" />
        <img
          src="/images/landing/cta/cash1.svg"
          class="cash abs_images hidden lg:block"
          alt="cash" />
        <img
          src="/images/landing/cta/cash3.svg"
          class="cash3 abs_images hidden lg:block"
          alt="cash" />
        <img
          src="/images/landing/cta/cash-svg.svg"
          class="cash-svg abs_images"
          alt="cash" />
        <img
          src="/images/landing/cta/cash-hand.svg"
          class="cash-hand abs_images"
          alt="cash" />
      </div>
      <img
        src="/images/landing/cta/cash2.svg"
        class="cash2 abs_images hidden lg:block"
        alt="cash" />
    </div>
  </section>
</template>

<script>
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export default {
  name: 'CTA',
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    const t1 = gsap.timeline({
      scrollTrigger: {
        trigger: '.section-cta',
        start: 'top top',
        end: 'bottom bottom',
        // markers: true,
      },
    });
    t1.to('.cash-hand', { rotate: '0deg', bottom: '110px',right: '340px', duration: 0.5 });
    t1.from('.cta_animated_offer_btn', { rotate: '-5deg', duration: 0.5 });
  },
};
</script>