<template>
   <section class="section section-pick-your-sports relative py-[100px] max-lg:py-[72px]" id="games">
    <img src="/images/landing/bg.png" class="section_bg_images bgl1">
    <img src="/images/landing/bg.png" class="section_bg_images bgl2">
        <div class="container">
            <div class="flex mb-10 max-lg:flex-col">
                <div class="w-2/3 max-lg:w-full max-lg:text-center overflow-hidden">
                    <h2 class="heading2 text-white" ref="heading">PICKS ON <br/> YOUR FAVORITE SPORT</h2>
                </div>
                <div class="w-1/3 flex justify-end hidden lg:block">
                    <div class="navigation-button flex items-start justify-end gap-7" ref="nav_btn">
                        <div class="no_selection arrow left sports-slider-prev">
                            <svg width="48" height="31" viewBox="0 0 48 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M47.8381 19.2404C36.8334 18.3409 25.6623 15.8533 13.4749 18.2004C17.9748 21.9389 21.7262 25.0449 25.9303 28.5445C23.0197 31.5802 20.3864 31.5661 17.9932 29.7812C12.6618 25.846 7.35813 21.7702 2.21152 17.315C-0.985482 14.5041 -0.662072 10.864 2.8306 8.57315C4.7667 7.44558 6.74062 6.47451 8.74412 5.66392C11.9966 4.13199 15.2582 2.61408 18.5199 1.15243C19.5732 0.688632 20.6636 0.449739 22.0495 -1.13433e-06C22.0495 4.9612 19.5548 5.28442 17.9563 6.68986C16.3578 8.0953 14.3527 9.31805 12.5325 10.6111L12.708 12.0165C18.714 12.0165 24.7199 11.7073 30.7166 12.1149C35.7431 12.4662 40.7418 13.6327 45.7406 14.5182C47.1174 14.7993 48.4756 15.3193 47.8381 19.2404Z" fill="#5FF487"/>
                            </svg>
                        </div>
                        <div class="no_selection arrow right sports-slider-next">
                            <svg width="48" height="32" viewBox="0 0 48 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M-7.58606e-07 17.3549C0.823696 12.5185 2.57178 12.7102 4.12765 12.666C11.1748 12.5185 18.222 12.4448 25.2692 12.3711C28.0149 12.3711 30.8338 12.3711 33.8906 12.3711C33.314 9.61375 31.6758 9.42206 30.5866 8.22772C29.4975 7.03337 28.0149 5.9275 26.9258 4.453C26.7149 4.03087 26.5734 3.53002 26.5142 2.99663C26.455 2.46324 26.4801 1.91444 26.5871 1.40077C26.7946 0.963673 27.062 0.609797 27.3684 0.366797C27.6748 0.123797 28.012 -0.0017163 28.3535 1.68487e-05C29.2235 0.195324 30.0641 0.645049 30.8337 1.32706C35.7942 4.89535 40.7364 8.52261 45.6878 12.0909C46.9508 13.0051 48.3053 14.0962 47.9392 16.6029C47.61 18.3364 46.9136 19.8288 45.9715 20.82C44.6874 21.9633 43.3313 22.8827 41.9262 23.5625C38.2653 25.848 34.5221 28.0893 30.8337 30.3895C29.2138 31.3921 27.2553 33.5154 26.1387 30.2568C24.8757 26.5853 27.182 25.0223 28.7379 23.3414C29.4609 22.5746 30.2206 21.8669 31.6758 20.3924C20.8213 17.7972 10.5159 19.5371 -7.58606e-07 17.3549Z" fill="#5FF487"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div class="slider relative max-lg:mx-[-6px]">
                <div class="swiper-upper mb-5" ref="slides">
                    <swiper
                        class="sports-slider max-w-full"
                        ref="swiperUpper"
                        :modules="modules"
                        :slides-per-view="5"
                        :loop="true"
                        :centered-slides="true"
                        :controller="{ control: controlledSwiper }"
                        :navigation="{
                            enabled: true,
                            nextEl: '.sports-slider-next',
                            prevEl: '.sports-slider-prev',
                        }"
                        :breakpoints="{
                            350: {
                                slidesPerView: 1.7,
                            },
                            600:{
                                slidesPerView: 3,
                            },
                            1024: {
                                slidesPerView: 5,
                            }
                        }"
                        @slideChange="onSlideChange"
                        
                    >
                        <swiper-slide class="sports-slide">
                            <div class="image-wrapper">
                                <img src="/images/landing/pick-your-sports/img1.png" alt="" />
                            </div>
                        </swiper-slide>
                        <swiper-slide class="sports-slide">
                            <div class="image-wrapper">
                                <img src="/images/landing/pick-your-sports/img2.png" alt="" />
                            </div>
                        </swiper-slide>
                        <swiper-slide class="sports-slide">
                            <div class="image-wrapper">
                                <img src="/images/landing/pick-your-sports/img3.png" alt="" />
                            </div>
                        </swiper-slide>
                        <swiper-slide class="sports-slide">
                            <div class="image-wrapper">
                                <img src="/images/landing/pick-your-sports/img4.png" alt="" />
                            </div>
                        </swiper-slide>
                        <swiper-slide class="sports-slide">
                            <div class="image-wrapper">
                                <img src="/images/landing/pick-your-sports/img5.png" alt="" />
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
                <div class="swiper-down relative">
                    <swiper
                        class="sports-bottom-slider max-w-full absolute bottom-0 left-0 swiper-no-swiping"
                        :modules="modules2"
                        ref="swiperDown"
                        :slides-per-view="1"
                        :loop="true"
                        effect="fade"
                        :loopAdditionalSlides=30
                        :centered-slides="true"
                        :allow-touch-move=false
                        @swiper="setControlledSwiper"
                    >
                        <swiper-slide class="sports-down-slide ">
                            <div class="content-wrapper">
                                <button class="cursor-default btn btn-purple body1">OVER/UNDER</button>
                                <button class="cursor-default btn btn-yellow rightside body1">COMBAT & ESPORTS</button>
                            </div>
                        </swiper-slide>
                        <swiper-slide class="sports-down-slide">
                            <div class="content-wrapper">
                                <button class="cursor-default btn btn-purple body1">LIVE PICKS</button>
                                <button class="cursor-default btn btn-yellow rightside body1">TONS OF LEAGUES</button>
                            </div>
                        </swiper-slide>
                        <swiper-slide class="sports-down-slide">
                            <div class="content-wrapper">
                                <button class="cursor-default btn btn-purple body1">GAME TOTALS</button>
                                <button class="cursor-default btn btn-yellow rightside body1">CHOOSE THE MVP</button>
                            </div>
                        </swiper-slide>
                        <swiper-slide class="sports-down-slide">
                            <div class="content-wrapper">
                                <button class="cursor-default btn btn-purple body1"> 3-WAY MONEYLINE</button>
                                <button class="cursor-default btn btn-yellow rightside body1">HANDICAPS & SPREADS</button>
                            </div>
                        </swiper-slide>
                        <swiper-slide class="sports-down-slide">
                            <div class="content-wrapper">
                                <a class="cursor-default btn btn-purple body1">HALFTIME & FULL-TIME PICKS</a>
                                <a class="cursor-default btn btn-yellow rightside body1">SUPER BOWL WINNER</a>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
            <div class="w-full  flex justify-center mt-[90px] lg:hidden lg:mt-[45px]">
                   <div class="navigation-button flex items-center gap-7" >
                       <div class="no_selection arrow left sports-slider-prev">
                           <svg width="48" height="31" viewBox="0 0 48 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                               <path d="M47.8381 19.2404C36.8334 18.3409 25.6623 15.8533 13.4749 18.2004C17.9748 21.9389 21.7262 25.0449 25.9303 28.5445C23.0197 31.5802 20.3864 31.5661 17.9932 29.7812C12.6618 25.846 7.35813 21.7702 2.21152 17.315C-0.985482 14.5041 -0.662072 10.864 2.8306 8.57315C4.7667 7.44558 6.74062 6.47451 8.74412 5.66392C11.9966 4.13199 15.2582 2.61408 18.5199 1.15243C19.5732 0.688632 20.6636 0.449739 22.0495 -1.13433e-06C22.0495 4.9612 19.5548 5.28442 17.9563 6.68986C16.3578 8.0953 14.3527 9.31805 12.5325 10.6111L12.708 12.0165C18.714 12.0165 24.7199 11.7073 30.7166 12.1149C35.7431 12.4662 40.7418 13.6327 45.7406 14.5182C47.1174 14.7993 48.4756 15.3193 47.8381 19.2404Z" fill="#5FF487"/>
                           </svg>
                       </div>
                       <div class="paginations body2 text-white">{{formatNumber(currentSlide)}}/{{ formatNumber(totalSlides) }}</div>
                       <div class="no_selection arrow right sports-slider-next">
                           <svg width="48" height="32" viewBox="0 0 48 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                               <path d="M-7.58606e-07 17.3549C0.823696 12.5185 2.57178 12.7102 4.12765 12.666C11.1748 12.5185 18.222 12.4448 25.2692 12.3711C28.0149 12.3711 30.8338 12.3711 33.8906 12.3711C33.314 9.61375 31.6758 9.42206 30.5866 8.22772C29.4975 7.03337 28.0149 5.9275 26.9258 4.453C26.7149 4.03087 26.5734 3.53002 26.5142 2.99663C26.455 2.46324 26.4801 1.91444 26.5871 1.40077C26.7946 0.963673 27.062 0.609797 27.3684 0.366797C27.6748 0.123797 28.012 -0.0017163 28.3535 1.68487e-05C29.2235 0.195324 30.0641 0.645049 30.8337 1.32706C35.7942 4.89535 40.7364 8.52261 45.6878 12.0909C46.9508 13.0051 48.3053 14.0962 47.9392 16.6029C47.61 18.3364 46.9136 19.8288 45.9715 20.82C44.6874 21.9633 43.3313 22.8827 41.9262 23.5625C38.2653 25.848 34.5221 28.0893 30.8337 30.3895C29.2138 31.3921 27.2553 33.5154 26.1387 30.2568C24.8757 26.5853 27.182 25.0223 28.7379 23.3414C29.4609 22.5746 30.2206 21.8669 31.6758 20.3924C20.8213 17.7972 10.5159 19.5371 -7.58606e-07 17.3549Z" fill="#5FF487"/>
                           </svg>
                       </div>
                   </div>
               </div>
        </div>
   </section>
</template>
  
<script>
import { ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import { Navigation , Controller , EffectFade } from 'swiper';

import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export default {
    name: 'PickYourSports',
    components: {
        Swiper,
        SwiperSlide,
    },
    data: () => ({
        currentSlide: 1,
        totalSlides:5,
    }),
    setup() {
        const controlledSwiper = ref(null);
        const setControlledSwiper = (swiper) => {
            controlledSwiper.value = swiper;
        };
        
        return {
            controlledSwiper,
            setControlledSwiper,
            modules: [Navigation,Controller],
            modules2: [Navigation,Controller,EffectFade],
        };
        
    },
    mounted() {
        gsap.registerPlugin(ScrollTrigger);
        const t1 = gsap.timeline({
                scrollTrigger: {
                    trigger: ".section-pick-your-sports", 
                    start: "top 30%", 
                    end: "bottom bottom", 
                    // markers:true
                },
        });
        t1.from(this.$refs.heading, { y: 100,opacity:0, duration: 0.5 });
        t1.from(this.$refs.nav_btn, { y: 100,opacity:0, duration: 0.5 },"<");
        t1.from('.section-pick-your-sports .image-wrapper', { x: -150, duration: 0.8 },"<");
        t1.from('.section-pick-your-sports .image-wrapper img', { left: 150, duration: 0.8 },"<");
        t1.from('.section-pick-your-sports .sports-bottom-slider', { y: 50,opacity:0, duration: 0.8 },"<");
        // gsap.to(".events", { scrollTrigger: ".events", x: 100, duration: 3 });
    },
    methods: {
        onSlideChange(swiper) {
            this.currentSlide = swiper.realIndex + 1; // Update the current slide index
        },
        formatNumber(num) {
            return num.toString().padStart(2, '0'); // Change 2 to the desired number of digits
        },
    },
};
</script>
  