<template>
  <div class="mx-auto w-full rounded-xl p-1 bg-secondary">
    <Disclosure v-slot="{ open }">
      <DisclosureButton
        class="flex w-full justify-between rounded-lg bg-secondary-light px-4 py-2 text-left text-sm font-medium hover:bg-secondary-light/60 focus:outline-none focus-visible:ring focus-visible:ring-white-500 focus-visible:ring-opacity-75">
        <span
          >Level 1
          <span
            v-if="level1State.status !== '' && level1State.status === 'Completed'"
            class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap font-bold bg-green-500 text-white rounded"
            >{{ level1State.status }}</span
          >
          <span
            v-if="level1State.status !== '' && level1State.status === 'Pending'"
            class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap font-bold bg-orange-500 text-white rounded"
            >{{ level1State.status }}</span
          >
          <span
            v-if="level1State.status !== '' && level1State.status === 'Rejected'"
            class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap font-bold bg-red-500 text-white rounded"
            >{{ level1State.status }}</span
          >
        </span>

        <ChevronUpIcon
          :class="open ? 'rotate-180 transform' : ''"
          class="h-5 w-5 text-white-500" />
      </DisclosureButton>
      <DisclosurePanel class="px-4 pt-4 pb-2 text-sm bg-secondary rounded-lg">
        <div
          v-if="level1State.reason !== null && level1State.reason !== ''"
          class="flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
          role="alert">
          <svg
            class="inline flex-shrink-0 mr-3 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clip-rule="evenodd"></path>
          </svg>
          <div><span class="font-medium">Reason!</span>{{ level1State.reason }}</div>
        </div>
        <span class="text-lg">Verification </span>
        <p>
          <span class="text-md">
            In the event your details change, level one verification can be updated at a
            later date
          </span>
        </p>
        <form class="w-full" @submit.stop.prevent="() => submitInfo()">
          <label
            for="first-name"
            class="block mb-2 mt-4 text-sm font-medium text-text-dim-color"
            >FirstName</label
          >
          <div class="relative">
            <input
              v-model="form.firstname"
              required
              type="text"
              id="first-name"
              class="input-bet"
              placeholder="First name" />
          </div>

          <label
            for="last-name"
            class="block mb-2 mt-4 text-sm font-medium text-text-dim-color"
            >Lastname</label
          >
          <div class="relative">
            <input
              v-model="form.lastname"
              required
              type="text"
              id="last-name"
              class="input-bet"
              placeholder="Last name" />
          </div>

          <label for="dob" class="mt-4 block mb-2 text-sm font-medium text-text-dim-color"
            >Date of Birth</label
          >
          <Datepicker
            class="hidden"
            required
            v-model="form.dob"
            :flow="['month', 'year', 'calendar']"
            :format="formatDate"
            :state="dobError ? false : null"
            placeholder="Your Date of Birth"
            :inputClassName="'!placeholder:text-text-dim-color ! !rounded-xl !border !py-3 !border-line-color-bright !text-slate-300 !text-sm !rounded-xl !min-h-10 !font-semibold focus:ring-green-600 focus:border-green-600 block'" />

          <div class="flex justify-between gap-2">
            <input
              v-model="form.day"
              required
              type="number"
              id="dob-day"
              maxlength="2"
              size="2"
              min="1"
              max="31"
              class="input-bet"
              placeholder="Day" />
            <input
              v-model="form.month"
              required
              type="number"
              id="dob-month"
              maxlength="2"
              size="2"
              min="1"
              max="12"
              class="input-bet"
              placeholder="Month" />
            <input
              v-model="form.year"
              required
              type="number"
              id="dob-year"
              maxlength="4"
              size="4"
              min="1900"
              max="2050"
              class="input-bet"
              placeholder="Year" />
          </div>
          <label
            for="city"
            class="block mb-2 mt-4 text-sm font-medium text-text-dim-color"
            >City</label
          >
          <input
            v-model="form.city"
            required
            type="text"
            id="city"
            class="input-bet"
            placeholder="City" />

          <label
            for="city"
            class="block mb-2 mt-4 text-sm font-medium text-text-dim-color"
            >Residential Address</label
          >
          <input
            v-model="form.residential_address"
            required
            type="text"
            id="residential-address"
            class="input-bet"
            placeholder="Residential Address" />

          <label
            for="country"
            class="block mb-2 mt-4 text-sm font-medium text-text-dim-color"
            >Country</label
          >
          <div class="relative">
            <input
              v-model="form.country"
              required
              type="text"
              id="country"
              class="input-bet"
              placeholder="Country" />
          </div>

          <label
            for="occupation"
            class="block mb-2 mt-4 text-sm font-medium text-text-dim-color"
            >Occupation</label
          >
          <div class="relative">
            <input
              v-model="form.occupation"
              required
              type="text"
              id="city"
              class="input-bet"
              placeholder="Occupation" />
          </div>

          <label
            for="postal-code"
            class="block mb-2 mt-4 text-sm font-medium text-text-dim-color"
            >Postal Code</label
          >
          <div class="relative">
            <input
              v-model="form.postal_code"
              required
              type="text"
              id="postal-code"
              class="input-bet"
              placeholder="Postal Code" />
          </div>
          <button :disabled="level1" class="btn-bet">Submit</button>
        </form>
      </DisclosurePanel>
    </Disclosure>
    <Disclosure as="div" class="mt-2" v-slot="{ open }">
      <DisclosureButton
        class="flex w-full justify-between rounded-lg bg-secondary-light px-4 py-2 text-left text-sm font-medium hover:bg-secondary-light-200 focus:outline-none focus-visible:ring focus-visible:ring-white-500 focus-visible:ring-opacity-75">
        <span
          >Level 2
          <span
            v-if="level2State.status !== '' && level2State.status === 'Completed'"
            class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap font-bold bg-green-500 text-white rounded"
            >{{ level2State.status }}</span
          >
          <span
            v-if="level2State.status !== '' && level2State.status === 'Pending'"
            class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap font-bold bg-orange-500 text-white rounded"
            >{{ level2State.status }}</span
          >
          <span
            v-if="level2State.status !== '' && level2State.status === 'Rejected'"
            class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap font-bold bg-red-500 text-white rounded"
            >{{ level2State.status }}</span
          >
        </span>
        <ChevronUpIcon
          :class="open ? 'rotate-180 transform' : ''"
          class="h-5 w-5 text-white-500" />
      </DisclosureButton>
      <DisclosurePanel class="px-4 pt-4 pb-2 text-sm bg-secondary rounded-lg">
        <div
          v-if="level2State.reason !== null && level2State.reason !== ''"
          class="flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
          role="alert">
          <svg
            class="inline flex-shrink-0 mr-3 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clip-rule="evenodd"></path>
          </svg>
          <div><span class="font-medium">Reason!</span>{{ level2State.reason }}</div>
        </div>
        <span class="text-lg text-white">Verification</span>
        <p
          class="weight-normal lineHeight-base align-left size-medium variant-warn with-icon-space svelte-v8zorn pt-4"
          style="color: #f89511">
          Please complete level one verification first.
        </p>
        <div>
          <span class="text-md">
            Please upload your proof of identity. All documents must be laying on a flat
            surface with all 4 corners inside the frame. All information should be clear
            and identifiable.
          </span>
          <div class="relative flex py-2 items-center">
            <div class="flex-grow border-t border-line-color-bright"></div>
          </div>
          <label
            class="block mb-2 text-sm font-medium text-gray-500 dark:text-gray-300 pt-2"
            for="file_input"
            >Proof of Identity (Front)*</label
          >
          <input
            class="input-with-file"
            id="file_input_front"
            ref="file_input_front"
            @change="handleFrontFileUpload($event)"
            type="file" />

          <label
            class="block mb-2 text-sm font-medium text-gray-500 dark:text-gray-300 pt-4"
            for="file_input"
            >Proof of Identity (Back)*</label
          >
          <input
            class="input-with-file"
            id="file_input_back"
            ref="file_input_back"
            @change="handleBackFileUpload($event)"
            type="file" />
        </div>

        <br />

        <p class="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">
          <i>Following file types are accepted .png, .jpg, .pdf</i>
        </p>
        <button v-on:click="submitDocuments" :disabled="level2" class="btn-bet">
          Submit Documents
        </button>
      </DisclosurePanel>
    </Disclosure>
    <Disclosure as="div" class="mt-2" v-slot="{ open }">
      <DisclosureButton
        class="flex w-full justify-between rounded-lg bg-secondary-light px-4 py-2 text-left text-sm font-medium hover:bg-secondary-light-200 focus:outline-none focus-visible:ring focus-visible:ring-white-500 focus-visible:ring-opacity-75">
        <span
          >Level 3
          <span
            v-if="level3State.status !== '' && level3State.status === 'Completed'"
            class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap font-bold bg-green-500 text-white rounded"
            >{{ level3State.status }}</span
          >
          <span
            v-if="level3State.status !== '' && level3State.status === 'Pending'"
            class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap font-bold bg-orange-500 text-white rounded"
            >{{ level3State.status }}</span
          >
          <span
            v-if="level3State.status !== '' && level3State.status === 'Rejected'"
            class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap font-bold bg-red-500 text-white rounded"
            >{{ level3State.status }}</span
          >
        </span>
        <ChevronUpIcon
          :class="open ? 'rotate-180 transform' : ''"
          class="h-5 w-5 text-white-500" />
      </DisclosureButton>
      <DisclosurePanel class="px-4 pt-4 pb-2 text-sm bg-secondary rounded-lg">
        <div
          v-if="level3State.reason !== null && level3State.reason !== ''"
          class="flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
          role="alert">
          <svg
            class="inline flex-shrink-0 mr-3 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clip-rule="evenodd"></path>
          </svg>
          <div><span class="font-medium">Reason!</span>{{ level3State.reason }}</div>
        </div>
        <span class="text-lg text-white">Verification</span>
        <p
          class="weight-normal lineHeight-base align-left size-medium variant-warn with-icon-space svelte-v8zorn pt-4"
          style="color: #f89511">
          Please complete level two verification first.
        </p>
        <div>
          <span class="text-md">
            Please upload supporting documentation for your Proof of Address. Document
            laying on a flat surface must show all 4 corners and all information should be
            clear and identifiable.
          </span>
          <div class="relative flex py-2 items-center">
            <div class="flex-grow border-t border-line-color-bright"></div>
          </div>
          <label
            class="block mb-2 text-sm font-medium text-gray-500 dark:text-gray-300 pt-2"
            for="file_input_address"
            >Proof of Address*</label
          >
          <input
            class="input-with-file"
            id="file_input_address"
            @change="handleProofFileUpload($event)"
            type="file" />
        </div>

        <br />

        <p class="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">
          <i>Following file types are accepted .png, .jpg, .pdf</i>
        </p>
        <button :disabled="level3" v-on:click="submitAddressDocuments" class="btn-bet">
          Submit Documents
        </button>
      </DisclosurePanel>
    </Disclosure>
    <Disclosure as="div" class="mt-2" v-slot="{ open }">
      <DisclosureButton
        class="flex w-full justify-between rounded-lg bg-secondary-light px-4 py-2 text-left text-sm font-medium hover:bg-secondary-light-200 focus:outline-none focus-visible:ring focus-visible:ring-white-500 focus-visible:ring-opacity-75">
        <span
          >Level 4
          <span
            v-if="level4State.status !== '' && level4State.status === 'Completed'"
            class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap font-bold bg-green-500 text-white rounded"
            >{{ level4State.status }}</span
          >
          <span
            v-if="level4State.status !== '' && level4State.status === 'Pending'"
            class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap font-bold bg-orange-500 text-white rounded"
            >{{ level4State.status }}</span
          >
          <span
            v-if="level4State.status !== '' && level4State.status === 'Rejected'"
            class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap font-bold bg-red-500 text-white rounded"
            >{{ level4State.status }}</span
          >
        </span>
        <ChevronUpIcon
          :class="open ? 'rotate-180 transform' : ''"
          class="h-5 w-5 text-white-500" />
      </DisclosureButton>
      <DisclosurePanel class="px-4 pt-4 pb-2 text-sm bg-secondary rounded-lg">
        <div
          v-if="level4State.reason !== null && level4State.reason !== ''"
          class="flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
          role="alert">
          <svg
            class="inline flex-shrink-0 mr-3 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clip-rule="evenodd"></path>
          </svg>
          <div><span class="font-medium">Reason!</span>{{ level4State.reason }}</div>
        </div>
        <span class="text-lg text-white">Verification</span>
        <p
          class="weight-normal lineHeight-base align-left size-medium variant-warn with-icon-space svelte-v8zorn pt-4"
          style="color: #f89511">
          Please complete level three verification first.
        </p>
        <div>
          <span class="text-md">
            Please upload supporting documentation for your Source of Funds. Document
            laying on a flat surface must show all 4 corners and all information should be
            clear and identifiable.
          </span>
          <div class="relative flex py-2 items-center">
            <div class="flex-grow border-t border-line-color-bright"></div>
          </div>
          <label
            class="block mb-2 text-sm font-medium text-gray-500 dark:text-gray-300 pt-2"
            for="file_input_fundssource"
            >Source of Funds*</label
          >
          <input
            class="input-with-file"
            id="file_input_fundssource"
            @change="handleFundssourceFileUpload($event)"
            type="file" />
        </div>

        <br />

        <p class="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">
          <i>Following file types are accepted .png, .jpg, .pdf</i>
        </p>
        <button
          :disabled="level4"
          v-on:click="submitFundsSourceDocuments"
          class="btn-bet">
          Submit Documents
        </button>
      </DisclosurePanel>
    </Disclosure>
  </div>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { ChevronUpIcon } from '@heroicons/vue/solid';
</script>

<script>
import { mapActions, mapGetters } from 'vuex';
import { doRecaptcha } from '@/lib/recaptcha';
import dateFormat from 'dateformat';
import axios from '../../lib/axiosConfig';

export default {
  name: 'Verify',
  components: {},
  data() {
    return {
      kycs: [],
      form: {
        firstname: '',
        lastname: '',
        city: '',
        country: '',
        occupation: '',
        code: '',
        date: null,
        day: null,
        month: null,
        year: null,
        postal_code: '',
        residential_address: '',
      },
      widget: false,
      dobError: null,
      level1: false,
      level2: true,
      level3: true,
      level4: true,
      level1State: {
        status: '',
        reason: '',
      },
      level2State: {
        status: '',
        reason: '',
      },
      level3State: {
        status: '',
        reason: '',
      },
      level4State: {
        status: '',
        reason: '',
      },

      //front
      frontDocument: '',
      backDocument: '',
      proofDocument: '',
      fundssourcedocument: '',
    };
  },
  async created() {
    try {

     
    this.fetchKyc().then((element) => {
      this.kycs = element.data;
      this.kycs.forEach((element) => {
        switch (element.level) {
          case 1:
            this.level1 = false;
            this.level2 = false;
            //get the details
            this.form.firstname = element.user.firstname;
            this.form.lastname = element.user.lastname;
            this.form.city = element.user.city;
            this.form.postal_code = element.user.postal_code;
            this.form.residential_address = element.user.residential_address;
            this.form.occupation = element.user.occupation;
            this.form.country = element.user.country;

            this.level1State.status = element.status;
            this.level1State.reason = element.reason;

            //build date
            const d = new Date(element.user.dob);
            this.form.year = d.getFullYear();
            this.form.month = d.getMonth();
            this.form.day = d.getDay();
            this.form.date = element.user.dob;

            break;
          case 2:
            this.level2State.status = element.status;
            this.level2State.reason = element.reason;

            this.level1 = false;
            this.level2 = false;
            this.level3 = false;
            //get the details
            break;
          case 3:
            this.level3State.status = element.status;
            this.level3State.reason = element.reason;
            this.level1 = false;
            this.level2 = false;
            this.level3 = false;
            this.level4 = false;
            //get the details
            break;
          case 4:
            this.level4State.status = element.status;
            this.level4State.reason = element.reason;
            this.level1 = false;
            this.level2 = false;
            this.level3 = false;
            this.level4 = false;
            //get details
            break;
        }
      });
    });
    } catch(error) {
      console.log("error while fetching kyc");
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
  methods: {
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleString(undefined, options);
    },
    handleFrontFileUpload(event) {
      this.frontDocument = event.target.files[0];
    },
    handleBackFileUpload(event) {
      this.backDocument = event.target.files[0];
    },
    handleProofFileUpload(event) {
      this.proofDocument = event.target.files[0];
    },
    handleFundssourceFileUpload(event) {
      this.fundssourcedocument = event.target.files[0];
    },
    async submitDocuments() {
      if (this.frontDocument === null || this.backDocument === '') {
        this.$toast.error('No File Uploaded!');
        return;
      }
      let formData = new FormData();
      formData.append('level', 2);
      formData.append('front', this.frontDocument);
      formData.append('back', this.backDocument);
      try {
        await axios.post('/kyc/verification', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        this.$toast.success(
          'Verification level Two updated, <br>Please allow some time for the changes to take place.',
        );
      } catch (e) {
        this.$toast.error('Sorry we cant verify you at the moment! <br>' + ((typeof e.data.error != 'undefined')?e.data.error:''));
      } finally {
      }
    },
    async submitAddressDocuments() {
      if (this.proofDocument === null || this.proofDocument === '') {
        this.$toast.error('No File Uploaded!');
        return;
      }
      let formData = new FormData();
      formData.append('level', 3);
      formData.append('proofofaddress', this.proofDocument);
      try {
        await axios.post('/kyc/verification', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        this.$toast.success(
          'Verification level Three updated, <br>Please allow some time for the changes to take place.',
        );
      } catch (e) {
        this.$toast.error('Sorry we cant verify you at the moment! <br>' + ((typeof e.data.error != 'undefined')?e.data.error:''));
      } finally {
      }
    },
    async submitFundsSourceDocuments() {
      if (this.fundssourcedocument === null || this.fundssourcedocument === '') {
        this.$toast.error('No File Uploaded!');
        return;
      }
      let formData = new FormData();
      formData.append('level', 4);
      formData.append('sourceoffunds', this.fundssourcedocument);
      try {
        await axios.post('/kyc/verification', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        this.$toast.success(
          'Verification level Four updated, <br>Please allow some time for the changes to take place.',
        );
      } catch (e) {
        this.$toast.error('Sorry we cant verify you at the moment! <br>' + ((typeof e.data.error != 'undefined')?e.data.error:'') );
      } finally {
      }
    },
    validateDoB() {
      const date = new Date(this.form.year, this.form.month, this.form.day);
      if (!date) return 'This field Required.';
      const age = this.calculateAge(date);
      if (age < 18) {
        return 'You must be at-least 18 years old to proceed';
      }
      return null;
    },
    calculateAge(birthday) {
      // birthday is a date
      let ageDifMs = Date.now() - birthday;
      let ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    async fetchKyc() {
      return await axios.get('/kyc/verification');
    },
    async submitInfo() {
      this.dobError = this.validateDoB();
      if (this.dobError) {
        this.$toast.error('You must be at-least 18 years old to proceed!');
        return;
      }
      const form = { ...this.form };
      form.dob = dateFormat(
        new Date(this.form.year, this.form.month, this.form.day),
        'yyyy-mm-dd',
      );
      const payload = {
        level: 1,
        details: form,
      };

      try {
        await axios.post('/kyc/verification', payload);
        this.$toast.success(
          'Verification level one updated, <br>Please allow some time for the changes to take place.',
        );
      } catch (e) {
        const { data } = e;
        this.$toast.error('Sorry we cant verify you at the moment! <br>' + data.message);
      } finally {
      }
    },
  },
};
</script>
