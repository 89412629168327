import { defineStore } from 'pinia';
import axios from '@/lib/myAxios';
import { timeParse } from 'd3-time-format';
import { doRecaptcha } from '@/lib/recaptcha';
// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const dailyBonusStore = defineStore('dailybonus', {
  state: () => {
    return {
      selected: {
        name: null,
        icon: null,
      },
    };
  },
  getters: {},
  actions: {
    async claim() {
      const recaptchaToken = await doRecaptcha('daily_bonus');
      return await axios.post('/hourly-bonus', { fp: window.fp, token: recaptchaToken });
    },
    async lastClaimedBonuses() {
      return await axios.get('/hourly-bonus?fp=' + window.fp);
    },

    async lastClaimedGcTopup() {
      return await axios.get('/getlastgctopup');
    },
    async claimGCtopup() {
      const recaptchaToken = await doRecaptcha('daily_bonus');
      return await axios.post('/claimgctopup', { token: recaptchaToken });
    },
  },
});
