<template>
  <div class="section section-how-it-work relative z-0" id="howItWorks">
    <img src="/images/landing/bg.png" class="section_bg_images bgl1" />
    <img src="/images/landing/bg.png" class="section_bg_images bgl2" />
    <div class="container m-auto max-w-[1242px]">
      <h2 class="heading2 section-title">HOW IT WORKS</h2>

      <div
        class="cards-container flex gap-[15px] flex-row max-lg:flex-col max-lg:mx-[30px]">
        <div
          class="card-item card-item-ani-1 style-1 grow right-shadow z-10 max-lg:max-w[305px] max-lg:m-auto max-lg:mb-[30px]">
          <div class="card-inner">
            <div class="max-w-[330px] m-auto">
              <h3 class="heading3 mt-[20px] mb-[50px] text-center">01</h3>
              <h3 class="heading3 mb-[12px] text-center">SIGN UP</h3>
              <p class="body3 mb-[24px] text-center">
                No download required! Simply verify your identity and play for FREE!
              </p>
            </div>
            <img
              src="/images/landing/how-it-work/work-1.svg"
              alt="signup"
              class="max-lg:m-auto" />
          </div>
        </div>
        <div
          class="card-item card-item-ani-2 style-1 grow left-shadow z-20 max-lg:max-w[305px] max-lg:m-auto max-lg:mb-[30px]">
          <div class="card-inner">
            <div class="max-w-[330px] m-auto">
              <h3 class="heading3 mt-[20px] mb-[50px] text-center">02</h3>
              <h3 class="heading3 mb-[12px] text-center">FREE FOREVER</h3>
              <p class="body3 mb-[24px] text-center">
                Get JG and JC for FREE via Hourly Bonuses, Mail a Request, Rakeback,
                Races, etc
              </p>
            </div>
            <img
              src="/images/landing/how-it-work/work-2.svg"
              alt="signup"
              class="max-lg:m-auto" />
          </div>
        </div>
        <div
          class="card-item card-item-ani-3 style-1 grow right-shadow z-30 max-lg:max-w[305px] max-lg:m-auto max-lg:mb-[30px]">
          <div class="card-inner">
            <div class="max-w-[330px] m-auto">
              <h3 class="heading3 mt-[20px] mb-[50px] text-center">03</h3>
              <h3 class="heading3 mb-[12px] text-center">first picks</h3>
              <p class="body3 mb-[24px] text-center">Place FREE Picks with JC or JG!</p>
            </div>
            <img
              src="/images/landing/how-it-work/work-3.svg"
              alt="signup"
              class="max-lg:m-auto" />
          </div>
        </div>
      </div>
      <div class="text-center mt-[40px] lg:mt-[60px]">
        <a
          href="#"
          class="btn btn-primary max-w-[370px] w-full"
          v-on:click="$router.push({ path: $route.path, query: { tab: 'register' } })"
          >Start playing</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export default {
  name: 'HowItWorks',
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const mm = gsap.matchMedia(); // Use matchMedia for responsive animation

    mm.add("(min-width: 1024px)", () => {
      // Animation for screens wider than 1024px
      const t1 = gsap.timeline({
        scrollTrigger: {
          trigger: '.section-how-it-work',
          start: 'top 10%',
          end: 'bottom bottom',
          // markers: true,
        },
      });
      t1.from('.card-item-ani-1', { x: '100%', duration: 0.5 });
      t1.from('.card-item-ani-3', { x: '-100%', duration: 0.5 });
    });

    mm.add("(max-width: 1023px)", () => {
      // Animation for screens smaller than 1024px
      const t1 = gsap.timeline({
        scrollTrigger: {
          trigger: '.section-how-it-work',
          start: 'top 10%',
          end: 'bottom bottom',
          stagger: true
          // markers: true,
        },
      });
      t1.from('.card-item-ani-1', { y: '120px', duration: 0.5 });
      t1.from('.card-item-ani-2', { y: '120px', duration: 0.5 });
      t1.from('.card-item-ani-3', { y: '120px', duration: 0.5 });
    });
  },
};
</script>