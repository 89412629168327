<template>
  <div
    class="h-screen fixed top-0 left-0 z-[99999] w-full bg-black flex flex-col justify-between items-center fullscreen_loader pb-[45px]">
    <img src="/images/landing/loader/money.png" alt="money" class="img-fluid hiro-top-img mx-auto">
    <img src="/images/landing/loader/Illustration.svg" alt="Illustration" class="w-[278px]">
      <h2 class="text-number">{{ width }}%</h2>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import coin1 from '../../assets/loader/loader-coin-1.png';
import coin2 from '../../assets/loader/loader-coin-2.png';
import coin3 from '../../assets/loader/loader-coin-3.png';
import coin4 from '../../assets/loader/loader-coin-4.png';
import coin5 from '../../assets/loader/loader-coin-5.png';
import bg from '../../assets/loader/loader-bg.png';
import logo from '../../assets/logo.png';

export default {
  name: 'FullScreenLoader',
  props: {
    start: {
      default: false,
    },
    onSuccess: {
      default: null,
    },
  },
  computed: {
    ...mapGetters(['me', 'isLoggedIn']),
    top() {
      return this.width * 2 - 100;
    },
  },
  created() {
    this.now = new Date().getTime();
    this.page_load_time = this.now - window.performance.timing.navigationStart;
  },
  setup() {
    return {
      page_load_time: '',
      now: '',
      coin1,
      coin2,
      coin3,
      coin4,
      coin5,
      bg,
      logo,
    };
  },
  data() {
    return {
      intervalid1: 0,
      width: 0,
    };
  },
  mounted: function() {
    if (this.start) this.calculated();
  },
  methods: {
    onLoad() {
    },
    calculated() {
      var width = 100; // width of a progress bar in percentage
      const perfData = window.performance.timing; // The PerformanceTiming interface
      const EstimatedTime = -(perfData.loadEventEnd - perfData.navigationStart); // Calculated Estimated Time of Page Load which returns negative value.
      const time = parseInt((EstimatedTime / 1000) % 60) * 100 + 1; //Converting EstimatedTime from milliseconds to seconds. + 1 seconds delay
      const second_per_pec = width / time;


      this.intervalid1 = setInterval(() => {
        this.width += 1;

        if (this.width >= 100) {
          clearInterval(this.intervalid1);
          if (this.onSuccess)
            this.onSuccess();
        }

      }, 1);
    },
  },
  watch: {
    start: function(newVal, oldVal) {
      // watch it
      if (newVal) {
        this.calculated();
      }
    },
  },
};
</script>

<style scoped>
/* if we need to add rotating image */
/* .rotate {
  animation: rotation 5s linear infinite;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
} */
</style>
