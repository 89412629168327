import { defineStore } from 'pinia';
import axios from '@/lib/myAxios';
import { timeParse } from 'd3-time-format';

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const affiliatesStore = defineStore('affiliates', {
  state: () => {
    return {
      userclaims: [],
      widthrawals: [],
      comissions: {},
    };
  },
  getters: {},
  actions: {
    async fetchClaims(payload) {
      return axios.get(`/affiliate/users/${payload}/claims`);
    },
    async fetchWidthrawals() {
      let { data } = await axios.get('/affiliate/widthraw');
      this.widthrawals = [...data];
    },
    async fetchReferedUsers(userId, page, perPage) {
      return axios.get(
        `/affiliate/${userId}/referedusers?page=${page}&perPage=${perPage}`,
      );
    },
    async widthrawAmount(claims) {
      return await axios.post('/affiliate/widthraw', { claims: claims });
    },
    async fetchReferralCodes(userId) {
      return axios.get(`/affiliate/${userId}/referral-codes`);
    },
    async storeCampaign(payload) {
      return axios.post(`/campaign`, payload);
    },
  },
});
