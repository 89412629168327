<template>
  <div class="bg-secondary p-2 rounded-lg">
    <span class="text-lg bold font-bold">Two Factor</span>
    <div class="mt-4 text-sm">
      <span>Enable two factor authentication to ensure your account is secure.</span>
    </div>
    <div class="mt-4">
      <span class="flex h-[1px] opacity-50 bg-line-color-bright" />
    </div>
    <div class="mt-4 text-sm py-4">
      <div class="hidden">
        <label
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          for="copy-code">
          Copy this code to your authenticator app
        </label>
        <div class="inline-flex rounded-md shadow-sm" role="group">
          <input
            id="copy-code"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-l-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-secondary dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-600 dark:focus:border-green-600 dark:shadow-sm-light"
            type="text" />

          <button
            class="inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-r-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-green-600 dark:focus:text-white"
            type="button"
            v-on:click="copyToClipboard">
            <div>
              <copy-to-clipboard />
            </div>
          </button>
        </div>
      </div>
      <div v-if="!enabled">
        <div class="text-sm">
          <span>Don't let anyone see this!</span>
        </div>
        <div class="mb-6 py-4">
          <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            Two Factor Code
          </label>
          <div class="flex flex-row justify-start py-4">
            <div
              class="bg-slate-100 p-2 rounded-md child:w-32 w-36 h-36 flex flex-row items-center justify-center"
              v-html="qrCode"></div>
          </div>
        </div>
      </div>
      <form @submit.stop.prevent="() => onSubmit()">
        <label for="code" class="mt-4 block mb-2 text-sm font-medium text-text-dim-color"
          >Password</label
        >
        <div class="relative">
          <div
            class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.16146 9H15.8385C16.3657 8.99998 16.8205 8.99997 17.195 9.03057C17.5904 9.06287 17.9836 9.13419 18.362 9.32698C18.9265 9.6146 19.3854 10.0735 19.673 10.638C19.8658 11.0164 19.9371 11.4096 19.9694 11.805C20 12.1795 20 12.6344 20 13.1615V17.8386C20 18.3657 20 18.8205 19.9694 19.195C19.9371 19.5904 19.8658 19.9836 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.9836 21.8658 17.5904 21.9371 17.195 21.9694C16.8205 22 16.3657 22 15.8385 22H8.16145C7.63431 22 7.17954 22 6.80498 21.9694C6.40963 21.9371 6.01641 21.8658 5.63803 21.673C5.07355 21.3854 4.6146 20.9265 4.32698 20.362C4.13419 19.9836 4.06287 19.5904 4.03057 19.195C3.99997 18.8205 3.99998 18.3657 4 17.8386V13.1614C3.99998 12.6343 3.99997 12.1795 4.03057 11.805C4.06287 11.4096 4.13419 11.0164 4.32698 10.638C4.6146 10.0735 5.07355 9.6146 5.63803 9.32698C6.01641 9.13419 6.40963 9.06287 6.80498 9.03057C7.17953 8.99997 7.63434 8.99998 8.16146 9ZM12 15C11.4477 15 11 15.4477 11 16V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V16C13 15.4477 12.5523 15 12 15Z"
                fill="white" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8 6C8 3.79086 9.79086 2 12 2C14.2091 2 16 3.79086 16 6V9H14V6C14 4.89543 13.1046 4 12 4C10.8954 4 10 4.89543 10 6V9H8V6Z"
                fill="white" />
            </svg>
          </div>
          <input
            v-model="form.password"
            required
            type="password"
            id="code"
            class="input-with-icon"
            placeholder="Your password here" />
        </div>
        <label
          for="password"
          class="mt-4 block mb-2 text-sm font-medium text-text-dim-color"
          >Code</label
        >
        <div class="relative">
          <div
            class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.16146 9H15.8385C16.3657 8.99998 16.8205 8.99997 17.195 9.03057C17.5904 9.06287 17.9836 9.13419 18.362 9.32698C18.9265 9.6146 19.3854 10.0735 19.673 10.638C19.8658 11.0164 19.9371 11.4096 19.9694 11.805C20 12.1795 20 12.6344 20 13.1615V17.8386C20 18.3657 20 18.8205 19.9694 19.195C19.9371 19.5904 19.8658 19.9836 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.9836 21.8658 17.5904 21.9371 17.195 21.9694C16.8205 22 16.3657 22 15.8385 22H8.16145C7.63431 22 7.17954 22 6.80498 21.9694C6.40963 21.9371 6.01641 21.8658 5.63803 21.673C5.07355 21.3854 4.6146 20.9265 4.32698 20.362C4.13419 19.9836 4.06287 19.5904 4.03057 19.195C3.99997 18.8205 3.99998 18.3657 4 17.8386V13.1614C3.99998 12.6343 3.99997 12.1795 4.03057 11.805C4.06287 11.4096 4.13419 11.0164 4.32698 10.638C4.6146 10.0735 5.07355 9.6146 5.63803 9.32698C6.01641 9.13419 6.40963 9.06287 6.80498 9.03057C7.17953 8.99997 7.63434 8.99998 8.16146 9ZM12 15C11.4477 15 11 15.4477 11 16V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V16C13 15.4477 12.5523 15 12 15Z"
                fill="white" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8 6C8 3.79086 9.79086 2 12 2C14.2091 2 16 3.79086 16 6V9H14V6C14 4.89543 13.1046 4 12 4C10.8954 4 10 4.89543 10 6V9H8V6Z"
                fill="white" />
            </svg>
          </div>
          <input
            v-model="form.code"
            required
            type="text"
            id="password"
            class="input-with-icon"
            placeholder="Two factor code here" />
        </div>

        <button :disabled="loading" class="btn-bet">
          {{
            loading
              ? 'Please wait...'
              : enabled
                ? 'Disable Two Factor'
                : 'Enable Two Factor'
          }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import CopyToClipboard from '../Shared/CopyToClipboard.vue';
import { twoFactorStore } from '@/store/pinia/twoFactorStore';
import { doRecaptcha } from '@/lib/recaptcha';

export default {
  name: 'General',
  components: {
    CopyToClipboard,
  },
  setup() {
    return {
      twoFaS: twoFactorStore(),
    };
  },
  async created() {
    await this.init();
  },
  data() {
    return {
      qrCode: '',
      loading: false,
      enabled: false,
      form: {
        password: '',
        code: '',
      },
    };
  },
  methods: {
    copyToClipboard() {
      const copyText = document.getElementById('copy-code');
      copyText.select();
      document.execCommand('copy');
      //toast here
    },
    async init() {
      try {
        this.loading = true;
        const { data } = await this.twoFaS.init();
        this.qrCode = data.svg;
        this.enabled = data.confirmed;
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
    async enableTwoFactor() {
      try {
        this.loading = true;
        let res = null;
        if (this.enabled) {
          res = await this.twoFaS.delete(this.form);
        } else {
          res = await this.twoFaS.enable(this.form);
        }
        const { data } = res;
        this.$toast.success(data.status);
        await this.init();
        this.form = {
          password: '',
          code: '',
        };

        setTimeout(() => {
          window.location.reload();
          this.$router.push({ path: '/' }).then(() => {
            this.$router.go();
          });
        }, 3000);
      } catch (e) {
        const { data } = e;
        if (typeof data.error != 'undefined') {
          this.$toast.error(data.error);
        } else {
          window.swal('Failed', 'Reload page and try again.', 'error');
        }
      } finally {
        this.loading = false;
      }
    },
    async onSubmit() {
      await this.enableTwoFactor();
    },
  },
};
</script>
