import { defineStore } from 'pinia';
import axios from '../lib/axiosConfig';
import { chatStore } from './chatStore';
import { recaptcha } from '../lib/utils';

export const userStore = defineStore('user', {
  state: () => {
    return {
      chatVisible: true,
      betsSlipVisible: false,
      mobileMode: false,
      token: null,
      profile: null,
      isAdmin: true,
      balance: null,
      balanceTimestamp: 0,
      chatS: chatStore(),
    };
  },
  getters: {
    getToken() {
      this.fetchToken();
      return this.token;
    },
    getProfile() {
      this.fetchToken();
      return this.profile;
    },
    canChat() {
      return this.profile.can_chat;
    },
    isMuted() {
      return !this.isAuthenticated || this.profile.muted;
    },
    isAuthenticated() {
      return this.getToken !== null && this.profile != null;
    },
    isMod() {
      return this.isAuthenticated && this.profile.role !== 'user';
    },
  },
  actions: {
    toggleChat() {
      this.chatVisible = !this.chatVisible;
      if (!this.chatVisible) {
        this.mobileMode = false;
      }
    },
    toggleBetSlip() {
      this.betsSlipVisible = !this.betsSlipVisible;
      if (!this.betsSlipVisible) {
        this.mobileMode = false;
      }
    },
    async login(data) {
      try {
        let response = await axios.post('api/auth/login', data);
        window.localStorage['nexus-token'] = response.data.token;
        this.token = response.data.token;
        //fetch profile
        await this.fetchProfile();
        this.chatS.init();
        window.location.reload();
      } catch(error) {
        console.log('error while login');
      }
    },
    async resetPassword(data) {
      await axios.post('api/auth/password-reset-link ', data);
    },
    async updatePassword(data) {
      await axios.post('api/auth/password-reset', data);
    },
    unBan() {
      let isIpBanned = window.localStorage['ip-banned'] !== undefined;
      if (isIpBanned) {
        delete window.localStorage['ip-banned'];
        window.location.reload();
      }
    },
    async fetchProfile() {
      let isIpBanned = window.localStorage['ip-banned'] !== undefined;
      try {
        await this.fetchBalance();
        this.unBan();
      } catch (e) {
        const { status } = e;
        if (status !== 409) {
          this.unBan();
        }
      }
      isIpBanned = window.localStorage['ip-banned'] !== undefined;
      if (isIpBanned) return;
      let response = await axios.get('api/user');
      this.profile = response.data;
      if (this.profile.banned) {
        window.toast("You've been banned. Goodbye!", false);
        await this.logout();
      }
      window.localStorage['nexus-profile'] = JSON.stringify(this.profile);
      return this.profile;
    },
    async fetchBalance() {
      let response = await axios.get('api/user/balance');
      this.updateBalance(response.data.balance, response.data.timestamp);
    },
    async fetchAffiliates(page = 1) {
      return await axios.get('api/user/referrals?page=' + page);
    },
    async updateAccount(data) {
      await axios.patch('api/user', data);
      this.profile.email = data.email;
    },
    async createAccount(data) {
      if (window.localStorage['Ref'] !== undefined) data.ref = window.localStorage['Ref'];
      await axios.post('api/user/register', data);
      //login
      data.recaptcha = await recaptcha('login');
      await this.login(data);
    },
    async changePassword(data) {
      return await axios.post('api/password', data);
    },
    async logout() {
      try {
        await axios.delete('api/auth/logout');
        this.token = null;
        this.profile = null;
        delete window.localStorage['nexus-token'];
        delete window.localStorage['nexus-profile'];
        delete window.localStorage['Ref'];
        window.Intercom('shutdown');
        window.location.reload();
      } catch(error) {
        console.log('error while logging out');
      }
    },
    fetchToken() {
      if (this.token === null) {
        this.token =
          'nexus-token' in window.localStorage
            ? window.localStorage['nexus-token']
            : null;
      }
      if (this.profile === null) {
        this.profile =
          'nexus-profile' in window.localStorage
            ? JSON.parse(window.localStorage['nexus-profile'])
            : null;
      }
      return this.token;
    },
    updateBalance(amount, timestamp) {
      if (timestamp > this.balanceTimestamp) {
        this.balanceTimestamp = timestamp;
        this.balance = amount;
      }
    },
  },
});
