<template>
  <punt-modal show-close="true" size="max-w-2xl">
    <template #title>
      <div class="flex flex-row items-center gap-2 text-slate-300/80">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-8 w-8"
          viewBox="0 0 20 20"
          fill="currentColor">
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clip-rule="evenodd" />
        </svg>
        <h1 class="text-lg font-bold">Chat Rules</h1>
      </div>
    </template>
    <template #body>
      <div class="modal is-active">
        <ol class="m-4 list-decimal space-y-2 px-4 text-slate-300">
          <li>Don't spam & don't use excessive capital letters when chatting.</li>
          <li>Don't harass or be offensive to other users or Juked staff.</li>
          <li>
            Don't share any personal information (including socials) of you or other
            players.
          </li>
          <li>Don't beg or ask for loans, rains or tips.</li>
          <li>
            Don't use alternative (alts) accounts on chat, that is strictly forbidden.
          </li>
          <li>No suspicious behavior that can be seen as potential scams.</li>
          <li>
            Don't engage in any forms of advertising/trading/selling/buying or offering
            services.
          </li>
          <li>No discussion of streamers or Twitch or any other similar platforms.</li>
          <li>Don't use URL shortening services. Always submit the full link.</li>
          <li>Don't share codes, scripts or any other bot service.</li>
          <li>Only use English</li>
          <li>No politics & no religion talk in chat, this one is strictly forbidden.</li>
          <li>No pasting bonus codes</li>
        </ol>
      </div>
    </template>
  </punt-modal>
</template>

<script>
import PuntModal from '@/components/Shared/PuntModal.vue';
export default {
  name: 'ChatRules',
  components: { PuntModal },
  methods: {},
};
</script>
