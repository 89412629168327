<template>
  <div class="flex flex-col space-y-2 text-sm">
    <p><strong>Privacy Policy</strong></p>
    <p>
      This Privacy Policy governs the manner in which Juked Limited (hereinafter
      &ldquo;Juked&rdquo;, &ldquo;We&rdquo;, &ldquo;us&rdquo;, or &ldquo;our&rdquo;),
      collects, use, maintain and disclose information collected from customers of its
      website
      <span style="text-decoration: underline; color: #67e044">www.juked.co </span>.
      This Privacy Policy applies to
      <span style="text-decoration: underline; color: #67e044">www.juked.co </span>and
      all products and services offered through
      <span style="text-decoration: underline; color: #67e044">www.juked.co </span>.
    </p>
    <p><strong>1. Juked statement</strong></p>
    <p>
      Juked is committed to protecting and respecting your privacy and maintaining the
      confidence and trust its customers. This Privacy Policy explains how your personal
      information is collected, why it is collected and how it is kept secure.
    </p>
    <p><strong>2. Type of information we collect</strong></p>
    <p>We collect the following information from you:</p>
    <ul>
      <li>Full name</li>
      <li>Date of Birth</li>
      <li>Permanent address</li>
      <li>E-mail</li>
      <li>Phone number</li>
      <li>
        Device information about your use of our website, such as the content you view,
        the time and duration of your visit on our website, how often you use our
        services, how you first heard about our website, your preferences and information
        about your interaction with the content offered through our website, your hardware
        model, device type, other unique device identifiers, operating system version,
        browser type and IP address
      </li>
      <li>
        Identification documents (Passport, ID, utility bills, bank statements, etc.)
      </li>
      <li>Transaction information (linked to the purchases and redeems you make)</li>
      <li>
        Communications exchange with our teams (support, live chat, complaints, etc.)
      </li>
      <li>
        Information we obtain from a third-party, such as a site or platform provider
        (including Facebook), about your use of or interest in our services.
      </li>
    </ul>
    <p><strong>3. How we collect your information</strong></p>
    <p>
      We collect your information in a variety of ways, including when you visit,
      register, make purchases or redemptions on
      <a href="http://www.juked.co" target="_blank" rel="noopener"
        ><span style="color: #67e044">www.juked.co </span></a
      >, or communicate with us through filling out a form, using our chats or other means
      of communications.
    </p>
    <p>
      We also collect information about your use of our products and services through a
      variety of technologies that are present when you visit
      <a href="http://www.juked.co" target="_blank" rel="noopener"
        ><span style="color: #67e044">www.juked.co </span></a
      >or use our applications on third-party sites or platforms (whether or not you are
      logged in or registered) including cookies, flash cookies, pixels, tags and
      application program interfaces ("API").
    </p>
    <p>
      Analytics tools are also used by us to collect information, including when you visit
      <a href="http://www.juked.co" target="_blank" rel="noopener"
        ><span style="color: #67e044">www.juked.co </span></a
      >or use our applications or services on third-party sites or platforms.
    </p>
    <p><strong>4. How we use collect information</strong></p>
    <p>
      Juked is responsible for and may use your information for the purposes described
      in this Privacy Policy. Third-Parties may access your information where they act on
      behalf of Juked as a data processor for the purposes described in this Privacy
      Policy.
    </p>
    <p>
      In accordance with applicable law and any elections made available to you, Juked
      may collect and use your information for the following purposes:
    </p>
    <ul>
      <li>Provide and manage the services you request</li>
      <li>Improve customer service and our services</li>
      <li>Process payments</li>
      <li>Contact you about our services</li>
      <li>Comply legal and regulatory obligations.</li>
    </ul>
    <p><strong>5. Personal data collected via technology</strong></p>
    <p>
      When you interact with our website, we try to make that experience simple and
      useful. We and our partners use industry standard identifiers, such as cookies or
      other similar technologies.
    </p>
    <p>
      Our website may use technologies to enhance your experience. These technologies are
      small files that are placed on your computer, tablet, mobile phone or other devices
      when you visit a website. They allow us to record certain pieces of information
      whenever you visit or interact with the website.
    </p>
    <p><strong>6. Your rights over your information</strong></p>
    <p>You have the following general rights:</p>
    <ul>
      <li>Right of to access the personal information we hold about you</li>
      <li>Right to object to the processing of your data</li>
      <li>The right to withdraw any consent provided</li>
      <li>The right to request we delete the personal information we hold about you</li>
    </ul>
    <p>
      To exercise the rights described above, please contact
      <a href="https://stake.us/policies/support@stake.us" target="_blank" rel="noopener"
        ><span style="color: #67e044">help@juked.co </span></a
      >.
    </p>
    <p><strong>7. How we protect your information</strong></p>
    <p>
      Juked has in place, physical, electronic and operational procedures to protect the
      information that we collect from you. Juked adopts appropriate data collection,
      storage and processing practices and security measures to protect against
      unauthorized access, alteration, disclosure or destruction of your personal
      information, username, password, transaction information and data stored on our
      database. Our security measures are reviewed regularly and updated in keeping with
      technological advances.
    </p>
    <p><strong>8. How long we will retain your information</strong></p>
    <p>
      We will retain your information for the period of time required to fulfill the
      purposes outlined in this Privacy Policy, unless a longer retention period is
      required or permitted by law.
    </p>
    <p><strong>9. Sharing your information</strong></p>
    <p>
      Juked does not sell, trade, or rent the personal identification information of its
      customers. However, there are circumstances when Juked will share your personal
      data with other companies in Juked Group or with third-parties that provide
      services to you on our behalf.
    </p>
    <p>
      Juked will only share your personal information with companies of its Group and
      Third-Parties on the following circumstances:
    </p>
    <ul>
      <li>You allow us to share your information with third-parties</li>
      <li>
        When providing you with products and services and notifying you about either
        important changes or developments to the features and operation of those products
        and services
      </li>
      <li>
        When such information is required by our service providers to enable us to provide
        our services, such as companies that help us with technology services, storing and
        combining data, processing payments and redemptions or providing relevant
        marketing and advertising for our products and services. The partner companies
        will have access to your personal information only to perform services on our
        behalf and are prohibited from using your personal information for purposes other
        than those requested by us or required by law
      </li>
      <li>
        In response to lawful requests by public authorities, including to meet national
        security or law enforcement requirements, when ordered to do so by any regulatory
        body and/or under any legal provision contained in the governing law of a
        particular jurisdiction
      </li>
      <li>
        When instructing and authorizing the financial institution with which your account
        is held to disclose any information as may be requested by a regulator in respect
        to your account
      </li>
      <li>
        To enforce our terms and conditions set out on
        <a href="http://www.juked.co" target="_blank" rel="noopener"
          ><span style="color: #67e044">www.juked.co </span></a
        >, to protect our rights and property and the rights and property of our customers
        and third-parties, to detect fraud or other illegal activities, and to comply with
        law or legal processes; and
      </li>
      <li>To perform customer due diligence including ID verification.</li>
    </ul>
    <p>
      We may ask you to provide your image to assist us in verifying your identity. We do
      this by using facial recognition technology provided by third-parties that
      determines whether the photo you take matches the photo in your identification
      document. The facial recognition technology provided by these third-parties collects
      information from your image capture, including biometric data, and may share this
      information with us. Biometric data is stored by the third-party service provider in
      accordance with its Privacy Policy and is stored by us, until such time as the
      initial purpose for collecting or obtaining such information has been satisfied or
      within 3 years of your last interaction with us, whichever occurs first, provided we
      have no other legal obligation to retain such information for any longer period.
    </p>
    <p>
      We may also share generic aggregated demographic information not linked to any
      personal identification information regarding visitors to and customers of our with
      our business partners, trusted affiliates and advertisers for the purposes outlined
      above.
    </p>
    <p><strong>10. Data transfers</strong></p>
    <p>
      Juked Group operates in several international jurisdictions and personal
      information we collect may be transferred to, and stored and processed by,
      individual companies in the Juked Group or third-parties in the European Union,
      the United States, Australia, or any other country in which we or our third-party
      processors maintain facilities.
    </p>
    <p>
      We will ensure that transfers of personal information to any country or organization
      are subject to appropriate safeguards. Additionally, without limitation and where
      applicable, when transferring personal information from the European Union, we may
      use standard contractual clauses approved by the European Commission or otherwise
      adopt other means in accordance with European data protection laws for ensuring
      adequate safeguards.
    </p>
    <p><strong>11. Changes to this Privacy Policy</strong></p>
    <p>
      This Privacy Policy may be updated from time to time to reflect changes in the way
      we work or the way our work is regulated. We will notify you of material changes
      and, where required by law, will obtain your consent. Notice may be by the posting
      of such changes on our website or by other means in accordance with applicable law.
    </p>
    <p>
      Any changes to the Privacy Policy will become effective when the updated policy is
      posted on
      <a href="http://www.juked.co" target="_blank" rel="noopener"
        ><span style="color: #67e044">www.juked.co </span></a
      >.
    </p>
    <p>
      We encourage you to frequently check this page for any changes to stay informed
      about how we are helping to protect the personal information we collect.
    </p>
    <p><strong>12. Contacting us</strong></p>
    <p>
      If you have specific questions regarding your personal information or how we use it,
      please contact
      <a href="mailto:support@stake.us" target="_blank" rel="noopener"
        ><span style="color: #67e044">help@juked.co </span></a
      >.
    </p>
    <p><strong>13. Notice about collection</strong></p>
    <p>
      We collect information that identifies, relates to, describes, references, is
      capable of being associated with, or could reasonably be linked, directly or
      indirectly, with a particular consumer or device (&ldquo;personal
      information&rdquo;).
    </p>
    <p>Information Collected</p>
    <p>The information we collect may include the following categories of information:</p>
    <table>
      <tbody>
        <tr>
          <td><strong>Personal Information Category</strong></td>
          <td><strong>Examples</strong></td>
          <td><strong>Collected</strong></td>
        </tr>
        <tr>
          <td>Identifiers</td>
          <td>
            A real name, alias, postal address, unique personal identifier, online
            identifier, Internet Protocol address, email address, account name, Social
            Security number, driver's license number, passport number, or other similar
            identifiers.
          </td>
          <td>YES</td>
        </tr>
        <tr>
          <td>Personal information</td>
          <td>
            Including but not limited to name, signature, social security number, physical
            characteristics or description, communications such e-mails or chat messages,
            address, telephone number, passport number, driver&rsquo;s license or state
            identification card number, insurance policy number, education, employment,
            employment history, bank account number, credit card number, debit card
            number, or any other financial information. Some personal information included
            in this category may overlap with other categories.
          </td>
          <td>YES</td>
        </tr>
        <tr>
          <td>
            Protected classification characteristics under California or federal law
          </td>
          <td>
            Age (40 years or older), race, color, ancestry, national origin, citizenship,
            religion or creed, marital status, medical condition, physical or mental
            disability, sex, sexual orientation, veteran or military status, genetic
            information (including familial genetic information).
          </td>
          <td>YES</td>
        </tr>
        <tr>
          <td>Commercial information</td>
          <td>
            Records of personal property, products or services purchased, obtained, or
            considered, or other purchasing or consuming histories or tendencies.
          </td>
          <td>YES</td>
        </tr>
        <tr>
          <td>Biometric information</td>
          <td>
            An individual&rsquo;s physiological, biological or behavioral characteristics
            that can be used singly or in combination with each other or with other
            identifying data, to establish individual identity. Facial recognition
            technology collects information from your image capture, including biometric
            data, and shares this information with us, which assists us to verify your ID.
          </td>
          <td>YES</td>
        </tr>
        <tr>
          <td>Internet or other similar network activity</td>
          <td>
            Browsing history, search history, information on a consumer's interaction with
            a website, application, or advertisement.
          </td>
          <td>YES</td>
        </tr>
        <tr>
          <td>Geolocation data</td>
          <td>Physical or IP address location or movements.</td>
          <td>YES</td>
        </tr>
        <tr>
          <td>Sensory data</td>
          <td>Audio, electronic, visual, or similar information.</td>
          <td>YES</td>
        </tr>
        <tr>
          <td>Professional or employment-related information</td>
          <td>Current or past job history.</td>
          <td>YES</td>
        </tr>
        <tr>
          <td>Non-public education information</td>
          <td>
            Education records directly related to a student maintained by an educational
            institution or party acting on its behalf, such as grades, transcripts, class
            lists, student schedules, student identification codes, student financial
            information, or student disciplinary records.
          </td>
          <td>NO</td>
        </tr>
        <tr>
          <td>Inferences drawn from other personal information</td>
          <td>
            Profile reflecting a person's preferences, characteristics, predispositions,
            behavior.
          </td>
          <td>YES</td>
        </tr>
        <tr>
          <td><strong>Sensitive Personal Information Category</strong></td>
          <td><strong>Examples</strong></td>
          <td><strong>Collected</strong></td>
        </tr>
        <tr>
          <td>Government identifiers</td>
          <td>
            Social security, driver&rsquo;s license, state identification card, or
            passport number.
          </td>
          <td>YES</td>
        </tr>
        <tr>
          <td>Complete account access credentials</td>
          <td>
            Customer names, account numbers, digital currency addresses, or card numbers
            combined with required access/security code or password.
          </td>
          <td>YES</td>
        </tr>
        <tr>
          <td>Precise geolocation</td>
          <td>Precise physical or IP address location or movements.</td>
          <td>YES</td>
        </tr>
        <tr>
          <td>Racial or ethnic origin</td>
          <td>
            Racial or ethnic origin information that may be revealed through Government or
            other identifiers or other personal information.
          </td>
          <td>YES</td>
        </tr>
        <tr>
          <td>Religious or philosophical beliefs</td>
          <td>NO</td>
        </tr>
        <tr>
          <td>Union membership</td>
          <td>NO</td>
        </tr>
        <tr>
          <td>Genetic data</td>
          <td>NO</td>
        </tr>
        <tr>
          <td>Mail, email, or text messages contents not directed to us</td>
          <td>
            Chat conversations conducted on our platform; communications with our
            affiliates or service providers.
          </td>
          <td>YES</td>
        </tr>
        <tr>
          <td>Unique identifying biometric information</td>
          <td>
            Biometric information that may be revealed through Government or other
            identifiers or other personal information.
          </td>
          <td>YES</td>
        </tr>
        <tr>
          <td>Health, sex life, or sexual orientation information</td>
          <td>NO</td>
        </tr>
      </tbody>
    </table>
    <p>Sources of Information</p>
    <p>
      We obtain the categories of personal information listed above from the following
      sources:
    </p>
    <p>
      1. Directly from our customers. For example, from when customers register for our
      services or respond to requests for information verification.
    </p>
    <p>
      2. Directly and indirectly from activity on our websites and applications. This
      includes:
    </p>
    <ul>
      <li>
        Usage and log information. For example, service-related, diagnostic and
        performance information. This includes information about your activity on our
        websites and applications, log files and reports.
      </li>
      <li>
        Device and connection information. We collect device-specific information such as
        hardware model, operating system information, browser information, IP address etc.
      </li>
      <li>
        From third-parties that interact with us in connection with the services we
        perform. We may work with third-parties, for example, in order to understand,
        customise, provide, support and market the services we provide and/or to comply
        with laws.
      </li>
    </ul>
    <p>Use of Personal Information</p>
    <p>
      We may use or disclose the personal information we collect for one or more of the
      following business purposes:
    </p>
    <ul>
      <li>To provide and manage the services you request</li>
      <li>To maintain, develop, and improve customer service and our services</li>
      <li>To create, maintain, customize, and secure your customer account</li>
      <li>To fulfill purchases or redemptions and prevent transactional fraud</li>
      <li>To personalize user experience</li>
      <li>To contact you about our services</li>
      <li>To send important notices to you</li>
      <li>To comply with our legal and regulatory obligations</li>
      <li>To offer alternative dispute resolution services.</li>
    </ul>
    <p>
      We will not retain the categories of personal information and sensitive personal
      information longer than reasonably necessary to achieve these business purposes.
      Ordinarily, we will retain your personal information and sensitive personal
      information for up to [12] months beyond any closure of your account.
    </p>
    <p>Sharing Personal Information</p>
    <p>
      We may disclose your personal information to third-parties for the business purposes
      described above. We may disclose your personal information for a business purpose to
      the following categories of third-parties:
    </p>
    <ul>
      <li>Our affiliates</li>
      <li>Service providers</li>
      <li>
        Third-parties that interact with us in connection with the services we perform
      </li>
    </ul>
    <p>
      We do not sell your personal information or share it with third-parties for
      cross-context behavioral advertising.
    </p>
    <p><strong>14. Your Rights</strong></p>
    <p>
      California residents may have specific rights regarding their personal information,
      including accessing the personal information we&rsquo;ve collected about you during
      the past 12 months and information about our data practice. You may also have the
      right to request, subject to certain exceptions, that we delete the personal
      information we have collected from you.
    </p>
    <p>Exercising Access and Deletion Rights</p>
    <p>
      To request access to or deletion of your personal information, please submit a
      verifiable consumer request to us at help@juked.co.
    </p>
    <p>
      Only you or a person registered with the California Secretary of State that you
      authorize to act on your behalf may make a verifiable consumer request related to
      your personal information. If you decide to use an authorized agent, please also
      include written permission that you have designated that agent to make this request,
      or proof of the agent&rsquo;s power of attorney. We may follow up with you to verify
      your identity before processing your authorized agent&rsquo;s request. You may also
      make a verifiable consumer request on behalf of your minor child.
    </p>
    <p>For your consumer request to be verifiable, you must:</p>
    <p>
      Provide sufficient information that allows us to reasonably verify you are the
      person about whom we collected personal information or an authorized representative.
      You will need to provide:
    </p>
    <ul>
      <li>Full name</li>
      <li>Date of birth</li>
      <li>Address</li>
      <li>E-mail address</li>
      <li>Whether you are a California consumer pursuant to CCPA</li>
      <li>
        If you would like to know the specific pieces of personal information that we have
        collected about you, or to delete your personal information, identification that
        clearly shows your name, date of birth and address (for example, passport or other
        photo identification)
      </li>
    </ul>
    <p>
      Describe your request with sufficient detail that allows us to properly understand,
      evaluate and respond to it.
    </p>
    <p>
      We cannot respond to your request or provide you with personal information if we
      cannot verify your identity or authority to make the request and confirm the
      personal information relates to you. Making a verifiable consumer request does not
      require you to create an account with us. We will only use personal information
      provided in a verifiable consumer request to verify the requestor's identity or
      authority to make the request.
    </p>
    <p>Response Timing and Format</p>
    <p>
      We endeavor to provide timely responses to verifiable consumer request. If you have
      an account with us, we will deliver our written response to that account. If you do
      not have an account with us, we will deliver our written response by mail or
      electronically, at your option. Any disclosures we provide will only cover the
      12-month period preceding the verifiable consumer request's receipt.
    </p>
    <p>
      The response we provide will also explain the reasons we cannot comply with a
      request, if applicable. For data portability requests, we will select a format to
      provide your personal information that is readily useable and should allow you to
      transmit the information from one entity to another entity without hindrance.
    </p>
    <p>
      We do not charge a fee to process or respond to your verifiable consumer request
      unless it is excessive, repetitive, or manifestly unfounded. If we determine that
      the request warrants a fee, we will tell you why we made that decision and provide
      you with a cost estimate before completing your request.
    </p>
    <p>Non-Discrimination</p>
    <p>
      We will not discriminate against you for exercising any of your rights described
      above.
    </p>
    <p>Changes to this Notice</p>
    <p>
      This Notice may be updated from time to time to reflect changes in the way we work
      or the way our work is regulated. We will notify you of changes by posting changes
      here, or by other appropriate means.
    </p>
    <p>Contact Information</p>
    <p>
      If you have any questions or comments about this Notice, our Privacy Policy, the
      ways in which we collect and use your personal information, your choices and rights
      regarding such use, or wish to exercise your rights, please do not hesitate to
      contact us at help@juked.co.
    </p>
    <p>&nbsp;</p>
  </div>
</template>

<script>
export default {
  name: 'AML',
};
</script>
