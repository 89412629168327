<template>
  <punt-modal size="md:max-w-xl max-w-[92%]" :showClose="showClose" @close="closeModal">
    <template #title></template>
    <template #body>
      <div class="modal promo-modal is-active flex flex-col items-center text-slate-300">
        <img class="main-img mb-5" :src="promo_background" />
        <div class="text-block text-center px-6 pb-6">
          <h3 class="font-bold mb-3 text-3xl">{{ modalTitle }}</h3>
          <p>{{ modalText }}</p>
          <div class="mt-5 flex justify-center space-x-4">
            <button
              v-on:click="handlePromoSelection(true)"
              class="gap-2 rounded-xl bg-accent font-semibold text-white/90 transition delay-0 duration-150 ease-in-out hover:scale-105 hover:opacity-70 md:h-12 md:w-auto px-5 py-3 bg-prim-highlight shadow-btn shadow-accent disabled:opacity-5">
              {{ optInLabel }}
            </button>
            <button
              v-on:click="handlePromoSelection(false)"
              class="gap-2 rounded-xl bg-accent font-semibold text-white/90 transition delay-0 duration-150 ease-in-out hover:scale-105 hover:opacity-70 md:h-12 md:w-auto px-5 py-3 bg-prim-highlight shadow-btn shadow-accent disabled:opacity-5">
              {{ optOutLabel }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </punt-modal>
</template>

<script>
import { mapActions } from 'vuex';
import PuntModal from '@/components/Shared/PuntModal.vue';
import bonus_background from '@/assets/welcome-bouns.png';

export default {
  components: { PuntModal },
  data() {
    return {
      promo_background: bonus_background,
      showClose: true,
      modalTitle: "Free Bonuses Available!",
      modalText: "Opt in to receive free promotional bonuses via email and text. Don't miss out!",
      optInLabel: "Yes, Sign Me Up!",
      optOutLabel: "No, Thanks",
      optInToastMessage: "Thank you! You'll now receive promotional bonuses from us. You can always change your selection in your user profile.",
      optOutToastMessage: "We won't contact you with promotional bonuses. You can always change your selection in your user profile.",
      errorMessage: "Settings could not be updated.",
    };
  },
  methods: {
    ...mapActions(['updateUserOptInPromo']),
    async handlePromoSelection(opt_in) {
      this.closeModal();
      await this.updateUserOptInPromo({
        opt_in_promo_sms: opt_in,
        opt_in_promo_email: opt_in,
      })
        .then(() => {
          if (opt_in) {
            this.$toast.success(this.optInToastMessage);
          } else {
            this.$toast.info(this.optOutToastMessage);
          }
        })
        .catch(e => {
          this.$toast.error(this.errorMessage);
          console.error('Error:', e);
        });
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>
