<template>
  <div
    class="section section-latest-winner relative z-0 border-b border-b-white/30"
    id="reviews">
    <img src="/images/landing/bg.png" class="section_bg_images bgl2" />
    <div class="bg">
      <img
        class="image image-1 hidden lg:block"
        src="/images/landing/winner/w-image-1.svg"
        alt="" />
      <img
        class="image image-2 hidden lg:block"
        src="/images/landing/winner/w-image-2.svg"
        alt="" />
      <img
        class="image image-1 block lg:hidden"
        src="/images/landing/winner/w-image-3.svg"
        alt="" />
      <img
        class="image image-2 block lg:hidden"
        src="/images/landing/winner/w-image-4.svg"
        alt="" />
    </div>
    <div class="container m-auto max-w-[1287px]">
      <h2 class="heading2 section-title mb-[78px]">what our winners<br />are saying</h2>
      <div class="winner-testinomy overflow-hidden pl-[68px] pr-[28px] max-lg:mx-[-6px] lg:px-[38px]">
        <div class="swiper-wrapper py-[40px]" ref="slide">
          <div
            class="card-item winner-card-item-ani-1 style-2 swiper-slide right-shadow z-10">
            <div class="card-inner">
              <div class="quote mb-[32px]">
                <svg
                  width="37"
                  height="24"
                  viewBox="0 0 37 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.5553 7.65995L15.3879 8.67042C16.7609 10.5082 17.1036 11.7821 16.8681 14.2416C16.5443 17.6233 14.7593 20.0896 11.4037 21.1645C9.94879 21.8008 6.71093 21.6459 5.23496 20.8841C3.14507 20.0635 1.47987 18.0426 0.720857 16.2635C0.502193 15.3118 0.407577 14.6823 0.598896 12.684C1.04672 9.62429 1.41255 9.0388 4.24252 6.98285C5.55869 6.1781 8.22045 4.26117 10.2094 2.90033C12.1984 1.53948 14.0192 0.317658 14.3408 0.193331C14.8307 -0.0700167 15.123 0.113088 16.1238 0.984536C16.847 1.51916 17.4021 2.19281 17.3874 2.34653C17.3727 2.50024 16.6263 3.82493 15.8799 5.14961L14.5553 7.65995ZM34.0834 10.7706C35.3323 12.2863 35.6603 13.7138 35.4396 16.0196C35.1158 19.4014 33.3308 21.8676 29.9752 22.9425C28.5203 23.5788 25.2824 23.424 23.8064 22.6622C20.0724 21.2188 18.8172 18.1512 19.1704 14.462C19.4794 11.2339 19.8158 10.9559 23.6403 8.21948C25.461 6.99766 28.1228 5.08072 29.7754 3.99794C31.4427 2.76142 32.927 1.81764 33.234 1.84703C33.6944 1.89112 36.3899 4.47607 36.2069 4.76882C36.1775 5.07625 35.5993 6.26191 34.8382 7.7403L33.6819 10.1116L34.0834 10.7706Z"
                    fill="#0D0D0D" />
                </svg>
              </div>
              <p class="mb-[24px] text-[18px] lg:text-[20px] leading-[110%] font-bold">
                "I've tried several social gaming platforms, but this one stands out for
                its user-friendly interface and unbeatable live social gaming options. The
                real-time updates keep me on top of the game, and I've won more here than
                anywhere else. Instant redemptions and excellent customer support make it
                my go-to social gaming site!"
              </p>
              <p class="body3 flex justify-between">
                <span class="author">Alex D.</span>
                <span class="date">5th Aug, 2024</span>
              </p>
            </div>
          </div>
          <div class="card-item style-2 swiper-slide right-shadow z-10">
            <div class="card-inner">
              <div class="quote mb-[32px]">
                <svg
                  width="37"
                  height="24"
                  viewBox="0 0 37 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.5553 7.65995L15.3879 8.67042C16.7609 10.5082 17.1036 11.7821 16.8681 14.2416C16.5443 17.6233 14.7593 20.0896 11.4037 21.1645C9.94879 21.8008 6.71093 21.6459 5.23496 20.8841C3.14507 20.0635 1.47987 18.0426 0.720857 16.2635C0.502193 15.3118 0.407577 14.6823 0.598896 12.684C1.04672 9.62429 1.41255 9.0388 4.24252 6.98285C5.55869 6.1781 8.22045 4.26117 10.2094 2.90033C12.1984 1.53948 14.0192 0.317658 14.3408 0.193331C14.8307 -0.0700167 15.123 0.113088 16.1238 0.984536C16.847 1.51916 17.4021 2.19281 17.3874 2.34653C17.3727 2.50024 16.6263 3.82493 15.8799 5.14961L14.5553 7.65995ZM34.0834 10.7706C35.3323 12.2863 35.6603 13.7138 35.4396 16.0196C35.1158 19.4014 33.3308 21.8676 29.9752 22.9425C28.5203 23.5788 25.2824 23.424 23.8064 22.6622C20.0724 21.2188 18.8172 18.1512 19.1704 14.462C19.4794 11.2339 19.8158 10.9559 23.6403 8.21948C25.461 6.99766 28.1228 5.08072 29.7754 3.99794C31.4427 2.76142 32.927 1.81764 33.234 1.84703C33.6944 1.89112 36.3899 4.47607 36.2069 4.76882C36.1775 5.07625 35.5993 6.26191 34.8382 7.7403L33.6819 10.1116L34.0834 10.7706Z"
                    fill="#0D0D0D" />
                </svg>
              </div>
              <p class="mb-[24px] text-[18px] lg:text-[20px] leading-[110%] font-bold">
                "Juked is my favorite platform for social sports! Whether it's soccer,
                tennis, or other games, the excitement never stops. The site is
                user-friendly, and I love the real-time updates during matches. It’s a
                fantastic way to engage with sports and keep the competition alive!"
              </p>
              <p class="body3 flex justify-between">
                <span class="author">Olivia</span>
                <span class="date">13th Sep, 2024</span>
              </p>
            </div>
          </div>
          <div
            class="card-item style-2 winner-card-item-ani-3 swiper-slide right-shadow z-10">
            <div class="card-inner">
              <div class="quote mb-[32px]">
                <svg
                  width="37"
                  height="24"
                  viewBox="0 0 37 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.5553 7.65995L15.3879 8.67042C16.7609 10.5082 17.1036 11.7821 16.8681 14.2416C16.5443 17.6233 14.7593 20.0896 11.4037 21.1645C9.94879 21.8008 6.71093 21.6459 5.23496 20.8841C3.14507 20.0635 1.47987 18.0426 0.720857 16.2635C0.502193 15.3118 0.407577 14.6823 0.598896 12.684C1.04672 9.62429 1.41255 9.0388 4.24252 6.98285C5.55869 6.1781 8.22045 4.26117 10.2094 2.90033C12.1984 1.53948 14.0192 0.317658 14.3408 0.193331C14.8307 -0.0700167 15.123 0.113088 16.1238 0.984536C16.847 1.51916 17.4021 2.19281 17.3874 2.34653C17.3727 2.50024 16.6263 3.82493 15.8799 5.14961L14.5553 7.65995ZM34.0834 10.7706C35.3323 12.2863 35.6603 13.7138 35.4396 16.0196C35.1158 19.4014 33.3308 21.8676 29.9752 22.9425C28.5203 23.5788 25.2824 23.424 23.8064 22.6622C20.0724 21.2188 18.8172 18.1512 19.1704 14.462C19.4794 11.2339 19.8158 10.9559 23.6403 8.21948C25.461 6.99766 28.1228 5.08072 29.7754 3.99794C31.4427 2.76142 32.927 1.81764 33.234 1.84703C33.6944 1.89112 36.3899 4.47607 36.2069 4.76882C36.1775 5.07625 35.5993 6.26191 34.8382 7.7403L33.6819 10.1116L34.0834 10.7706Z"
                    fill="#0D0D0D" />
                </svg>
              </div>
              <p class="mb-[24px] text-[18px] lg:text-[20px] leading-[110%] font-bold">
                "Juked is a game-changer for social sports fans like me. From soccer to
                tennis, they cover everything with live match updates and great features.
                The platform is easy to use, and the community vibe adds to the fun. It's
                my go-to for all things sports, and I’m hooked!"
              </p>
              <p class="body3 flex justify-between">
                <span class="author">Jackson N.</span>
                <span class="date">22nd Aug, 2024</span>
              </p>
            </div>
          </div>
          <div class="card-item style-2 swiper-slide right-shadow z-10">
            <div class="card-inner">
              <div class="quote mb-[32px]">
                <svg
                  width="37"
                  height="24"
                  viewBox="0 0 37 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.5553 7.65995L15.3879 8.67042C16.7609 10.5082 17.1036 11.7821 16.8681 14.2416C16.5443 17.6233 14.7593 20.0896 11.4037 21.1645C9.94879 21.8008 6.71093 21.6459 5.23496 20.8841C3.14507 20.0635 1.47987 18.0426 0.720857 16.2635C0.502193 15.3118 0.407577 14.6823 0.598896 12.684C1.04672 9.62429 1.41255 9.0388 4.24252 6.98285C5.55869 6.1781 8.22045 4.26117 10.2094 2.90033C12.1984 1.53948 14.0192 0.317658 14.3408 0.193331C14.8307 -0.0700167 15.123 0.113088 16.1238 0.984536C16.847 1.51916 17.4021 2.19281 17.3874 2.34653C17.3727 2.50024 16.6263 3.82493 15.8799 5.14961L14.5553 7.65995ZM34.0834 10.7706C35.3323 12.2863 35.6603 13.7138 35.4396 16.0196C35.1158 19.4014 33.3308 21.8676 29.9752 22.9425C28.5203 23.5788 25.2824 23.424 23.8064 22.6622C20.0724 21.2188 18.8172 18.1512 19.1704 14.462C19.4794 11.2339 19.8158 10.9559 23.6403 8.21948C25.461 6.99766 28.1228 5.08072 29.7754 3.99794C31.4427 2.76142 32.927 1.81764 33.234 1.84703C33.6944 1.89112 36.3899 4.47607 36.2069 4.76882C36.1775 5.07625 35.5993 6.26191 34.8382 7.7403L33.6819 10.1116L34.0834 10.7706Z"
                    fill="#0D0D0D" />
                </svg>
              </div>
              <p class="mb-[24px] text-[18px] lg:text-[20px] leading-[110%] font-bold">
                "Juked has completely changed how I experience social sports. The platform
                offers seamless interaction for soccer, tennis, and more. The live updates
                and interactive features keep the excitement high during every match. It’s
                the perfect site for anyone passionate about sports!"
              </p>
              <p class="body3 flex justify-between">
                <span class="author">Sophia</span>
                <span class="date">1st Sep, 2024</span>
              </p>
            </div>
          </div>
          <div class="card-item style-2 swiper-slide right-shadow z-10">
            <div class="card-inner">
              <div class="quote mb-[32px]">
                <svg
                  width="37"
                  height="24"
                  viewBox="0 0 37 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.5553 7.65995L15.3879 8.67042C16.7609 10.5082 17.1036 11.7821 16.8681 14.2416C16.5443 17.6233 14.7593 20.0896 11.4037 21.1645C9.94879 21.8008 6.71093 21.6459 5.23496 20.8841C3.14507 20.0635 1.47987 18.0426 0.720857 16.2635C0.502193 15.3118 0.407577 14.6823 0.598896 12.684C1.04672 9.62429 1.41255 9.0388 4.24252 6.98285C5.55869 6.1781 8.22045 4.26117 10.2094 2.90033C12.1984 1.53948 14.0192 0.317658 14.3408 0.193331C14.8307 -0.0700167 15.123 0.113088 16.1238 0.984536C16.847 1.51916 17.4021 2.19281 17.3874 2.34653C17.3727 2.50024 16.6263 3.82493 15.8799 5.14961L14.5553 7.65995ZM34.0834 10.7706C35.3323 12.2863 35.6603 13.7138 35.4396 16.0196C35.1158 19.4014 33.3308 21.8676 29.9752 22.9425C28.5203 23.5788 25.2824 23.424 23.8064 22.6622C20.0724 21.2188 18.8172 18.1512 19.1704 14.462C19.4794 11.2339 19.8158 10.9559 23.6403 8.21948C25.461 6.99766 28.1228 5.08072 29.7754 3.99794C31.4427 2.76142 32.927 1.81764 33.234 1.84703C33.6944 1.89112 36.3899 4.47607 36.2069 4.76882C36.1775 5.07625 35.5993 6.26191 34.8382 7.7403L33.6819 10.1116L34.0834 10.7706Z"
                    fill="#0D0D0D" />
                </svg>
              </div>
              <p class="mb-[24px] text-[18px] lg:text-[20px] leading-[110%] font-bold">
                "Juked is by far the best place for social sports enthusiasts. I mostly
                follow soccer and tennis, and the real-time updates are a game-changer.
                It’s easy to engage with the platform, and the community aspect makes it
                even more enjoyable. The ultimate social sports experience!"
              </p>
              <p class="body3 flex justify-between">
                <span class="author">Mason</span>
                <span class="date">17th Jul, 2024</span>
              </p>
            </div>
          </div>
          <div class="card-item style-2 swiper-slide right-shadow z-10">
            <div class="card-inner">
              <div class="quote mb-[32px]">
                <svg
                  width="37"
                  height="24"
                  viewBox="0 0 37 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.5553 7.65995L15.3879 8.67042C16.7609 10.5082 17.1036 11.7821 16.8681 14.2416C16.5443 17.6233 14.7593 20.0896 11.4037 21.1645C9.94879 21.8008 6.71093 21.6459 5.23496 20.8841C3.14507 20.0635 1.47987 18.0426 0.720857 16.2635C0.502193 15.3118 0.407577 14.6823 0.598896 12.684C1.04672 9.62429 1.41255 9.0388 4.24252 6.98285C5.55869 6.1781 8.22045 4.26117 10.2094 2.90033C12.1984 1.53948 14.0192 0.317658 14.3408 0.193331C14.8307 -0.0700167 15.123 0.113088 16.1238 0.984536C16.847 1.51916 17.4021 2.19281 17.3874 2.34653C17.3727 2.50024 16.6263 3.82493 15.8799 5.14961L14.5553 7.65995ZM34.0834 10.7706C35.3323 12.2863 35.6603 13.7138 35.4396 16.0196C35.1158 19.4014 33.3308 21.8676 29.9752 22.9425C28.5203 23.5788 25.2824 23.424 23.8064 22.6622C20.0724 21.2188 18.8172 18.1512 19.1704 14.462C19.4794 11.2339 19.8158 10.9559 23.6403 8.21948C25.461 6.99766 28.1228 5.08072 29.7754 3.99794C31.4427 2.76142 32.927 1.81764 33.234 1.84703C33.6944 1.89112 36.3899 4.47607 36.2069 4.76882C36.1775 5.07625 35.5993 6.26191 34.8382 7.7403L33.6819 10.1116L34.0834 10.7706Z"
                    fill="#0D0D0D" />
                </svg>
              </div>
              <p class="mb-[24px] text-[18px] lg:text-[20px] leading-[110%] font-bold">
                "Juked has become my go-to platform for everything related to social
                sports. From soccer to tennis, the interactive features and live updates
                make every match exciting. The community aspect is amazing, and it's so
                easy to engage with other fans. Juked truly takes sports to the next
                level!"
              </p>
              <p class="body3 flex justify-between">
                <span class="author">Emma</span>
                <span class="date">30th Aug, 2024</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination text-center mt-[40px]">
        <a target="" class="button prev-btn" href=""
          ><svg
            width="48"
            height="31"
            viewBox="0 0 48 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M47.8381 19.2404C36.8334 18.3409 25.6623 15.8533 13.4749 18.2004C17.9748 21.9389 21.7262 25.0449 25.9303 28.5445C23.0197 31.5802 20.3864 31.5661 17.9932 29.7812C12.6618 25.846 7.35813 21.7702 2.21152 17.315C-0.985482 14.5041 -0.662072 10.864 2.8306 8.57315C4.7667 7.44558 6.74062 6.47451 8.74412 5.66392C11.9966 4.13199 15.2582 2.61408 18.5199 1.15243C19.5732 0.688632 20.6636 0.449739 22.0495 -1.13433e-06C22.0495 4.9612 19.5548 5.28442 17.9563 6.68986C16.3578 8.0953 14.3527 9.31805 12.5325 10.6111L12.708 12.0165C18.714 12.0165 24.7199 11.7073 30.7166 12.1149C35.7431 12.4662 40.7418 13.6327 45.7406 14.5182C47.1174 14.7993 48.4756 15.3193 47.8381 19.2404Z"
              fill="#5FF487" />
          </svg>
        </a>
        <div class="paginations body2 text-white">
          {{ formatNumber(currentSlide) }}/{{ formatNumber(totalSlides) }}
        </div>
        <a target="" class="button next-btn" href="">
          <svg
            width="48"
            height="32"
            viewBox="0 0 48 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M-7.58606e-07 17.3549C0.823696 12.5185 2.57178 12.7102 4.12765 12.666C11.1748 12.5185 18.222 12.4448 25.2692 12.3711C28.0149 12.3711 30.8338 12.3711 33.8906 12.3711C33.314 9.61375 31.6758 9.42206 30.5866 8.22772C29.4975 7.03337 28.0149 5.9275 26.9258 4.453C26.7149 4.03087 26.5734 3.53002 26.5142 2.99663C26.455 2.46324 26.4801 1.91444 26.5871 1.40077C26.7946 0.963673 27.062 0.609797 27.3684 0.366797C27.6748 0.123797 28.012 -0.0017163 28.3535 1.68487e-05C29.2235 0.195324 30.0641 0.645049 30.8337 1.32706C35.7942 4.89535 40.7364 8.52261 45.6878 12.0909C46.9508 13.0051 48.3053 14.0962 47.9392 16.6029C47.61 18.3364 46.9136 19.8288 45.9715 20.82C44.6874 21.9633 43.3313 22.8827 41.9262 23.5625C38.2653 25.848 34.5221 28.0893 30.8337 30.3895C29.2138 31.3921 27.2553 33.5154 26.1387 30.2568C24.8757 26.5853 27.182 25.0223 28.7379 23.3414C29.4609 22.5746 30.2206 21.8669 31.6758 20.3924C20.8213 17.7972 10.5159 19.5371 -7.58606e-07 17.3549Z"
              fill="#5FF487" />
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/css';
import 'swiper/css/effect-creative';
import 'swiper/css/navigation';
import Swiper, { EffectCreative, Navigation } from 'swiper';

import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export default {
  name: 'LatestWinner',
  data: () => ({
    swiper: null,
    currentSlide: 1,
    totalSlides: 6,
  }),
  mounted() {
    setTimeout(() => {
      this.totalSlides = this.$refs.slide.querySelectorAll('.card-item').length;
      this.swiper = new Swiper('.winner-testinomy', {
        modules: [EffectCreative, Navigation],
        // effect: 'creative',
        loop: true,
        spaceBetween: 62,
        grabCursor: true,
        preventClicks: false,
        // loopedSlides: 2,
        preventClicksPropagation: false,
        navigation: {
          enabled: true,
          nextEl: '.section-latest-winner .next-btn',
          prevEl: '.section-latest-winner .prev-btn',
        },
        allowTouchMove: true,
        slidesPerView: 3,
        on: {
          slideChange: this.onSlideChange,
        },
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 60,
          },
          600: {
            slidesPerView: 2,
          },
          900: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 3,
          },
        },
      });

      /*let swiperContainer = document.querySelector(`div.realpeople-slider-swiper`);

      swiperContainer.addEventListener('mouseenter', function () {
        this.swiper.autoplay.stop();
      });

      swiperContainer.addEventListener('mouseleave', function () {
        this.swiper.autoplay.start();
      });*/
    }, 500);

    gsap.registerPlugin(ScrollTrigger);
    const t1 = gsap.timeline({
      scrollTrigger: {
        trigger: '.section-latest-winner',
        start: 'top 10%',
        end: 'bottom bottom',
        // markers: true,
      },
    });
    t1.from('.winner-card-item-ani-1', {
      x: '100%',
      rotate: '-4.49028deg',
      duration: 0.5,
    });
    t1.from('.winner-card-item-ani-3', {
      x: '-100%',
      rotate: '-4.49028deg',
      duration: 0.5,
    });
    t1.to('.winner-card-item-ani-1', {
      x: '0',
    });
    t1.to('.winner-card-item-ani-3', {
      x: '0',
    });
  },
  methods: {
    onSlideChange(swiper) {
      this.currentSlide = swiper.realIndex + 1; // Update the current slide index
    },
    formatNumber(num) {
      return num.toString().padStart(2, '0'); // Change 2 to the desired number of digits
    },
  },
};
</script>