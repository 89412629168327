<template>
  <section class="section section-allowed-state relative z-0" id="map">
    <img src="/images/landing/bg.png" class="section_bg_images bgl1">
    <div class="container big m-auto max-w-[1336px]">
        <div class="lg:flex lg:flew-row inner-content">
            <img src="/images/landing/map/map1.svg" class="map_image map1 hidden lg:block" alt="allowed states">
            <img src="/images/landing/map/map2.svg" class="map_image map2 hidden lg:block " alt="allowed states">
            <img src="/images/landing/map/mobilemap1.svg" class="map_image map3 hidden max-lg:block" alt="allowed states">
            <img src="/images/landing/map/mobilemap2.svg" class="map_image map4 hidden max-lg:block" alt="allowed states">
            <div class="lg:w-[35%] text-center lg:text-left">
                <div class="mx-[auto] my-[0] mb-[30px] max-w-[340px] lg:max-w-[440px]">
                  <h2 class="heading2 section-title mb-[32px]">
                    States <br/> where you <br/> can play
                  </h2>
                  <p class="body2">Our website is available in 43 states! Sign up and make your first sports picks!</p>
                </div>
                <div class="hidden lg:flex flex-row body3 relative mt-[168px]">
                    <div class="relative pl-[44px]">
                      <span class="fill-square yellow bg-yellow" >
                      </span>
                      Allowed to play Juked
                    </div>
                    <div class="relative pl-[44px] ml-[32px]">
                      <span class="fill-square bg-white">
                      </span>
                      Excluded from playing
                    </div>
                </div>
            </div>
            <div class="lg:w-[65%]">
                <Map />
                <div class="md:hidden">
                <div class="body3 max-w-[220px] m-auto relative mt-[25px]">
                    <div class="relative pl-[44px] py-[14px]">
                      <span class="fill-square yellow bg-yellow" >
                      </span>
                      Allowed to play Juked
                    </div>
                    <div class="relative pl-[44px] py-[14px]">
                      <span class="fill-square bg-white">
                      </span>
                      Excluded from playing
                    </div>
                    <p class="body4 opacity-70 text-center mt-[16px]">Scroll the map</p>
                </div>
                <div class="pagination text-center mt-[40px] ">
                        <a href="#" @click.prevent="SlidePrev" class="button prev-btn" :disabled="currentSlide < 2"  ><svg width="48" height="31" viewBox="0 0 48 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M47.8381 19.2404C36.8334 18.3409 25.6623 15.8533 13.4749 18.2004C17.9748 21.9389 21.7262 25.0449 25.9303 28.5445C23.0197 31.5802 20.3864 31.5661 17.9932 29.7812C12.6618 25.846 7.35813 21.7702 2.21152 17.315C-0.985482 14.5041 -0.662072 10.864 2.8306 8.57315C4.7667 7.44558 6.74062 6.47451 8.74412 5.66392C11.9966 4.13199 15.2582 2.61408 18.5199 1.15243C19.5732 0.688632 20.6636 0.449739 22.0495 -1.13433e-06C22.0495 4.9612 19.5548 5.28442 17.9563 6.68986C16.3578 8.0953 14.3527 9.31805 12.5325 10.6111L12.708 12.0165C18.714 12.0165 24.7199 11.7073 30.7166 12.1149C35.7431 12.4662 40.7418 13.6327 45.7406 14.5182C47.1174 14.7993 48.4756 15.3193 47.8381 19.2404Z" fill="#5FF487"/>
                          </svg>
                        </a>
                        <div class="paginations body2 text-black">{{ (currentSlide)}}/{{  (totalSlides) }}</div>
                        <a href="#" @click.prevent="SlideNext" class="button next-btn"  :disabled="currentSlide > 1">
                          <svg width="48" height="32" viewBox="0 0 48 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M-7.58606e-07 17.3549C0.823696 12.5185 2.57178 12.7102 4.12765 12.666C11.1748 12.5185 18.222 12.4448 25.2692 12.3711C28.0149 12.3711 30.8338 12.3711 33.8906 12.3711C33.314 9.61375 31.6758 9.42206 30.5866 8.22772C29.4975 7.03337 28.0149 5.9275 26.9258 4.453C26.7149 4.03087 26.5734 3.53002 26.5142 2.99663C26.455 2.46324 26.4801 1.91444 26.5871 1.40077C26.7946 0.963673 27.062 0.609797 27.3684 0.366797C27.6748 0.123797 28.012 -0.0017163 28.3535 1.68487e-05C29.2235 0.195324 30.0641 0.645049 30.8337 1.32706C35.7942 4.89535 40.7364 8.52261 45.6878 12.0909C46.9508 13.0051 48.3053 14.0962 47.9392 16.6029C47.61 18.3364 46.9136 19.8288 45.9715 20.82C44.6874 21.9633 43.3313 22.8827 41.9262 23.5625C38.2653 25.848 34.5221 28.0893 30.8337 30.3895C29.2138 31.3921 27.2553 33.5154 26.1387 30.2568C24.8757 26.5853 27.182 25.0223 28.7379 23.3414C29.4609 22.5746 30.2206 21.8669 31.6758 20.3924C20.8213 17.7972 10.5159 19.5371 -7.58606e-07 17.3549Z" fill="#5FF487"/>
                        </svg>

                        </a>
                </div>
                </div>
            </div>
        </div>
       
 
    </div>
  </section>
</template>

<script>

import Map from '@/components/Home/Landingpage/Map.vue';
export default {
  name: 'AllowedState',
  data: () => ({
    currentSlide: 1,
    totalSlides:2,
  }),
  components:{
    Map
  },
  methods:{
    SlidePrev()
    {
      if(this.currentSlide >1)
      {
        if( document.getElementsByClassName('map-outer')[0])
        {
          document.getElementsByClassName('map-outer')[0].scrollLeft = 0;
          this.currentSlide=1;
        }
      }
      
    },
    SlideNext()
    {
      if(this.currentSlide <2)
      {
        if( document.getElementsByClassName('map-outer')[0])
        {
          document.getElementsByClassName('map-outer')[0].scrollLeft = 800;
          this.currentSlide=2;
        }
      }
      
    }
  }
};
</script>



 