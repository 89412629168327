<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal">
      <div class="fixed inset-0 z-[99999] overflow-y-auto">
        <div class="min-h-screen md:px-4 px-2 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0">
            <DialogOverlay
              class="fixed inset-0 bg-[rgb(55_65_81_/_40%)] backdrop-filter backdrop-blur-[3px]" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <div
              :class="[size, spacing]"
              class="bg-primary inline-block w-full overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl modal_title">
              <DialogTitle
                v-if="hasTitleSlot"
                as="div"
                class="flex flex-row justify-between space-y-2 items-center text-white">
                <slot name="title"></slot>
                <button
                  v-if="showClose"
                  v-on:click="closeModal"
                  class="p-2 hover:opacity-70 close-punt-modal">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor">
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd" />
                  </svg>
                </button>
              </DialogTitle>
              <slot name="body"></slot>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from '@headlessui/vue';

export default {
  components: { TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle },
  props: {
    size: {
      default: 'max-w-md',
    },
    spacing: {
      default: ' ',
    },
    showClose: {
      default: false,
    },
    onClose: {
      default: null,
    },
  },
  setup() {
    const isOpen = ref(true);
    const openModal = () => {
      isOpen.value = true;
    };

    return { isOpen, openModal };
  },
  computed: {
    hasTitleSlot() {
      return !!this.$slots.title;
    },
  },
  methods: {
    // TODO: Fix manual onClose callback. Vue uses the onClose name internally to store event handlers for the close event.
    // TODO: Make modals open and close by hiding and showing the modal component, not by navigating to a new route.
    closeModal() {
      if (Array.isArray(this.onClose)) {
        // If onClose is an array that means Vue has attached multiple event handlers to the close event.
        // Emitting the close event is the correct way to ensure all event handlers are called.
        this.$emit('close');
      } else if (typeof this.onClose === 'function') {
        // If onClose is a function that means either Vue has attached a single event handler to the close event,
        // or a manual onClose callback was passed as a prop to this component.
        // Emitting the close event is the correct way to call these event handlers.
        // In the absence of regression testing, for now the callback will be called manually as before.
        this.onClose();
      } else {
        // Some modals in our app have a specific route, and when the modal is closed we need to navigate back in the browser.
        // This is not a canonical way to close a modal, and should be changed when we have more regression testing.
        this.$router.go(-1);
      }
    },
  },
};
</script>
