<template>
  <punt-modal size="max-w-xl" :on-close="closeModal">
    <template #body>
      <div
        class="modal is-active flex min-h-[300px] flex-col items-center justify-center space-y-2 p-4 text-slate-300">
        <img
          v-on:click="reload"
          class="mt-8 max-h-8 hover:opacity-80"
          src="../assets/logo.png" />
        <p class="pt-8 text-sm font-bold">
          Sorry! Juked is not available in your particular region.
        </p>
        <p class="texy-sm text-center">
          If you are from a permitted region but still cannot access, please reach out to:
          <a href="mailto:help@juked.co" class="text-slate-100 hover:opacity-70"
            >help@juked.co</a
          >
        </p>

        <!--<div
          class="flex flex-row items-center justify-center gap-4 px-4 py-8 text-sm text-slate-300"
        >
          <a href="https://twitter.com/therealpunt" target="_blank">
            <img
              class="h-5 w-5 hover:scale-105 hover:opacity-80"
              src="../assets/twitter.svg"
            />
          </a>
          <a href="https://instagram.com/therealpunt" target="_blank">
            <img
              class="h-5 w-5 hover:scale-105 hover:opacity-80"
              src="../assets/instagram.svg"
            />
          </a>
        </div> -->
      </div>
    </template>
  </punt-modal>
</template>

<script>
import PuntModal from '@/components/Shared/PuntModal.vue';

export default {
  components: { PuntModal },
  props: {
    onClose: {
      type: String,
      required: false,
      default: function () {
        return 'close';
      },
      validator: function (x) {
        return ['close', 'back'].includes(x);
      },
    },
  },
  data() {
    return {
      limits: false,
      error: null,
      fetching: false,
    };
  },
  methods: {
    reload() {
      window.location.replace('/');
    },
    closeModal() {},
  },
};
</script>
