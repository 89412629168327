<template>
    <section class="section section-faq relative max-lg:px-[16px]" id="FAQs">
    <img src="/images/landing/bg.png" class="section_bg_images bgl1" />
        <div class="container max-w-[1327px] m-auto">
            <div class="row flex items-center max-lg:flex-col">
                <div class="lg:w-6/12">
                    <div class="inner-content">
                        <h2 class="heading2 max-w-[590px] max-lg:text-center max-lg:mb-[32px]">Frequently asked question</h2>
                    </div>
                    <div class="image hidden lg:block">
            <img src="/images/landing/faq/faq-image.svg" alt="" />
                    </div>
                </div>
                <div class="lg:w-6/12">
                    <Accordion v-for="item in items" :key="item.id" :item="item"/>
                    <div class="image block lg:hidden max-lg:mt-[40px]">
            <img class="m-auto" src="/images/landing/faq/faq-image.svg" alt="" />
                  </div>
                </div>
            </div>
        </div>
    </section>
</template>



<script>
import Accordion from './Accordion.vue'
export default {
  name: 'Faq',
  data() {
    return {
      items: [
        {
        id:1,
        que:'What is Juked?',
          ans: `Juked is a social sportsbook that has pioneered play-for-fun sports gaming by reimagining sports predictions as a free-to-play social game. We integrate promotional plays and loyalty rewards to elevate the social experience and offer sports fans the opportunity to earn rewards and win prizes.`,
      },
      {
        id:2,
        que:'Is Juked available in my state?',
          ans: `Players located in the United States, excluding the states of Washington, Kentucky, Michigan, Hawaii, Idaho, Nevada, and Tennessee may use the platform. Eligibility to participate in promotions in other states may vary month-to-month. For more information on eligibility and availability please see our Terms of Use and Sweepstakes Rules.`,
      },
      {
        id:3,
        que:'What’s the difference between Juked Gold and Juked Cash?',
        ans:`Juked Gold are used to play for fun. They provide the opportunity to win more Juked Gold, earn XP, and move up the leaderboards while earning achievement badges and building a status within the Juked community. Juked Gold are a freemium product that can be claimed every hours, or purchased through our shop, but cannot be transferred outside the game or redeemed for prizes. In addition, Juked Gold is always available to claim via our “top-up” if you run out! <br>
Juked Cash is free to claim and serves as an entry into our promotional sweepstakes. Juked Cash won from gameplay can be used in additional games or redeemed for prizes, provided the minimum redemption level is met and the account holder’s identity has been verified in accordance with our Terms of Use. <br>
You can also obtain Juked Cash for free as a bonus with the purchase of select Juked Gold packs or via one of our alternative methods of entry. NO PURCHASE IS NECESSARY to obtain Juked Cash; please see our Sweepstakes Rules for complete details.`,
      },
      {
        id:3,
        que:'Do I need to verify my identity to use Juked?',
          ans: `Yes. Like other responsible social gaming platforms, every customer on Juked must verify their identity prior to making any purchase or redeeming a prize. Our identity verification process is automated and performed securely by our partners. As part of that process, at a minimum, each customer is required to validate their identity through submission of their social security number and address, and must submit a photo of their valid, government-issued ID with a live selfie photo that matches the submitted photo ID. Additional documentation may be required depending on the value of the prizes won.`,
      },
      ],
    };
  },
  components: {
    Accordion,
  },
};
</script>
