<template>
  <section class="section section-hero-banner-block relative border-b border-b-white/30">
    <img src="/images/landing/bg.png" class="section_bg_images bgl1" />
    <img src="/images/landing/bg.png" class="section_bg_images bgl2" />
    <div class="bg">
      <img class="img-bg bg-1" src="/images/intro/coin-1.svg" alt="coin-img" ref="bg1" />
      <img
        class="img-bg bg-2 hidden lg:block"
        src="/images/intro/coin-2.svg"
        alt="coin-img"
        ref="bg2" />
      <img
        class="img-bg bg-3 hidden lg:block"
        src="/images/intro/coin-3.svg"
        alt="coin-img"
        ref="bg3" />
      <img class="img-bg bg-4" src="/images/intro/coin-4.svg" alt="coin-img" ref="bg4" />
      <img
        class="img-bg bg-5 hidden lg:block"
        src="/images/intro/p-1.svg"
        alt="cash-img"
        ref="bg5" />
      <img class="img-bg bg-6" src="/images/intro/p-2.svg" alt="cash-img" ref="bg6" />
      <img
        class="img-bg bg-7 hidden lg:block"
        src="/images/intro/p-3.svg"
        alt="cash-img"
        ref="bg7" />
      <img class="img-bg bg-8" src="/images/intro/p-4.svg" alt="cash-img" ref="bg8" />
    </div>
    <div class="container m-auto max-lg:h-full">
      <div
        class="content text-center max-lg:h-full max-lg:flex max-lg:flex-col max-lg:justify-between max-lg:px-[10px] gap-[142px]">
        <div
          class="title-wrapper mb-[108px] max-lg:mb-0 max-lg:mt-[108px]"
          ref="titleWrapper">
          <img
            class="block lg:hidden mobile-image"
            src="/images/intro/intro-cartoon.svg"
            alt="img" />
          <h1 class="heading1 first">
            free<div class="!inline lg:!hidden">&nbsp;</div>
            <span><img src="/images/intro/intro-cartoon.svg" alt="img" /></span>fun
          </h1>
          <h1 class="heading1">social sports!</h1>
          <a class="btn btn-purple" href="">Get 200% extra!</a>
        </div>
        <a
          href="#"
          class="btn btn-primary min-w-[370px] max-lg:min-w-full"
          ref="btn1"
          v-on:click="$router.push({ path: $route.path, query: { tab: 'register' } })">
          join now
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from 'gsap';
export default {
  name: 'MainSection',
  mounted() {
    gsap.from(this.$refs.titleWrapper, {
      scale: 0,
      ease: 'back.out(1.9)',
      duration: 0.6,
      delay: 0.5,
    });
    gsap.from(
      this.$refs.bg1,
      { x: -100, opacity: 0, ease: 'back.out(1.9)', duration: 0.6 },
      '<',
    );
    gsap.from(
      this.$refs.bg2,
      { y: -100, opacity: 0, ease: 'back.out(1.9)', duration: 0.6 },
      '<',
    );
    gsap.from(
      this.$refs.bg3,
      { x: 50, opacity: 0, ease: 'back.out(1.9)', duration: 0.6 },
      '<',
    );
    gsap.from(
      this.$refs.bg4,
      { y: 50, opacity: 0, ease: 'back.out(1.9)', duration: 0.6 },
      '<',
    );
    gsap.from(
      this.$refs.bg5,
      { x: -50, opacity: 0, ease: 'back.out(1.9)', duration: 0.6 },
      '<',
    );
    gsap.from(
      this.$refs.bg6,
      { y: 50, opacity: 0, ease: 'back.out(1.9)', duration: 0.6 },
      '<',
    );
    gsap.from(
      this.$refs.bg7,
      { rotate: -50, opacity: 0, ease: 'back.out(1.9)', duration: 0.6 },
      '<',
    );
    gsap.from(
      this.$refs.bg8,
      { x: -50, opacity: 0, ease: 'back.out(1.9)', duration: 0.6 },
      '<',
    );
    gsap.from(this.$refs.btn1, { opacity: 0, duration: 0.9 }, '<');
  },
};
</script>