<template>
  <!-- Close Account Modal -->
  <punt-modal size="max-w-xl" :onClose="closeModal" v-if="confirmDelete">
    <template #body>
      <div class="modal bonus-modal is-active flex flex-col items-center text-slate-300">
        <div class="text-block">
          <h3 class="font-bold mb-3 text-3xl text-center">Confirm Delete Account?</h3>

          <p>
            Once you confirm the deletion of your account, the process will commence. It
            will be fully completed within 30 days. Please note that if you log back into
            your account during this 30-day period, the deletion process will be halted
            and 30-day countdown will be reset.
          </p>
          <p class="mt-3">
            <button
              v-on:click="deleteMyAccount"
              :disabled="isDeleting"
              class="mr-8 inline-block gap-2 rounded-xl bg-accent font-semibold text-white/90 transition delay-0 duration-150 ease-in-out hover:scale-105 hover:opacity-70 md:h-12 md:w-auto px-5 py-3 bg-prim-highlight shadow-btn shadow-accent disabled:opacity-5">
              Confirm
            </button>
            <button
              :disabled="isDeleting"
              v-on:click="closeModal"
              class="gap-2 inline-block rounded-xl bg-secondary font-semibold text-white/90 transition delay-0 duration-150 ease-in-out hover:scale-105 hover:opacity-70 md:h-12 md:w-auto px-5 py-3 bg-prim-highlight shadow-btn shadow-secondary disabled:opacity-5">
              Cancel
            </button>
          </p>
        </div>
      </div>
    </template>
  </punt-modal>

  <div class="flex w-full flex-col items-center privacy-policy-div">
    <h1 style="text-indent: 0pt; text-align: left" class="h1">PRIVACY POLICY</h1>
    <p class="p" style="text-indent: 0pt; text-align: left">
      <br />
    </p>
    <p class="p" style="text-indent: 0pt; text-align: left">
      <br />
    </p>
    <p class="p" style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      VERSION: 1.0
    </p>
    <p
      class="p"
      style="padding-top: 6pt; padding-left: 5pt; text-indent: 0pt; text-align: left">
      DATE UPDATED: 16 November 2022
    </p>
    <p
      class="p"
      style="padding-top: 6pt; padding-left: 5pt; text-indent: 0pt; text-align: left">
      At Juked, we are committed to protecting and respecting your privacy and
      maintaining the confidence and trust of our customers. This privacy policy (“Privacy
      Policy”) explains how your personal information is collected, why it is collected
      and how it is kept secure.
    </p>
    <p
      class="s2 p"
      style="padding-top: 6pt; padding-left: 5pt; text-indent: 0pt; text-align: left">
      <a href="https://juked.co/" class="h3" target="_blank"
        >This Privacy Policy governs the manner in which Juked collect, use, maintain,
        and disclose information collected from users (each, a “User”) of Juked through
      </a>
      <span
        style="
          color: #1154cc;
          font-family: Calibri, sans-serif;
          font-style: normal;
          font-weight: bold;
          text-decoration: underline;
          font-size: 8pt;
        "
        >https://juked.co/</span
      >
      <span style="color: #000"
        >and any subdomains, subpages, successor sites or any application accessible via
        our website (the “Website”). This Privacy Policy applies to the Website and all
        products and services offered through the Website.</span
      >
    </p>
    <p class="p" style="text-indent: 0pt; text-align: left">
      <br />
    </p>
    <ol id="l1">
      <li data-list-text="1.">
        <h2 style="padding-left: 23pt; text-indent: -18pt; text-align: left">
          DATA CONTROLLER
        </h2>
        <ol id="l2">
          <li data-list-text="1.1.">
            <p
              class="p"
              style="
                padding-top: 8pt;
                padding-left: 40pt;
                text-indent: -35pt;
                text-align: left;
              ">
              This Privacy Policy is issued on behalf of Juked so when we mention
              “Juked”, &quot;we&quot;, &quot;us&quot; or &quot;our&quot; in
            </p>
            <p
              class="p"
              style="
                padding-top: 1pt;
                padding-left: 40pt;
                text-indent: 0pt;
                text-align: left;
              ">
              this Privacy Policy, we are referring to the relevant company in the Juked
              responsible for processing your data.
            </p>
            <p class="p" style="text-indent: 0pt; text-align: left">
              <br />
            </p>
          </li>
          <li data-list-text="1.2.">
            <p
              class="p"
              style="
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              The data controller responsible for processing data collected through your
              use of the Website (including any data you may provide through the Website
              when you purchase a product or service or take part in a promotion or
              competition) is Juked.
            </p>
            <p class="p" style="text-indent: 0pt; text-align: left">
              <br />
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="2.">
        <h2 style="padding-left: 23pt; text-indent: -18pt; text-align: left">
          TYPE OF INFORMATION WE COLLECT
        </h2>
        <ol id="l3">
          <li data-list-text="2.1.">
            <p
              class="p"
              style="
                padding-top: 8pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              We collect two basic types of information - personal information (as defined
              below) and anonymous information (as defined below) - and we may use
              personal and anonymous information to create a third type of information,
              aggregate information (also defined below). For example, we collect:
            </p>
            <p class="p" style="text-indent: 0pt; text-align: left">
              <br />
            </p>
            <ol id="l4">
              <li data-list-text="(a)">
                <p
                  class="p"
                  style="padding-left: 66pt; text-indent: -25pt; text-align: left">
                  contact details, such as your first name and surname, email address,
                  residential address and mobile or other phone numbers;
                </p>
                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </li>
              <li data-list-text="(b)">
                <p
                  class="p"
                  style="padding-left: 66pt; text-indent: -25pt; text-align: left">
                  personal information such as gender, age, age range or image;
                </p>
                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </li>
              <li data-list-text="(c)">
                <p
                  class="p"
                  style="padding-left: 66pt; text-indent: -25pt; text-align: left">
                  account login details, including your username, password and pictures
                  associated with your profile;
                </p>
                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </li>
              <li data-list-text="(d)">
                <p
                  class="p"
                  style="padding-left: 66pt; text-indent: -25pt; text-align: justify">
                  transaction information you provide when you make a purchase, request a
                  redemption or otherwise use our Website or contact customer service,
                  such as your postal address, phone number and payment or redemption
                  information including banking and financial details, credit card details
                  and bank account statements
                  <a class="req-postal-code" @click.prevent="getPostalCode"
                    >Request Postal Code</a
                  >
                </p>

                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </li>
              <li data-list-text="(e)">
                <p
                  class="p"
                  style="padding-left: 66pt; text-indent: -25pt; text-align: left">
                  information sent bilaterally or within a small group using text message,
                  live chat, post or other similar methods of communication;
                </p>
                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </li>
              <li data-list-text="(f)">
                <p
                  class="p"
                  style="padding-left: 66pt; text-indent: -25pt; text-align: justify">
                  information we obtain from a third party, such as a site or platform
                  provider (including Facebook), about your use of or interest in our
                  services;
                </p>
                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </li>
              <li data-list-text="(g)">
                <p
                  class="p"
                  style="padding-left: 66pt; text-indent: -25pt; text-align: justify">
                  location information, including location information provided by a
                  mobile or other device interacting with one of our Websites, or
                  associated with your IP address, where we are permitted by law to
                  process this information;
                </p>
              </li>
              <li data-list-text="(h)">
                <p
                  class="p"
                  style="
                    padding-top: 3pt;
                    padding-left: 66pt;
                    text-indent: -25pt;
                    text-align: justify;
                  ">
                  activity, technical and device information about your use of our
                  Website, such as the content you view, the time and duration of your
                  visit on our Website, how often you use our services, how you first
                  heard about our Website, your preferences and information about your
                  interaction with the content offered through our Website, your hardware
                  model, device type, other unique device identifiers, operating system
                  version, browser type and IP address; and
                </p>
                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </li>
              <li data-list-text="(i)">
                <p
                  class="p"
                  style="padding-left: 66pt; text-indent: -25pt; text-align: left">
                  social security numbers where we are required to do so for tax or other
                  legal reasons.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li data-list-text="3.">
        <h2
          style="
            padding-top: 6pt;
            padding-left: 23pt;
            text-indent: -18pt;
            text-align: left;
          ">
          HOW WE COLLECT YOUR INFORMATION
        </h2>
        <ol id="l5">
          <li data-list-text="3.1.">
            <p
              class="p"
              style="
                padding-top: 8pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              We collect information that Users provide to us in a variety of ways,
              including, when Users visit our Website, register on the Website, purchase
              products on our Website, respond to a survey, fill out a form, and in
              connection with other activities, services, features or resources we make
              available on our Website.
            </p>
            <p class="p" style="text-indent: 0pt; text-align: left">
              <br />
            </p>
            <p class="p" style="padding-left: 40pt; text-indent: 0pt; text-align: left">
              You warrant any contact information you provide, included (but not limited
              to), your e-mail address and mobile phone number is true and accurate. You
              further warrant that you are the telephone subscriber and/or that you own
              any telephone numbers that you provide to Juked. You acknowledge that by
              voluntarily providing your telephone numbers to Juked, you expressly agree
              to be contacted at the telephone numbers you provide.
            </p>
            <p class="p" style="padding-left: 40pt; text-indent: 0pt; text-align: left">
              You consent to receive pre-recorded voice messages and/or autodialled calls
              (including text messages) by or on behalf of Juked relating to:
            </p>
            <p class="p" style="text-indent: 0pt; text-align: left">
              <br />
            </p>
            <ol id="l6">
              <li data-list-text="(a)">
                <p
                  class="p"
                  style="padding-left: 77pt; text-indent: -36pt; text-align: left">
                  this Privacy Policy and any associated terms and conditions;
                </p>
                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </li>
              <li data-list-text="(b)">
                <p
                  class="p"
                  style="padding-left: 77pt; text-indent: -36pt; text-align: left">
                  any transaction with Juked related to your account; and
                </p>
                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </li>
              <li data-list-text="(c)">
                <p
                  class="p"
                  style="padding-left: 77pt; text-indent: -36pt; text-align: left">
                  promotions from Juked.
                </p>
                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
                <p
                  class="p"
                  style="padding-left: 40pt; text-indent: 0pt; text-align: left">
                  The above communications may be made by or on behalf of Juked, even if
                  your phone number is registered on any state or federal Do Not Call
                  list. You acknowledge that you may incur a charge for these texts or
                  calls by your telephone carrier and that Juked will not be responsible
                  for these charges.
                </p>
              </li>
            </ol>
          </li>
          <li data-list-text="3.2.">
            <p
              class="p"
              style="
                padding-top: 6pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              In addition to collecting information via our Website, we collect
              information from Users by telephone, video or in correspondence (including
              email, live chat, mail, text message or social media). All telephone or
              video calls between Users and Juked representatives may be recorded for
              training, quality control and compliance purposes and by continuing with any
              such call you consent to it being recorded.
            </p>
          </li>
          <li data-list-text="3.3.">
            <p
              class="p"
              style="
                padding-top: 5pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              We also collect information about your use of our products and services
              through a variety of technologies that are present when you visit our
              Websites or use our applications on third party sites or platforms (whether
              or not you are logged in or registered) including cookies, flash cookies,
              pixels, tags and application program interfaces (“API”). Please see the
              section entitled “Personal Data Collected via Technology” below for further
              information.
            </p>
          </li>
          <li data-list-text="3.4.">
            <p
              class="p"
              style="
                padding-top: 5pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              Analytics tools are also used by us to collect information, including when
              you visit our Website or use our applications or services on third party
              sites or platforms.
            </p>
          </li>
          <li data-list-text="3.5.">
            <p
              class="p"
              style="
                padding-top: 5pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              Finally, we also use trusted sources to update or supplement the information
              you provided, or we collected, including in order to verify or validate
              information (for example, postal address, documentation, and names).
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="4.">
        <h2
          style="
            padding-top: 6pt;
            padding-left: 23pt;
            text-indent: -18pt;
            text-align: left;
          ">
          HOW WE USE COLLECTED INFORMATION
        </h2>
        <ol id="l7">
          <li data-list-text="4.1.">
            <p
              class="p"
              style="
                padding-top: 8pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              The Data Controller is responsible for and may use your information for the
              purposes described in this Privacy Policy. Other members of Juked may
              access your information where they act on behalf of the Data Controller as a
              data processor for the purposes described in this Privacy Policy. In
              summary, we use the information collected to operate our Website and to
              provide our products and services, to facilitate your financial
              transactions, to respond to your questions and to fulfill all applicable
              regulatory requirements such as anti-fraud checks and other checks required
              by applicable anti-money laundering and other legislation.
            </p>
          </li>
          <li data-list-text="4.2.">
            <p
              class="p"
              style="
                padding-top: 5pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 113%;
                text-align: left;
              ">
              In accordance with applicable law and any elections made available to you,
              Juked may collect and use the information of Users for the following
              specific purposes:
            </p>
            <p class="p" style="text-indent: 0pt; text-align: left">
              <br />
            </p>
            <ol id="l8">
              <li data-list-text="(a)">
                <p
                  class="p"
                  style="padding-left: 66pt; text-indent: -25pt; text-align: justify">
                  To provide and manage the services you request - This includes, for
                  example, processing your registration and setting up and looking after
                  your account, including checks made to guard against fraud.
                </p>
                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </li>
              <li data-list-text="(b)">
                <p
                  class="p"
                  style="padding-left: 66pt; text-indent: -25pt; text-align: justify">
                  To improve customer service and our services - Information you provide
                  helps us respond to your customer service requests more efficiently; we
                  may use feedback you provide to improve our products and services.
                </p>
                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </li>
              <li data-list-text="(c)">
                <p
                  class="p"
                  style="padding-left: 66pt; text-indent: -25pt; text-align: justify">
                  To process payments - We may use the information Users provide about
                  themselves when placing an order to service that order (including to
                  satisfy our customer due diligence requirements and to establish the
                  source of funds where necessary). We do not share this information with
                  third parties except to the extent necessary to provide the service.
                </p>
                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </li>
              <li data-list-text="(d)">
                <p
                  class="p"
                  style="padding-left: 66pt; text-indent: -25pt; text-align: justify">
                  To personalize user experience - We may use information which has been
                  aggregated to understand how our Users as a group use the services and
                  resources provided on our Website.
                </p>
                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </li>
              <li data-list-text="(e)">
                <p
                  class="p"
                  style="padding-left: 66pt; text-indent: -25pt; text-align: justify">
                  To contact you about our services - For example by email, live chat,
                  telephone, text message, mail, or social media; to manage promotions and
                  competitions you choose to enter. We may periodically contact you with
                </p>
                <p
                  class="p"
                  style="
                    padding-top: 2pt;
                    padding-left: 66pt;
                    text-indent: 0pt;
                    text-align: justify;
                  ">
                  offers and information about our products and services, including in
                  connection with your accounts, promotions, contests, online surveys, new
                  features that you are entitled to access, and other important
                  information. As set out in clause 3.1, provision of your telephone
                  information will be deemed as your consent to receive text messages sent
                  by automatic telephone dialing systems. Consent to receive direct
                  advertising is not a condition of purchase.
                </p>
                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </li>
              <li data-list-text="(f)">
                <p
                  class="p"
                  style="padding-left: 66pt; text-indent: -25pt; text-align: justify">
                  To send important notices to you - From time to time we may send
                  important notices to you, such as communications about purchases you
                  have made, or legal notices regarding the changes to our terms and
                  conditions or other policies. This information is important to your
                  interactions with us and you acknowledge that you may not opt-out of
                  receiving these communications.
                </p>
                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </li>
              <li data-list-text="(g)">
                <p
                  class="p"
                  style="padding-left: 66pt; text-indent: -25pt; text-align: justify">
                  To comply with our legal and regulatory obligations - We may use
                  information we collect about you to assess your social gameplay activity
                  for responsible social gameplay purposes, for taxation purposes, to
                  comply with fraud and anti-money laundering laws (including to establish
                  the source of funds where a transaction is involved) or to comply with
                  any other legal or regulatory obligations (including detection,
                  investigation and prevention of activity that violates our terms and
                  conditions or that may be illegal).
                </p>
                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </li>
              <li data-list-text="(h)">
                <p
                  class="p"
                  style="padding-left: 66pt; text-indent: -25pt; text-align: justify">
                  To offer alternative dispute resolution services - We may provide
                  information we collect about you to our third party partner for the
                  specific purpose of resolving any disputes that have not been
                  satisfactorily resolved through our internal processes.
                </p>
                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </li>
            </ol>
          </li>
          <li data-list-text="4.3.">
            <p class="p" style="padding-left: 40pt; text-indent: -35pt; text-align: left">
              We do not use automated decision making within the meaning of European data
              protection laws.
            </p>
            <p class="p" style="text-indent: 0pt; text-align: left">
              <br />
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="5.">
        <h2 style="padding-left: 23pt; text-indent: -18pt; text-align: left">
          PERSONAL DATA COLLECTED VIA TECHNOLOGY
        </h2>
        <ol id="l9">
          <li data-list-text="5.1.">
            <p
              class="p"
              style="
                padding-top: 8pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: justify;
              ">
              When you interact with our Website, we try to make that experience simple
              and useful. We and our partners use industry standard identifiers, such as
              cookies or other similar technologies.
            </p>
          </li>
          <li data-list-text="5.2.">
            <p
              class="p"
              style="
                padding-top: 5pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: justify;
              ">
              Our Website may use technologies to enhance User experience. These
              technologies are small files that are placed on your computer, tablet,
              mobile phone or other devices when you visit a website. They allow us to
              record certain pieces of information whenever you visit or interact with the
              website.
            </p>
          </li>
          <li data-list-text="5.3.">
            <p
              class="p"
              style="
                padding-top: 5pt;
                padding-left: 40pt;
                text-indent: -35pt;
                text-align: left;
              ">
              The following are examples of these types of technologies:
            </p>
            <p class="p" style="text-indent: 0pt; text-align: left">
              <br />
            </p>
            <ol id="l10">
              <li data-list-text="(a)">
                <p
                  class="p"
                  style="padding-left: 66pt; text-indent: -25pt; text-align: justify">
                  Cookies. These are small text files placed in your device browsers to
                  store your preferences. Most browsers allow you to block and delete
                  cookies. However, if you do that, the service may not work properly.
                </p>
                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </li>
              <li data-list-text="(b)">
                <p
                  class="p"
                  style="padding-left: 66pt; text-indent: -25pt; text-align: justify">
                  Pixel tags/web beacons. A pixel tag (also known as a web beacon) is a
                  piece of code embedded on the Website that collects information about
                  Users’ engagement on that web page. The use of a pixel allows us to
                  record, for example, that a user has visited a particular web page or
                  clicked on a particular advertisement. We may also include web beacons
                  in e-mails to understand whether messages have been opened, acted on or
                  forwarded.
                </p>
                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </li>
              <li data-list-text="(c)">
                <p
                  class="p"
                  style="padding-left: 66pt; text-indent: -25pt; text-align: left">
                  Mobile device identifiers. We may share the advertising identifier
                  associated with your mobile device or tablet (such as the Apple IDFA and
                  Google Advertising ID) with advertisers. It is used to identify your
                  device for internet-based advertising.
                </p>
                <div
                  class="p"
                  style="padding-left: 66pt; text-indent: 0pt; text-align: justify">
                  <a
                    href="http://www.networkadvertising.org/mobile-choices"
                    class="h3"
                    target="_blank"
                    >For instructions for using the advertising choice mechanisms provided
                    by the most popular mobile operating systems, visit the NAI’s Mobile
                    Choices page
                  </a>
                  <a
                    href="http://www.networkadvertising.org/mobile-choices"
                    class="a"
                    target="_blank"
                    >here.</a
                  >
                </div>
                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </li>
              <li data-list-text="(d)">
                <p
                  class="p"
                  style="padding-left: 66pt; text-indent: -25pt; text-align: justify">
                  Event tagging. Event tagging (including Facebook App Events and Playfab
                  Events) allows us to track actions that occur in the Website such as
                  application installs and purchase events. By tracking these events we
                  can view analytics, measure ad performance, and build audiences for ad
                  targeting.
                </p>
                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
                <p
                  class="p"
                  style="padding-left: 41pt; text-indent: 0pt; text-align: left">
                  Behavioral Advertising
                </p>
              </li>
            </ol>
          </li>
          <li data-list-text="5.4.">
            <p
              class="p"
              style="
                padding-top: 6pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              We may partner with a third party to either display advertising on our
              Website or any application accessible via our Website or to manage our
              advertising on other websites (for example, using custom audiences on
              Facebook). Our third party partner may use technologies such as cookies to
              gather information about your activities on our Website and other websites
              in order to provide you advertising based on your browsing activities and
              interests.
            </p>
          </li>
          <li data-list-text="5.5.">
            <p
              class="s3 p"
              style="
                padding-top: 5pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              <a href="https://preferences-mgr.truste.com/" class="h3" target="_blank"
                >If you wish to opt-out of interest-based advertising click
              </a>
              <span
                style="
                  color: #00f;
                  font-family: Calibri, sans-serif;
                  font-style: normal;
                  font-weight: bold;
                  text-decoration: underline;
                  font-size: 8pt;
                "
                >here</span
              >
              <a href="https://www.youronlinechoices.eu/" class="h3" target="_blank"
                >(or if located in the European Union click
              </a>
              <span
                style="
                  color: #00f;
                  font-family: Calibri, sans-serif;
                  font-style: normal;
                  font-weight: bold;
                  text-decoration: underline;
                  font-size: 8pt;
                "
                >here</span
              >
              <span style="color: #000"
                >). Please note you will continue to receive generic advertisements</span
              >
              <span style="color: #122a40">.</span>
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="6.">
        <h2
          style="
            padding-top: 6pt;
            padding-left: 23pt;
            text-indent: -18pt;
            text-align: left;
          ">
          YOUR RIGHTS OVER YOUR INFORMATION
        </h2>
        <p class="p" style="text-indent: 0pt; text-align: left">
          <br />
        </p>
        <p class="p" style="padding-left: 40pt; text-indent: 0pt; text-align: left">
          General Rights
        </p>
        <p class="p" style="text-indent: 0pt; text-align: left">
          <br />
        </p>
        <ol id="l11">
          <li data-list-text="6.1.">
            <p
              class="p"
              style="
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              We appreciate that by law and subject to certain conditions, you have a
              number of rights concerning the personal information we hold about you.
              These rights include the right to access the personal information we hold
              about you, the right to object to the processing of your data and the right
              to withdraw any consent provided. In some circumstances, you can ask us to
              delete the personal information we hold about you.
            </p>
          </li>
          <li data-list-text="6.2.">
            <p
              class="p"
              style="
                padding-top: 5pt;
                padding-left: 41pt;
                text-indent: -36pt;
                line-height: 217%;
                text-align: left;
              ">
              <a href="mailto:help@juked.co" class="h3" target="_blank"
                >To exercise the right described at clause 6.1, please email
                support@juked.co</a
              >m Right to complain to a supervisory authority
            </p>
          </li>
          <li data-list-text="6.3.">
            <p
              class="p"
              style="
                padding-top: 2pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              You also have the right to complain to the relevant data protection
              supervisory authority about our processing of your personally identifiable
              information.
            </p>
            <p class="p" style="text-indent: 0pt; text-align: left">
              <br />
            </p>
            <p class="p" style="padding-left: 40pt; text-indent: 0pt; text-align: left">
              Rights in direct advertising
            </p>
          </li>
          <li data-list-text="6.4.">
            <p
              class="p"
              style="
                padding-top: 6pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              If we process your personally identifiable information for the purpose of
              direct advertising, you have the right to object at any time to the
              processing of personally identifiable information relating to you for the
              purpose of such advertising; this also applies to profiling, where
              appropriate, insofar as it is associated with such direct advertising.
            </p>
          </li>
          <li data-list-text="6.5.">
            <p
              class="p"
              style="
                padding-top: 5pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              <a href="mailto:help@juked.co" class="h3" target="_blank"
                >You may opt-out of receiving commercial messages from us by following the
                instructions contained in those messages or by contacting Customer Support
                at
              </a>
              <a href="mailto:support@globalpoker.com" class="h3" target="_blank"
                >help@juked.co</a
              >. In the event of your objection to processing for the purpose of direct
              advertising, we will no longer process your personally identifiable
              information for these purposes.
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="7.">
        <h2
          style="
            padding-top: 6pt;
            padding-left: 23pt;
            text-indent: -18pt;
            text-align: left;
          ">
          HOW WE PROTECT YOUR INFORMATION
        </h2>
        <ol id="l12">
          <li data-list-text="7.1.">
            <p
              class="p"
              style="
                padding-top: 8pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              We have in place physical, electronic and operational procedures to protect
              the information that we collect. We adopt appropriate data collection,
              storage and processing practices and security measures to protect against
              unauthorized access, alteration, disclosure or destruction of your personal
              information, username, password, transaction information and data stored on
              our Website. Our security measures are reviewed regularly and updated in
              keeping with technological advances.
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="8.">
        <h2
          style="
            padding-top: 6pt;
            padding-left: 23pt;
            text-indent: -18pt;
            text-align: left;
          ">
          HOW LONG WILL WE RETAIN YOUR INFORMATION
        </h2>
        <p class="p" style="text-indent: 0pt; text-align: left">
          <br />
        </p>
        <p
          class="p"
          style="
            padding-left: 40pt;
            text-indent: 0pt;
            line-height: 108%;
            text-align: left;
          ">
          We will retain your information for the period of time required to fulfill the
          purposes outlined in this Privacy Policy unless a longer retention period is
          required or permitted by law.
        </p>
        <p class="p" style="text-indent: 0pt; text-align: left">
          <br />
        </p>
      </li>
      <li data-list-text="9.">
        <h2 style="padding-left: 23pt; text-indent: -18pt; text-align: left">
          SHARING YOUR INFORMATION
        </h2>
        <ol id="l13">
          <li data-list-text="9.1.">
            <p
              class="p"
              style="
                padding-top: 8pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              We do not sell, trade, or rent the personal identification information of
              Users to others.However, there are circumstances when we share your personal
              data with other companies in the Juked, with third parties that provide
              services to you on our behalf, and with other third parties in the course of
              complying with our legal obligations.
            </p>
            <p class="p" style="text-indent: 0pt; text-align: left">
              <br />
            </p>
            <p class="p" style="padding-left: 40pt; text-indent: 0pt; text-align: left">
              With other companies within Juked
            </p>
            <p class="p" style="text-indent: 0pt; text-align: left">
              <br />
            </p>
            <p class="p" style="padding-left: 40pt; text-indent: 0pt; text-align: left">
              We may share the information we collect with other companies in the Juked
              for the purposes set out in this Privacy Policy:
            </p>
          </li>
          <li data-list-text="9.2.">
            <p
              class="p"
              style="
                padding-top: 6pt;
                padding-left: 40pt;
                text-indent: -35pt;
                text-align: left;
              ">
              Please see Transfers below for further information on how we share
              information within the Juked.
            </p>
            <p class="p" style="text-indent: 0pt; text-align: left">
              <br />
            </p>
            <p class="p" style="padding-left: 40pt; text-indent: 0pt; text-align: left">
              With third parties
            </p>
          </li>
          <li data-list-text="9.3.">
            <p
              class="p"
              style="
                padding-top: 6pt;
                padding-left: 40pt;
                text-indent: -35pt;
                text-align: left;
              ">
              We only share information with third parties in limited circumstances
              including the following:
            </p>
            <p class="p" style="text-indent: 0pt; text-align: left">
              <br />
            </p>
            <ol id="l14">
              <li data-list-text="(a)">
                <p
                  class="p"
                  style="padding-left: 66pt; text-indent: -25pt; text-align: left">
                  when you allow us to share your information with third parties;
                </p>
                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </li>
              <li data-list-text="(b)">
                <p
                  class="p"
                  style="padding-left: 66pt; text-indent: -25pt; text-align: left">
                  when providing you with products and services and notifying you about
                  either important changes or developments to the features and operation
                  of those products and services;
                </p>
                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </li>
              <li data-list-text="(c)">
                <p
                  class="p"
                  style="padding-left: 66pt; text-indent: -25pt; text-align: left">
                  with our service providers to enable us to provide our services, such as
                  companies that help us with technology services, storing and combining
                  data, and processing payments and redemptions or providing relevant
                  marketing and advertising for our products and services. The partner
                  companies will have access to your personal information only to perform
                  services on our behalf and are prohibited from using your personal
                  information for purposes other than those requested by us or required by
                  law. Where service providers use personal information for their own
                  purposes, where permitted by law, such service provider will act as data
                  controller;
                </p>
                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </li>
              <li data-list-text="(d)">
                <p
                  class="p"
                  style="padding-left: 66pt; text-indent: -25pt; text-align: left">
                  in response to lawful requests by public authorities, including to meet
                  national security or law enforcement requirements, when ordered to do so
                  by any regulatory body and/or under any legal provision contained in the
                  governing law of a particular jurisdiction;
                </p>
                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </li>
              <li data-list-text="(e)">
                <p
                  class="p"
                  style="padding-left: 66pt; text-indent: -25pt; text-align: left">
                  when instructing and authorizing the financial institution with which a
                  User’s account is held to disclose any information as may be requested
                  by a regulator in respect of the User’s account;
                </p>
                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </li>
              <li data-list-text="(f)">
                <p
                  class="p"
                  style="padding-left: 66pt; text-indent: -25pt; text-align: left">
                  when we share your personal information with third parties to enforce
                  our terms and conditions set out on our Website, to protect our rights
                  and property and the rights and property of our customers and third
                  parties, to detect fraud or other illegal activities, and to comply with
                  law or legal processes; and
                </p>
                <p class="p" style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </li>
              <li data-list-text="(g)">
                <p
                  class="p"
                  style="padding-left: 66pt; text-indent: -25pt; text-align: left">
                  to perform customer due diligence including ID verification.
                </p>
              </li>
            </ol>
          </li>
          <li data-list-text="9.4.">
            <div
              class="s3 p"
              style="
                padding-top: 6pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              <a
                href="https://maps.google.com/help/terms_maps.html"
                class="h3"
                target="_blank"
                >We make available an API provided by Google Maps in order to allow Users’
                to have access to an address auto- complete function on our Website. The
                use of Google Maps for this purpose by the User is subject to the then-
                current Google Maps/Google Earth Additional Terms of Service at
              </a>
              <a
                href="https://maps.google.com/help/terms_maps.html"
                class="a"
                target="_blank"
                >https://maps.google.com/help/terms_maps.htm</a
              >l
              <a
                href="https://www.google.com/policies/privacy/"
                class="h3"
                target="_blank"
                >and Google Privacy Policy at
              </a>
              <a href="https://www.google.com/policies/privacy/" class="a" target="_blank"
                >https://www.google.com/policies/privacy/</a
              >
            </div>
          </li>
          <li data-list-text="9.5.">
            <p
              class="p"
              style="
                padding-top: 2pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              We may ask you to provide your image to assist us in verifying your
              identity. We do this by using facial recognition technology provided by
              third parties (including, but not limited to, Veriff) that determines
              whether the photo you take matches the photo in your identification
              document. The facial recognition technology provided by these third parties
              collects information from your image capture, including biometric data, and
              may share this information with us. Biometric data is stored by the third
              party service provider in accordance with its privacy policy and is stored
              by us, until such time as the initial purpose for collecting or obtaining
              such information has been satisfied or within 3 years of your last
              interaction with us, whichever occurs first, provided we have no other legal
              obligation to retain such information for any longer period.
            </p>
          </li>
          <li data-list-text="9.6.">
            <p
              class="p"
              style="
                padding-top: 5pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              We may also share generic aggregated demographic information not linked to
              any personal identification information regarding visitors and Users with
              our business partners, trusted affiliates and advertisers for the purposes
              outlined above.
            </p>
            <p class="p" style="text-indent: 0pt; text-align: left">
              <br />
            </p>
            <p class="p" style="padding-left: 40pt; text-indent: 0pt; text-align: left">
              Transfers
            </p>
          </li>
          <li data-list-text="9.7.">
            <p
              class="p"
              style="
                padding-top: 6pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              Juked operates in several international jurisdictions and personal
              information we collect may be transferred to, and stored and processed by,
              individual companies in the Juked or third parties in, the European Union,
              the United States, Australia, India or any other country in which we or our
              third party processors maintain facilities. We will ensure that transfers of
              personal information to any country or an international organization are
              subject to appropriate safeguards. Additionally, without limitation where
              applicable, when transferring personal information from the European Union,
              we may use standard contractual clauses approved by the European Commission
              or otherwise adopt other means in accordance with European data protection
              laws for ensuring adequate safeguards.
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="10.">
        <h2
          style="
            padding-top: 6pt;
            padding-left: 23pt;
            text-indent: -18pt;
            text-align: left;
          ">
          OTHER IMPORTANT INFORMATION
        </h2>
        <p class="p" style="text-indent: 0pt; text-align: left">
          <br />
        </p>
        <p class="p" style="padding-left: 40pt; text-indent: 0pt; text-align: left">
          Compliance with Children’s Online Privacy Protection Act
        </p>
        <p class="p" style="text-indent: 0pt; text-align: left">
          <br />
        </p>
        <ol id="l15">
          <li data-list-text="10.1.">
            <p
              class="p"
              style="
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              Protecting the privacy of the very young is especially important. For that
              reason, we never collect or maintain information on our Website from
              individuals we actually know are under the age of 18, and no part of our
              Website is structured to attract anyone under the age of 18.
            </p>
            <p class="p" style="text-indent: 0pt; text-align: left">
              <br />
            </p>
            <p class="p" style="padding-left: 41pt; text-indent: 0pt; text-align: left">
              Your California Privacy Rights
            </p>
            <p class="p" style="text-indent: 0pt; text-align: left">
              <br />
            </p>
            <p
              class="p"
              style="
                padding-left: 40pt;
                text-indent: 0pt;
                line-height: 108%;
                text-align: left;
              ">
              California residents may have specific rights regarding their personal
              information, including accessing the personal information we’ve collected
              about you during the past 12 months and information about our data practice.
              You may also have the right to request, subject to certain exceptions, that
              we delete the personal information we have collected from you.
            </p>
            <p class="p" style="text-indent: 0pt; text-align: left">
              <br />
            </p>
            <p class="p" style="padding-left: 40pt; text-indent: 0pt; text-align: left">
              Exercising Access and Deletion Rights
            </p>
            <p class="p" style="text-indent: 0pt; text-align: left">
              <br />
            </p>
            <p class="p" style="padding-left: 40pt; text-indent: 0pt; text-align: left">
              <a href="mailto:help@juked.co" class="h3" target="_blank"
                >To request access to or deletion of your personal information, please
                submit a verifiable consumer request to us at
              </a>
              <a href="mailto:help@juked.co" class="s1" target="_blank"
                >help@juked.co</a
              >
            </p>
            <p class="p" style="text-indent: 0pt; text-align: left">
              <br />
            </p>
            <p
              class="p"
              style="
                padding-left: 40pt;
                text-indent: 0pt;
                line-height: 108%;
                text-align: left;
              ">
              Only you or a person registered with the California Secretary of State that
              you authorize to act on your behalf may make a verifiable consumer request
              related to your personal information. If you decide to use an authorized
              agent, please also include written permission that you have designated that
              agent to make this request, or proof of the agent’s power of attorney. We
              may follow up with you to verify your identity before processing your
              authorized agent’s request. You may also make a verifiable consumer request
              on behalf of your minor child.
            </p>
            <p class="p" style="text-indent: 0pt; text-align: left">
              <br />
            </p>
            <p class="p" style="padding-left: 40pt; text-indent: 0pt; text-align: left">
              For your consumer request to be verifiable, you must:
            </p>
            <p class="p" style="text-indent: 0pt; text-align: left">
              <br />
            </p>
            <p
              class="p"
              style="
                padding-left: 40pt;
                text-indent: 0pt;
                line-height: 108%;
                text-align: left;
              ">
              Provide sufficient information that allows us to reasonably verify you are
              the person about whom we collected personal information or an authorized
              representative. You will need to provide:
            </p>
            <ul id="l16">
              <li data-list-text="●">
                <p
                  class="p"
                  style="
                    padding-left: 71pt;
                    text-indent: -18pt;
                    line-height: 10pt;
                    text-align: left;
                  ">
                  Full name
                </p>
              </li>
              <li data-list-text="●">
                <p
                  class="p"
                  style="padding-left: 71pt; text-indent: -18pt; text-align: left">
                  Date of birth
                </p>
              </li>
              <li data-list-text="●">
                <p
                  class="p"
                  style="padding-left: 71pt; text-indent: -18pt; text-align: left">
                  Address
                </p>
              </li>
              <li data-list-text="●">
                <p
                  class="p"
                  style="padding-left: 71pt; text-indent: -18pt; text-align: left">
                  E-mail address
                </p>
              </li>
              <li data-list-text="●">
                <p
                  class="p"
                  style="padding-left: 71pt; text-indent: -18pt; text-align: left">
                  Whether you are a California consumer pursuant to CCPA
                </p>
              </li>
              <li data-list-text="●">
                <p
                  class="p"
                  style="
                    padding-left: 71pt;
                    text-indent: -18pt;
                    line-height: 108%;
                    text-align: left;
                  ">
                  IF you would like to know the specific pieces of personal information
                  that we have collected about you, or to delete your personal
                  information, identification that clearly shows your name, date of birth
                  and address (For example, passport or other photo identification)
                </p>
              </li>
            </ul>
            <p class="p" style="text-indent: 0pt; text-align: left">
              <br />
            </p>
            <p class="p" style="padding-left: 41pt; text-indent: 0pt; text-align: left">
              Do-Not-Track Signals and Similar Mechanisms.
            </p>
          </li>
          <li data-list-text="10.2.">
            <p
              class="p"
              style="
                padding-top: 6pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              Some web browsers may transmit “do-not-track” signals to the websites with
              which the user communicates. Due to the different ways that web browsers
              incorporate and activate this feature, it is not always clear whether users
              intend for these signals to be transmitted, or whether they even are aware
              of them. As there currently is no industry standard concerning what, if
              anything, websites should do when they receive such signals, our Website
              currently does not take action in response to these signals.
            </p>
            <p class="p" style="text-indent: 0pt; text-align: left">
              <br />
            </p>
            <p class="p" style="padding-left: 40pt; text-indent: 0pt; text-align: left">
              If and when a final standard is established and accepted, we will reassess
              how to respond to these signals.
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="11.">
        <h2
          style="
            padding-top: 6pt;
            padding-left: 23pt;
            text-indent: -18pt;
            text-align: left;
          ">
          CHANGES TO THIS PRIVACY POLICY
        </h2>
        <ol id="l17">
          <li data-list-text="11.1.">
            <p
              class="p"
              style="
                padding-top: 8pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: justify;
              ">
              This Privacy Policy may be updated from time to time to reflect changes in
              the way we work or the way our work is regulated. We will notify you of
              material changes and, where required by law, will obtain your consent.
              Notice may be by the posting of such changes on our Website or by other
              means in accordance with applicable law.
            </p>
          </li>
          <li data-list-text="11.2.">
            <p
              class="p"
              style="
                padding-top: 5pt;
                padding-left: 40pt;
                text-indent: -35pt;
                text-align: left;
              ">
              Any changes to the Privacy Policy will become effective when the updated
              policy is posted on our Website.
            </p>
            <p class="p" style="text-indent: 0pt; text-align: left">
              <br />
            </p>
          </li>
          <li data-list-text="11.3.">
            <p
              class="p"
              style="
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              We encourage Users to frequently check this page for any changes to stay
              informed about how we are helping to protect the personal information we
              collect.
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="12.">
        <h2
          style="
            padding-top: 1pt;
            padding-left: 23pt;
            text-indent: -18pt;
            text-align: left;
          ">
          DEFINITIONS
        </h2>
        <ol id="l18">
          <li data-list-text="12.1.">
            <p
              class="p"
              style="
                padding-top: 8pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              Personal information means information that identifies (whether directly or
              indirectly) a particular individual, such as the individual’s name, postal
              address, email address, and telephone number. When anonymous information is
              directly or indirectly associated with personal information, the resulting
              information is also treated as personal information.
            </p>
          </li>
          <li data-list-text="12.2.">
            <p
              class="p"
              style="
                padding-top: 5pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              Anonymous information means information that does not directly or indirectly
              identify, and cannot reasonably be used to identify, an individual.
            </p>
          </li>
          <li data-list-text="12.3.">
            <p
              class="p"
              style="
                padding-top: 6pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              Aggregate information means information about groups or categories of
              customers, which does not identify and cannot reasonably be used to identify
              an individual customer.
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="13.">
        <h2
          style="
            padding-top: 6pt;
            padding-left: 23pt;
            text-indent: -18pt;
            text-align: left;
          ">
          CONTACTING US
        </h2>
        <ol id="l19">
          <li data-list-text="13.1.">
            <p
              class="p"
              style="
                padding-top: 8pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              If you have specific questions regarding your personal information or how we
              use it, please contact our Data Protection Officer directly by email at the
              following email address
              <a href="mailto:help@juked.co" target="_blank">help@juked.co</a>
              and specify your place of residence and the nature of your question.
            </p>
          </li>
        </ol>
      </li>

      <li data-list-text="14.">
        <h2
          style="
            padding-top: 6pt;
            padding-left: 23pt;
            text-indent: -18pt;
            text-align: left;
          ">
          ACCOUNT CLOSURE
        </h2>
        <ol id="l19">
          <li data-list-text="14.1.">
            <p
              class="p"
              style="
                padding-top: 8pt;
                padding-left: 40pt;
                text-indent: -35pt;
                line-height: 114%;
                text-align: left;
              ">
              If you would like to close your account, please click
              <span
                style="
                  color: #00f;
                  font-family: Calibri, sans-serif;
                  font-style: normal;
                  font-weight: bold;
                  text-decoration: underline;
                  font-size: 10pt;
                "
                v-on:click="closeAccountConfirmation()"
                >here</span
              >. Once you confirm the deletion of your account, the process will commence.
              It will be fully completed within 30 days. Please note that if you log back
              into your account during this 30-day period, the deletion process will be
              halted, and the 30-day countdown will reset. Kindly note that once you start
              the account closure process, you will lose access to live support. If you
              still need access to support, you can always email us at:
              <a href="mailto:help@juked.co" target="_blank">help@juked.co</a>
            </p>
          </li>
        </ol>
      </li>
    </ol>
  </div>
</template>

<script>
import axios from '@/lib/axiosConfig';
import { doRecaptcha } from '@/lib/recaptcha';
import { mapGetters } from 'vuex';
import PuntModal from '@/components/Shared/PuntModal.vue';
export default {
  data() {
    return {
      confirmDelete: false,
      isDeleting: false,
      postalCode: '',
    };
  },
  components: { PuntModal },
  created() {
  },
  computed: {
    ...mapGetters(['me', 'isLoggedIn']),
  },
  methods: {
    async deleteMyAccount() {
      if (this.me.id === null || this.me.id === undefined) {
        this.$toast.error('No User Found Please refresh and retry!');
        this.$router.push({ path: '/?tab=login' }).then(() => {
          this.$router.go();
        });
        return;
      }
      this.isDeleting = true;
      try {
        const response = await axios.post('/user/close-account', { user_id: this.me.id });
        this.confirmDelete = false;
        this.isDeleting = false;
        this.$toast.success(response.data.message);
        this.$store.dispatch('logout');
        this.$router.push({ path: '/' }).then(() => {
          this.$router.go();
        });
      } catch (e) {
        this.isDeleting = false;
        this.confirmDelete = false;
        this.$toast.error('Error! ' + ((typeof e.data.error != 'undefined')?e.data.error:''));
      }
    },
    closeModal() {
      this.confirmDelete = false;
    },
    closeAccountConfirmation() {
      if (this.me.id === null || this.me.id === undefined) {
        this.$toast.error('Please Login to request your account delete!');
        this.$router.push({ path: '/?tab=login' });
      } else {
        this.confirmDelete = true;
      }
    },
  },
};
</script>

<style scoped>
.bonus-modal {
  padding-top: 20% !important;
}
h3.font-bold.mb-3.text-3xl.text-center {
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 2.25rem;
  text-align: center;
}
/* p.mt-3{
  margin: ;
} */
/* * {
  margin: 0;
  padding: 0;
  text-indent: 0;
} */
.h1 {
  color: white;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 18pt;
}
.p {
  color: white;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 10pt;
  margin: 0pt;
}
.s1 {
  color: #1154cc;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: underline;
  font-size: 8pt;
}
.s2 {
  color: #1154cc;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 8pt;
}
h2 {
  color: white;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 12pt;
}
.a {
  color: #00f;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: underline;
  font-size: 8pt;
}
.h3,
h3 {
  color: white;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 8pt;
}
.s3 {
  color: #00f;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 8pt;
}
li {
  display: block;
}
#l1 {
  padding-left: 0pt;
  counter-reset: c1 1;
}
#l1 > li > *:first-child:before {
  counter-increment: c1;
  content: counter(c1, decimal) '. ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
#l1 > li:first-child > *:first-child:before {
  counter-increment: c1 0;
}
#l2 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l2 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) '.' counter(c2, decimal) '. ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
#l2 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l3 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l3 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) '.' counter(c2, decimal) '. ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
#l3 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l4 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l4 > li > *:first-child:before {
  counter-increment: c3;
  content: '(' counter(c3, lower-latin) ') ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
#l4 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l5 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l5 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) '.' counter(c2, decimal) '. ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
#l5 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l6 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l6 > li > *:first-child:before {
  counter-increment: c3;
  content: '(' counter(c3, lower-latin) ') ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
#l6 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l7 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l7 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) '.' counter(c2, decimal) '. ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
#l7 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l8 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l8 > li > *:first-child:before {
  counter-increment: c3;
  content: '(' counter(c3, lower-latin) ') ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
#l8 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l9 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l9 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) '.' counter(c2, decimal) '. ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
#l9 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l10 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l10 > li > *:first-child:before {
  counter-increment: c3;
  content: '(' counter(c3, lower-latin) ') ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
#l10 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l11 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l11 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) '.' counter(c2, decimal) '. ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
#l11 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l12 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l12 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) '.' counter(c2, decimal) '. ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
#l12 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l13 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l13 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) '.' counter(c2, decimal) '. ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
#l13 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l14 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l14 > li > *:first-child:before {
  counter-increment: c3;
  content: '(' counter(c3, lower-latin) ') ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
#l14 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l15 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l15 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) '.' counter(c2, decimal) '. ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
#l15 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l16 {
  padding-left: 0pt;
}
#l16 > li > *:first-child:before {
  content: '● ';
  color: white;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 8pt;
}
#l17 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l17 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) '.' counter(c2, decimal) '. ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
#l17 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l18 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l18 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) '.' counter(c2, decimal) '. ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
#l18 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l19 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l19 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) '.' counter(c2, decimal) '. ';
  color: white;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
#l19 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
.privacy-policy-div {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
}
.req-postal-code {
  font-style: italic;
  cursor: pointer;
}
.swal2-input {
  background: black !important;
}
</style>
