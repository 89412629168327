<template>
  <header class="site-header section z-[1000] " :class="{ 'windowScrolled': isScrolled }">
    <div class="container ">
      <div class="inner-content mt-[8px] m-auto bg-white/10 rounded-[18px] p-1.5" ref="header">
        <div class="row flex justify-between items-center gap-4 max-[400px]:gap-2">
          <div class="">
            <router-link to="/" class="logo ">
              <img src="../../../assets/logo.png" alt="site-logo" class="site-logo max-h-[20px]" />
            </router-link>
          </div>
          <div class="hidden lg:block">
            <div class="menu-links">
              <ul class="flex justify-between gap-[clamp(1.25rem,3.125vw_-_0.75rem,3rem)]">
                <li class="active"><button class="body3 text-white hover:text-accent-green1"  href="" @click="scrollToElement('games')">Games</button></li>
                <li>
                  <button class="body3 text-white hover:text-accent-green1" href="" @click="scrollToElement('whyJuked')">Why Juked</button>
                </li>
                <li>
                  <button class="body3 text-white hover:text-accent-green1" href="" @click="scrollToElement('howItWorks')">How it works</button>
                </li>
                <li>
                  <button class="body3 text-white hover:text-accent-green1" href="" @click="scrollToElement('map')">Map</button>
                </li>
                <li>
                  <button class="body3 text-white hover:text-accent-green1" href="" @click="scrollToElement('reviews')">Reviews</button>
                </li>
                <li>
                  <button class="body3 text-white hover:text-accent-green1" href="" @click="scrollToElement('FAQs')">FAQs</button>
                </li>
              </ul>
            </div>
          </div>
          <div class="header-buttons flex">
            <ul class="flex justify-between item-center">
              <li class="hidden lg:block">
                <button
                  class="header-btn btn-login btn-outline"
                  href=""
                  v-on:click="$router.push({ path: $route.path, query: { tab: 'login' } })">
                  Log in
                </button>
              </li>
              <li class="hidden lg:block">
                <button
                  class="header-btn btn-singup btn-secondary ml-2"
                  href=""
                  v-on:click="
                    $router.push({ path: $route.path, query: { tab: 'register' } })
                  ">
                  Sign up
                </button>
              </li>
              <li class="block lg:hidden">
                  <div class="header-menu-images flex items-center" @click="openMenu()" :class="menuopen?'active':''">
                    <div class="bar m-button">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="40" height="40" rx="10" fill="#5FF487"/>
                  <path d="M17.5428 16.8054C15.8252 16.8054 14.1154 16.8054 12.4055 16.8054C11.8807 16.7927 11.361 16.6904 10.8651 16.5022C10.6049 16.4283 10.3763 16.2576 10.2189 16.0193C10.0614 15.781 9.98489 15.4901 10.0025 15.1968C10.0102 14.9046 10.1092 14.6241 10.283 14.402C10.4567 14.1798 10.6948 14.0293 10.9575 13.9756C11.2752 13.8833 11.6001 13.8241 11.928 13.7988C15.8817 13.5349 19.8303 13.2794 23.7738 13.0324C25.0912 12.9438 26.4135 13.0372 27.7096 13.3103C28.3642 13.4535 28.9188 13.7735 28.9958 14.5904C29.0226 14.9568 28.9202 15.3205 28.7097 15.607C28.4993 15.8935 28.1965 16.0812 27.8636 16.1316C25.9474 16.6808 23.9755 16.9638 21.9946 16.9738C20.5158 17.0328 19.0293 16.9738 17.5505 16.9738L17.5428 16.8054Z" fill="#0D0D0D"/>
                  <path d="M17.5428 21.854C15.8252 21.854 14.1154 21.854 12.4055 21.854C11.8807 21.8445 11.361 21.7678 10.8651 21.6266C10.6049 21.5712 10.3763 21.4432 10.2189 21.2645C10.0614 21.0858 9.98489 20.8676 10.0025 20.6476C10.0102 20.4284 10.1092 20.2181 10.283 20.0515C10.4567 19.8848 10.6948 19.772 10.9575 19.7317C11.2752 19.6625 11.6001 19.6181 11.928 19.5991C15.8817 19.4012 19.8303 19.2096 23.7738 19.0243C25.0912 18.9578 26.4135 19.0279 27.7096 19.2327C28.3642 19.3401 28.9188 19.5801 28.9958 20.1928C29.0226 20.4676 28.9202 20.7403 28.7097 20.9552C28.4993 21.1701 28.1965 21.3109 27.8636 21.3487C25.9474 21.7606 23.9755 21.9728 21.9946 21.9803C20.5158 22.0246 19.0293 21.9803 17.5505 21.9803L17.5428 21.854Z" fill="#0D0D0D"/>
                  <path d="M17.5428 27.8054C15.8252 27.8054 14.1154 27.8054 12.4055 27.8054C11.8807 27.7927 11.361 27.6904 10.8651 27.5022C10.6049 27.4283 10.3763 27.2576 10.2189 27.0193C10.0614 26.781 9.98489 26.4901 10.0025 26.1968C10.0102 25.9046 10.1092 25.6241 10.283 25.402C10.4567 25.1798 10.6948 25.0293 10.9575 24.9756C11.2752 24.8833 11.6001 24.8241 11.928 24.7988C15.8817 24.5349 19.8303 24.2794 23.7738 24.0324C25.0912 23.9438 26.4135 24.0372 27.7096 24.3103C28.3642 24.4535 28.9188 24.7735 28.9958 25.5904C29.0226 25.9568 28.9202 26.3205 28.7097 26.607C28.4993 26.8935 28.1965 27.0812 27.8636 27.1316C25.9474 27.6808 23.9755 27.9638 21.9946 27.9738C20.5158 28.0328 19.0293 27.9738 17.5505 27.9738L17.5428 27.8054Z" fill="#0D0D0D"/>
                  </svg>
                </div>
                <div class="close m-button">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="40" height="40" rx="10" fill="#5FF487"/>
                    <path d="M14.0126 25.6742C14.1381 24.8182 14.4855 24.0111 15.0193 23.3352C15.9856 21.9925 17.0242 20.7046 18.1304 19.4774C18.4803 19.0746 18.5138 18.8223 18.1304 18.405C16.8425 16.9808 15.6297 15.489 14.4968 13.9358C14.2044 13.5427 13.9216 13.1108 14.3194 12.645C14.7173 12.1791 15.2254 12.3441 15.676 12.5819C16.4771 13.0505 17.2069 13.634 17.8427 14.3143C18.5378 14.9936 19.2473 15.6584 19.8896 16.3815C20.2971 16.8667 20.5751 16.6969 20.8483 16.3038C21.6912 15.1547 22.5937 14.0516 23.5519 12.9992C23.7817 12.7334 24.0352 12.4898 24.3093 12.2713C24.4619 12.1113 24.6681 12.0151 24.8873 12.0016C25.1066 11.9882 25.3228 12.0584 25.4934 12.1985C25.8816 12.5237 25.7954 12.9361 25.594 13.3292C24.9045 14.5336 24.1425 15.6939 23.3123 16.8036C23.0198 17.2307 22.7274 17.6625 22.4015 18.0605C22.1474 18.3759 22.2097 18.5797 22.4926 18.8369C23.6238 19.8624 24.7424 20.8992 25.8481 21.9474C26.2142 22.2811 26.5263 22.6708 26.7732 23.1023C26.934 23.3104 27.0139 23.5709 26.998 23.8346C26.9821 24.0983 26.8715 24.347 26.687 24.5338C26.2316 24.9705 25.7282 24.8347 25.2824 24.5047C23.7639 23.4778 22.3028 22.3665 20.9059 21.1758C20.5272 20.8264 20.345 20.8895 20.067 21.2777C18.8638 22.9325 17.7708 24.6745 16.3567 26.1643C16.1985 26.3293 16.0499 26.4991 15.8774 26.6495C15.7233 26.8208 15.5187 26.937 15.2941 26.9807C15.0694 27.0244 14.8368 26.9934 14.631 26.8922C14.415 26.7801 14.2392 26.6024 14.1283 26.3839C14.0174 26.1654 13.9769 25.9172 14.0126 25.6742Z" fill="#0D0D0D"/>
                    </svg>
                </div>
                  </div>
              </li>
            </ul>
          </div>
        </div>
    </div>
    </div>
    <div class="flex flex-col justify-center mobile-menu lg:hidden background_images h-screen pb-[48px] lg:pt-[189px] absolute w-full left-0 z-[-1]":class="menuopen?'active':''" style="background-image:url(/images/landing/Menu-bg.svg);">
      <div class="menu-links">
            <ul class="flex justify-between flex-col items-center gap-[32px] md:mb-[140px] mb-[60px] pt-[5rem] md:pt-0">
              <li class="active"><button class="heading3 text-white hover:text-accent-green1"  href="" @click="scrollToElement('games')">Games</button></li>
              <li>
                <button class="heading3 text-white hover:text-accent-green1" @click="scrollToElement('whyJuked')">Why Juked</button>
              </li>
              <li>
                <button class="heading3 text-white hover:text-accent-green1" @click="scrollToElement('howItWorks')">How it works</button>
              </li>
              <li>
                <button class="heading3 text-white hover:text-accent-green1" @click="scrollToElement('map')">Map</button>
              </li>
              <li>
                <button class="heading3 text-white hover:text-accent-green1" @click="scrollToElement('reviews')">Reviews</button>
              </li>
              <li>
                <button class="heading3 text-white hover:text-accent-green1" @click="scrollToElement('FAQs')">FAQs</button>
              </li>
            </ul>
          </div>
          <div class="header-buttons px-4">
          <ul class="flex-grow justify-between flex-col items-center">
            <li class="w-full mb-[12px]">
              <button
                class="header-btn btn-login btn-outline w-full"
                href=""
                v-on:click="$router.push({ path: $route.path, query: { tab: 'login' } })">
                Log in
              </button>
            </li>
            <li class="w-full">
              <button
                class="header-btn btn-singup btn-secondary w-full"
                href=""
                v-on:click="
                  $router.push({ path: $route.path, query: { tab: 'register' } })
                ">
                Sign up
              </button>
            </li>
          </ul>
        </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { gsap } from "gsap";
export default {
  name: 'PHeader',
  data() {
    return {
      menuopen: false,
      isScrolled: false
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    gsap.from(this.$refs.header,{ y: -100,ease: "back.out(1.9)",duration:0.6,delay: 2 }, );
  },
  methods: {
    openMenu() {
      this.menuopen = !this.menuopen;
    },
    onPlayNowClicked() {
      const route = this.isLoggedIn ? '/' : '/?tab=register';
      this.$router.push(route);
    },
    scrollToElement(id) {
      const container = document.getElementById(id);
      if (this.menuopen) {
        this.menuopen = false;
      }
      if (container) {
        const headerHeight = this.$el.offsetHeight; // Get the header height
        const elementPosition = container.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerHeight;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 50;
    }
  },
  computed: {
    ...mapGetters(['me', 'isLoggedIn']),
  },
};
</script>
