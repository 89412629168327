export const faq = [
    {
        "id" : 1,
        "title" : "General",
        "data" : [
            {
                "id":1,
                "que" : "Why is Juked’s VIP program the best?",
                "ans" : "<p>Juked's VIP program is consistently hailed as the premier social casino experience, distinguished by the generous bonuses we extend to our valued customers. Our support team, celebrated for its excellence, is readily available around the clock to assist with any inquiries. Leading in customer satisfaction among a plethora of social casinos, we've cultivated a global community of enthusiastic patrons.</p>"
            },
            {
                "id":2,
                "que" : "Where can I find the Juked Telegram Channel?",
                "ans" : "The Juked Telegram channel is: <a href='https://t.me/JukedCasino' target='_blank'>Juked Casino</a>"
            },
            {
                "id":3,
                "que" : "Where can I find the Juked VIP Telegram channel?",
                "ans" : "<a href='https://t.me/+1BxnoIS5lcM1YTJh' target='_blank'>Juked Casino</a>"
            }
        ]
    }
]