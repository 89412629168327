import { defineStore } from 'pinia';
import axios from '@/lib/myAxios';
import { getBalanceIcon } from '@/lib/icons';
import { formatCrypto, formatCryptoRaw, formatRedemptionAmount } from '@/lib/utils';

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const balanceStore = defineStore('balance', {
  state: () => {
    return {
      bundleSelected: null,
      displayInFiat: false,
      hideZeros: false,
      loading: false,
      getBalanceIcon,
      formatCryptoRaw,
      formatCrypto,
      formatRedemptionAmount,
      network: null,
      default: 'USD',
      mainCurrency: 'SC',
      defaultFiat: 'USD',
      balances: [],
      showSelectFiatModal: false,
      currencies: [],
      exchangeRates: {
        USD: {
          BTC: 38042.73,
          ETH: 2808.74,
          BCH: 282.21,
          DOGE: 0.13,
          XRP: 0.591829,
          TRX: 0.069,
          EOS: 2.07,
        },
      },
    };
  },
  getters: {
    getBundleSelected() {
      return this.bundleSelected;
    },
    cashBalance() {
      const balance = this.balances.find((balance) => balance.game_mode === 'SC');
      return balance ? balance.balance : 0.0;
    },
    goldBalance() {
      const balance = this.balances.find((balance) => balance.game_mode === 'GC');
      return balance ? balance.balance : 0.0;
    },
    mainBalance() {
      return this.mainCurrency === 'SC' ? this.cashBalance : this.goldBalance;
    },
    selectedFiat() {
      return this.currencies.find((x) => x.name === this.defaultFiat);
    },
    selectedFiatSymbol() {
      return this.selectedFiat ? this.selectedFiat.symbol : '';
    },
    selectedCrypto() {
      return this.currencies.find((x) => x.name === this.default);
    },
    icon() {
      return getBalanceIcon(this.displayInFiat ? this.defaultFiat : this.default);
    },
    mainIcon() {
      return getBalanceIcon(this.mainCurrency);
    },
    networks() {
      if (!this.selectedCrypto) return [];
      return this.currencies.filter(
        (cur) => cur.parent_currency_id === this.selectedCrypto.id,
      );
    },
    cryptos() {
      return this.currencies.filter(
        (cur) => cur.parent_currency_id === null && cur.is_crypto,
      );
    },
    selectedNetwork() {
      if (this.networks.length === 0) return null;
      if (
        this.network &&
        this.networks.filter((network) => network.id === this.network.id).length > 0
      ) {
        return this.network;
      } else {
        this.network = this.networks[0];
        return this.network;
      }
    },
  },
  actions: {
    getCryptos(amount) {
      return this.cryptos.filter(
        (cur) => this.cryptoToFiat(cur.name, 'USD', cur.min_deposit) < amount,
      );
    },
    async init() {
      try {
        const { data } = await axios.get('/user/balance');
        this.mainCurrency = data.default;
        this.balances = data.data;
        await this.fetchCurrencies();
      } catch (e) {}
    },
    async fetchCurrencies() {
      const { data } = await axios.get('/currencies');
      this.currencies = data.currencies.map((currency) => {
        currency.icon = getBalanceIcon(currency.name);
        return currency;
      });
      this.exchangeRates = data.rates;
    },
    async updateMainCurrency(currency) {
      try {
        this.mainCurrency = currency;
        const data = await axios.post('/user/balance', {
          game_mode: currency,
        });
        this.updateDefault(currency);
      } catch (e) {}
    },
    async updateDefault(currency) {
      try {
        this.default = currency;
      } catch (e) {}
    },
    getIcon(currency = null) {
      currency = currency ?? this.default;
      return getBalanceIcon(this.displayInFiat ? this.defaultFiat : currency);
    },
    setDefaultCurrency(currency) {
      this.default = currency;
    },
    updateBalance(currency, amount, timestamp) {
      let bal = this.balances.find((x) => x.game_mode === currency);
      if (bal) {
        bal.balance = amount;
      } else {
        this.balances.push({
          balance: amount,
          game_mode: currency,
        });
      }
      this.balances = [...this.balances];
    },

    formatAmount(amount, withSymbol = true) {
      const symbol = this.displayInFiat && withSymbol ? this.selectedFiatSymbol : '';
      return symbol + '' + formatCrypto(amount, this.displayInFiat);
    },

    formatRaw(amount, withSymbol = false) {
      const symbol = this.displayInFiat && withSymbol ? this.selectedFiatSymbol : '';
      return symbol + '' + formatCryptoRaw(amount, this.displayInFiat);
    },
    formatWithSymbol(amount, isFiat) {
      const symbol = isFiat ? this.selectedFiatSymbol : '';
      return symbol + '' + formatCrypto(amount, isFiat);
    },
    exchangeAndFormatAmount(amount, currency) {
      return this.formatAmount(this.exchangeCurrency(amount, currency));
    },
    exchangeCurrency(amount, currency = null) {
      const exchangeRate = this.exchangeRate(currency ?? this.default); //this.currencies.length > 0 ? this.exchangeRates[this.defaultFiat][currency] : 1
      return amount * (this.displayInFiat ? exchangeRate : 1);
    },
    reverseExchangeCurrency(amount, currency = null) {
      const exchangeRate = this.exchangeRate(currency ?? this.default); //this.currencies.length > 0 ? this.exchangeRates[this.defaultFiat][currency] : 1
      return amount / (this.displayInFiat ? exchangeRate : 1);
    },
    exchangeRate(currency) {
      return this.currencies.length > 0
        ? this.exchangeRates[this.defaultFiat][currency]
        : 1;
    },

    formatRedemptionAmount(amount, currency) {
      return formatRedemptionAmount(this.exchangeCurrency(amount, currency));
    },

    fiatToCrypto(fiat, crypto, amount) {
      try {
        amount = isNaN(amount) ? 0 : amount;
        if (!this.exchangeRates && this.exchangeRates[fiat]) {
          return 0;
        }
        const rate = this.exchangeRates[fiat][crypto];
        return amount / rate;
      } catch (e) {
        return 0;
      }
    },
    cryptoToFiat(crypto, fiat, amount) {
      const rate = this.exchangeRates[fiat][crypto];
      return amount * rate;
    },
    getCurrencyContext(amount) {
      amount = isNaN(amount) ? 0 : amount;
      return amount;
      const rate = this.exchangeRates['USD'][this.default];
      return this.exchangeAndFormatAmount(amount / rate);
    },
    normalizeWager(amount, currency = null) {
      amount = isNaN(amount) ? 0 : amount;
      const rate = this.exchangeRate(currency ?? this.default);
      if (this.displayInFiat) {
        return amount / rate;
      } else {
        return amount * rate;
      }
    },
    formatWagerWithSymbol(amount, currency = null) {
      currency = currency ?? this.default;
      const wager = this.normalizeWager(amount, currency);
      if (this.displayInFiat) {
        return formatCrypto(wager, false) + ' ' + currency;
      } else {
        return this.selectedFiatSymbol + formatCrypto(wager, true);
      }
    },
  },
});
