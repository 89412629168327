<template>
  <div v-if="!shouldRender">
    <identity-verification-form />
  </div>
  <div v-else>
    <div class="flex min-h-[100%] items-center justify-center">
      <form
        @submit.prevent="submitAptPayForm"
        class="w-96 max-w-full rounded-lg bg-black bg-opacity-25 px-8 py-8 shadow-lg">
        <h1 class="mb-8 text-3xl font-bold text-white">Redeem Juked Cash</h1>
        <div class="mb-4">
          <label for="amount" class="block font-medium text-gray-300">Amount</label>
          <input
            v-model="formData.amount"
            type="number"
            step="any"
            id="amount"
            name="amount"
            class="mt-1 w-full rounded-md border-gray-300 bg-white px-4 py-2 text-gray-900 focus:border-blue-300 focus:ring focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:focus:border-gray-600 dark:focus:ring-blue-600" />
        </div>
        <div class="mb-4">
          <label for="currency" class="block font-medium text-gray-300">Currency</label>
          <input
            v-model="formData.currency"
            type="text"
            id="currency"
            name="currency"
            class="mt-1 w-full rounded-md border-gray-300 bg-white px-4 py-2 text-gray-900 focus:border-blue-300 focus:ring focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:focus:border-gray-600 dark:focus:ring-blue-600" />
        </div>
        <!-- <div class="mb-4">
              <label
                  for="transaction-type"
                  class="block font-medium text-gray-300"
              >Transaction Type</label
              >
              <select
                  v-model="formData.transactionType"
                  id="transaction-type"
                  name="transaction-type"
                  class="mt-1 w-full rounded-md border-gray-300 bg-white px-4 py-2 text-gray-900 focus:border-blue-300 focus:ring focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:focus:border-gray-600 dark:focus:ring-blue-600"
                  @change="onchangeRedemption($event,'AptPay')"
                  >
                <option value="CARD">CARD</option>
                <option value="ACH">ACH</option>
              </select>
            </div> -->
        <!-- ACH specific fields -->
        <div v-if="formData.transactionType == 'ACH'">
          <div class="mb-4">
            <label for="branch-transit-number" class="block font-medium text-gray-300"
              >Routing Number</label
            >
            <input
              v-model="formData.branchTransitNumber"
              type="text"
              id="branch-transit-number"
              name="branch-transit-number"
              class="mt-1 w-full rounded-md border-gray-300 bg-white px-4 py-2 text-gray-900 focus:border-blue-300 focus:ring focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:focus:border-gray-600 dark:focus:ring-blue-600" />
          </div>
          <div class="mb-4">
            <label for="account-number" class="block font-medium text-gray-300"
              >Account Number</label
            >
            <input
              v-model="formData.accountNumber"
              type="text"
              id="account-number"
              name="account-number"
              class="mt-1 w-full rounded-md border-gray-300 bg-white px-4 py-2 text-gray-900 focus:border-blue-300 focus:ring focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:focus:border-gray-600 dark:focus:ring-blue-600" />
          </div>
        </div>
        <!-- CARD specific fields -->
        <div v-if="formData.transactionType == 'CARD'">
          <div class="mb-4">
            <label for="disbursement-number" class="block font-medium text-gray-300"
              >Card Number</label
            >
            <span
              id="cc-number"
              class="form-field block h-10 mt-1 w-full rounded-md border-gray-300 bg-white text-black px-4 py-2 text-gray-900 focus:border-blue-300 focus:ring focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:focus:border-gray-600 dark:focus:ring-blue-600">
              <!--VGS Collect iframe for card number field will be here!-->
            </span>
          </div>
        </div>
        <div class="mb-4">
          <label for="remarks" class="block font-medium text-gray-300">Remarks</label>
          <input
            v-model="formData.remarks"
            type="text"
            id="remarks"
            name="remarks"
            class="mt-1 w-full rounded-md border-gray-300 bg-white px-4 py-2 text-gray-900 focus:border-blue-300 focus:ring focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:focus:border-gray-600 dark:focus:ring-blue-600" />
        </div>
        <div class="mt-6 flex justify-center">
          <button
            type="submit"
            class="rounded-md bg-blue-500 px-6 py-2 text-white hover:bg-blue-600 disabled:opacity-50"
            :disabled="this.isLoading">
            <span v-if="this.isLoading" class="animate-spin">Loading...</span>
            <span v-else>Submit</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import PuntModal from '@/components/Shared/PuntModal.vue';
import { getBalanceIcon } from '@/lib/icons';
import { mapActions, mapGetters } from 'vuex';
import IdentityVerificationForm from '@/components/Bank/IdentityVerificationForm.vue';
import { computed } from '@vue/reactivity';
import axios from '@/lib/myAxios';
import $store from '@/store';
import { aptPay } from '@/store/pinia/aptPay';

export default {
  name: 'AptPayWithdrawOld',
  props: {
    type: {
      default: 'max-w-md',
    },
  },
  components: {
    IdentityVerificationForm,
    PuntModal,
  },
  setup() {
    const aptP = aptPay();

    return {
      aptP,
    };
  },
  mounted() {
    this.formData.transactionType = this.type;
    if (window.Echo) {
    }
  },
  data() {
    return {
      isAptpayenabled: false,
      shouldRender: false,
      formData: {
        amount: '',
        currency: 'USD',
        transactionType: 'ACH',
        disbursementNumber: '',
        branchTransitNumber: '',
        accountNumber: '',
        remarks: '',
      },
      accountVerifylink: null,
      accountVerifyStart: false,
      isLoading: false,
      accounts: [],
      card_number: null,
      microDeposite: 0,
      verifyDebitcard: null,
      latestac_id: null,
      microdepositestart: false,
    };
  },
  async created() {
    this.isLoading = true;
    window.toast = this.$toast;

    await this.aptP.identityCheck();
    setTimeout(() => {
      this.subscribe_aptpaynotification();
    }, 200);

    this.shouldRender = this.aptP.isAptPayVerified;
    if (this.formData.transactionType == 'CARD') {
      setTimeout(() => {
        this.setupcollectjs();
      }, 700);
    }

    this.isLoading = false;
  },
  methods: {
    ...mapActions(['fetchUser']),
    async setupcollectjs() {
      this.card_number = VGSCollect.create(
        import.meta.env.VITE_PAY_SAFE_COLLECT_JS,
        import.meta.env.VITE_PAY_SAFE_ENV,
        function (state) {},
      );

      this.card_number.field('#cc-number', {
        type: 'card-number',
        name: 'disbursementNumber',
        //placeholder: 'Type Card number here',
        validations: ['required'],
        showCardIcon: false,
        //successColor: '#fff',
        errorColor: '#D8000C',
        placeholder: '4111 XXXX XXXX XXXX',
        addCardBrands: [
          {
            type: 'paymentcard',
            pattern: /^\d/,
            format: /(\d{1,4})/g,
            length: [15, 16, 17, 18, 19],
            cvcLength: [3, 4],
            luhn: false,
          },
        ],
      });
    },
    async submitaptwithdraw() {
      try {
        let response = await this.aptP.redeem(JSON.parse(JSON.stringify(this.formData)));
        // Toast notification for payment responses.
        if (response.status == 422 && response.data.error) {
          this.$toast.warning(response.data.error);
        }
        if (response.data['status']) {
          if (response.data['status'] == 'ACCEPTED') {
            this.$toast.success('Redemption successfully processed!');

            if(import.meta.env.VITE_NODE_ENV == 'production')
            {
                // tracking redemption event on GTM
                this.$gtm.trackEvent({
                  event: 'redemption',
                  value: this.formData.amount,
                  email: this.me.email,
                });
            }
            setTimeout(() => {
              window.location.reload();
              this.$router.push({ path: '/' }).then(() => {
                this.$router.go();
              });
            }, 2000);
          } else {
            if(import.meta.env.VITE_NODE_ENV == 'production')
            {
              // tracking redemption event on GTM
              this.$gtm.trackEvent({
                event: 'redemption',
                value: this.formData.amount,
                email: this.me.email,
              });
            }
            this.$toast.success(response.data['status']);
          }
          /*
            setTimeout(() => {
                window.location.reload();
                this.$router.go(-1);
            }, 5000);
            */
        }
        if (response.data['error_code']) {
          this.$toast.error(
            'error code ' + response.data['error_code'] + ' ' + response.data['message'],
          );
        }
        if (response.data['error']) {
          this.$toast.error(response.data['error']);
        }
        return response;
        // this.$toast.success('payment success')
      } catch (error) {
        console.error('Error in payment:', error);
      }
    },
    async submitAptPayForm() {
      if (!this.isLoading) {
        var tokenized_card_number = false;
        this.isLoading = true;

        if (this.formData.transactionType == 'CARD') {
          if (this.card_number) {
            await this.card_number.submit('/post', {}, function (status, data) {
              tokenized_card_number = data.json.disbursementNumber;
            });
          } else {
            throw new Error('Card Number is not valid');
            this.isLoading = false;
          }
        } else {
          tokenized_card_number = true;
        }

        const waitForMyVar = () => {
          return new Promise((resolve) => {
            const checkMyVar = () => {
              if (tokenized_card_number) {
                this.formData.disbursementNumber = tokenized_card_number;
                this.submitDisbursment();
              } else {
                setTimeout(checkMyVar, 100);
              }
            };
            checkMyVar();
          });
        };

        // use the promise to wait for myVar to be updated
        waitForMyVar().then(() => {
          // continue with the rest of your code here
          if (tokenized_card_number) {
            this.formData.disbursementNumber = tokenized_card_number;
            this.submitDisbursment();
          }
        });
      }
    },
    async submitDisbursment() {
      try {
        this.showFlinks = true;

        let response = await this.submitaptwithdraw();
        this.isLoading = false;
        if (response.status == 200) {
          let url = response.data['verificationUrl'];
          let refid = response.data['referenceId'];
          let result = null;
          let last_step = '';

          if (response.data['status'] == 'ACCEPTED') {
            this.isLoading = true;
          }
        }
        // else{
        // if(response.data.error_code){
        //   this.$toast.error(response.data.errors['0'])
        // }
        // }
      } catch (error) {
        console.error('Error in payment', error);
      }
    },
    subscribe_aptpaynotification() {
      window.Echo.private('App.User.' + this.me.id).listen(
        'AptpayNotification',
        ({ aptpaystatus }) => {
          if (aptpaystatus == 'PAYEE_VERIFICATION_COMPLETED') {
            this.shouldRender = true;
          }
        },
      );
    },
  },
  computed: {
    ...mapGetters(['me']),
    getType() {
      return this.type;
    },
  },
};
</script>

<style scoped>
#cc-number iframe {
  height: 100%;
}
</style>
